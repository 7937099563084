import { Subset } from '../generics';
import { StorageTypes } from './document';
import { DocumentTemplate } from './documentTemplate';
import { SourceEntityType } from './entityType';

export class DocumentPacketTemplate {
  packetId!: number;
  templateId!: number;
  order!: number;
  template?: DocumentTemplate;
  packet?: DocumentPacket;
}

export class DocumentPacket {
  id!: number;
  name!: string;
  filename!: string;
  sourceEntityTypeId!: SourceEntityType;
  companyId!: number;
  templateAssignments?: DocumentPacketTemplate[];
}

export type CreateDocumentPacketRequest = Subset<DocumentPacket, 'companyId' | 'name' | 'filename' | 'sourceEntityTypeId'> & {
  templateAssignments: Subset<DocumentPacketTemplate, 'templateId' | 'order'>[];
};
export type UpdateDocumentPacketRequest = Subset<DocumentPacket, 'id', 'name' | 'filename' | 'sourceEntityTypeId'> & { templateAssignments?: Subset<DocumentPacketTemplate, 'templateId' | 'order'>[] };

export type PrintDocumentResponse = {
  documentId: number | null;
  fileName: string;
  locationType: StorageTypes;
};
