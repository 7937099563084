import { ChangeDetectorRef, Component, ElementRef, NgZone } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { DocumentRow } from 'src/app/shared/microsoft-entity-documents/microsoft-entity-documents.component';
import { EntityContainer, ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { contactDropdown } from 'src/lib/commonTypes';
import { SaveOptions } from 'src/lib/EntityContainer';
import { createBookingPayOrderPreset } from 'src/lib/flex/forms/createBookingPayOrder';
import { CreatePayOrderPrefill, createPayOrderRequest } from 'src/lib/flex/forms/createPaymentOrder';
import { unbookingPayOrderPreset } from 'src/lib/flex/forms/unbookingPayOrder';
import { Subset } from 'src/lib/generics';
import { conditionalValidators } from 'src/lib/genericValidators';
import { compareDates, getCompanyByDefaultAuthorizedCompany, getTodayUTC, markFormGroupTouched, openFlexForm, removeLineBreaks } from 'src/lib/helperFunctions';
import { BankAccount, Contact, ContactBankInformation, GlAccountLeaves, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import {
  EntryType,
  PayOrderHeader,
  PayOrderLine,
  PayOrderLineType,
  PayOrderStatus,
  PayOrderStatuses,
  PayOrderStatusesFilteredBooked,
  PayOrderType,
  UpsertPayOrderLineManualRequest,
  UpsertPayOrderLinePaymentRequest,
  UpsertPayOrderLineVoucherRequest,
  UpsertPayOrderRequest,
} from 'src/lib/newBackendTypes/payOrder';
import { FormControlStatus, TypedFormGroup } from 'src/lib/typedForms';
import { DocumentsIcon, InvoiceIcon } from 'src/lib/uiConstants';

@Component({
  selector: 'accounting-pay-order-entity',
  templateUrl: './pay-order-entity.component.html',
  styleUrls: ['./pay-order-entity.component.scss'],
})
export class PayOrderEntityComponent extends EntityContainer<PayOrderHeader, UpsertPayOrderRequest, UpsertPayOrderRequest> implements ModalFormComponent<PayOrderHeader, Partial<PayOrderForm>> {
  form: TypedFormGroup<PayOrderForm> = null;

  prefill: Partial<PayOrderForm> = null;

  popUpOptions?: SaveOptions<PayOrderHeader, UpsertPayOrderRequest | UpsertPayOrderRequest> = null;

  contactDropdown = contactDropdown();
  contactBankAccountDropdown: ContactBankInformation[];
  bankAccountDropdown: BankAccount[];

  companies: Contact[];

  companyId: number | null = null;
  contactId: number | null = null;
  currencyId: number | null;

  PayOrderIcon = InvoiceIcon;
  documentsIcon = DocumentsIcon;

  showSaveButtonsInPopup: boolean;

  payOrderStatus;

  payCreationDate: Date = getTodayUTC();

  paymentEntityType = SourceEntityType.PAYMENT_KEY;
  pendingStatus = FormControlStatus.PENDING;

  constructor(
    route: ActivatedRoute,
    elementRef: ElementRef<any>,
    zone: NgZone,
    delegate: DelegateService,
    public commonData: CommonDataService,
    public cdr: ChangeDetectorRef,
    private formatter: DataFormattingService
  ) {
    super(
      {
        entityName: 'Pay Order',
        idFields: ['id'],
        labelField: 'id',
        tabFields: ['payOrderNumber'],
        sourceEntityType: SourceEntityType.PAY_ORDER_KEY,
        createProcedureId: endpoints.createPayOrder,
        updateProcedureId: endpoints.updatePayOrder,
        deleteProcedureId: endpoints.deletePayOrder,
        getProcedureId: endpoints.getPayOrder,
      },
      route,
      elementRef,
      zone,
      delegate
    );

    this.companies = commonData.staticCompanies.value;
  }

  public ngAfterViewInit() {
    this.cdr.detectChanges(); // IMPORTANT: without this, Angular will throw ExpressionChangedAfterItHasBeenCheckedError (dev mode only)
  } // end ngAfterViewInit

  get paymentId() {
    let paymentId = this.entity?.bookingPaymentId;
    return paymentId;
  }

  get paymentNumber() {
    let paymentNumber = this.entity?.bookingPayment?.documentReference;
    return paymentNumber;
  }

  async refreshBankAccounts() {
    if (!this.contactId) {
      this.bankAccountDropdown = [];
      return;
    }
    const bankAccountResponse = await this.api.run<ListResponse<BankAccount>>(endpoints.listBankAccounts, { filters: { id: { not: 0 } } }, null);
    this.bankAccountDropdown = bankAccountResponse.list;
  }

  async refreshContactBankAccounts() {
    if (!this.contactId) {
      this.contactBankAccountDropdown = [];
      return;
    }
    const contactBankAccountResponse = await this.api.run<ListResponse<ContactBankInformation>>(endpoints.listContactBankInformation, { filters: { id: { not: 0 }, contactId: this.contactId } }, null);
    this.contactBankAccountDropdown = contactBankAccountResponse.list;
    const selectedContactBankAccount = this.contactBankAccountDropdown.find((item) => item.default === YN.Y);
    this.form.controls.contactBankInformation.setValue(selectedContactBankAccount);
  }

  async initializeForm() {
    if (!this.entity) {
      const defaultCompany = getCompanyByDefaultAuthorizedCompany(this.store);
      this.companyId = defaultCompany ? defaultCompany.id : null;
    }
    const isCheckNumberRequired = conditionalValidators(() => this.isCheckRequired, Validators.required);
    const requiredIfIsReadonly = conditionalValidators(() => !this.isReadOnly, Validators.required);
    this.form = new TypedFormGroup<PayOrderForm>({
      id: new UntypedFormControl(),
      counterparty: new UntypedFormControl(null, requiredIfIsReadonly),
      contactBankInformation: new UntypedFormControl(null, requiredIfIsReadonly),
      payCreationDate: new UntypedFormControl(this.payCreationDate, requiredIfIsReadonly),
      ourAccount: new UntypedFormControl(null, requiredIfIsReadonly),
      description: new UntypedFormControl('', (control: AbstractControl) => {
        const value = control.value;
        const validationResult = !/[&]/.test(value);
        return !!value && !validationResult ? { custom: `Description doesn't allow '&' character` } : null;
      }),
      checkNumber: new UntypedFormControl('', isCheckNumberRequired),
      payOrderStatus: new UntypedFormControl(),
      fedRef: new UntypedFormControl(),
      totalAmount: new UntypedFormControl(),
      documents: new UntypedFormControl([]),
      payOrderLines: new UntypedFormControl([{ lineNumber: 1, type: PayOrderLineType.MANUAL, payExecDate: getTodayUTC() }], []),
    });

    if (!!this.prefill) {
      await this.refreshBankAccounts();
      await this.refreshContactBankAccounts();
      this.form.patchValue(this.prefill);
    }
  }

  initializeFormListeners() {
    this.form
      .get('counterparty')
      .valueChanges.pipe(startWith(this.form.value.counterparty), untilDestroyed(this))
      .subscribe((res: Contact) => {
        if (res && res !== null) {
          if (this.contactId === res.id) return;

          const accountingInformation = res.accountingInformation;
          const companyAccountingInformation = accountingInformation?.find((acc) => acc.companyId === this.companyId);
          const payFromAccount = companyAccountingInformation ? companyAccountingInformation.payFromAccount : null;

          this.contactId = res.id;
          this.refreshBankAccounts();
          this.refreshContactBankAccounts();
          this.form.controls.ourAccount.setValue(payFromAccount);
        }
      });

    this.form
      .get('payCreationDate')
      .valueChanges.pipe(startWith(this.form.value.payCreationDate), untilDestroyed(this))
      .subscribe((res: Date) => {
        if (res && res !== null) {
          this.payCreationDate = res;
        }
      });

    this.form
      .get('ourAccount')
      .valueChanges.pipe(startWith(this.form.value.ourAccount), untilDestroyed(this))
      .subscribe((res: BankAccount) => {
        if (res && res !== null) {
          this.companyId = res && res.ownerCode && res.ownerCode !== null ? res.ownerCode : this.companyId;
          this.currencyId = res.currKey;
        }
      });
  }

  async getCreateEntityRequest(saveOptions: SaveOptions<PayOrderHeader, UpsertPayOrderRequest>): Promise<UpsertPayOrderRequest> {
    const request = await createPayOrderRequest(this.delegate, this.form.value);
    return request;
  }

  async getUpdateEntityRequest(saveOptions: SaveOptions<PayOrderHeader, UpsertPayOrderRequest>): Promise<UpsertPayOrderRequest> {
    const form = this.form.value;
    const request = await createPayOrderRequest(this.delegate, this.form.value);

    if ((!!form.ourAccount && form.ourAccount.id !== this.entity.payOrderLines[0].ourAccount.id) || (form.ourAccount === null && this.entity.payOrderLines[0].ourAccount !== null)) {
      request.ourAccountCode = form.ourAccount ? form.ourAccount.id : null;
    }

    if (form.description !== this.entity.description) {
      const description = removeLineBreaks(form.description);
      request.description = description;
    }
    if (
      (!!form.contactBankInformation && form.contactBankInformation.id !== this.entity.payOrderLines[0]?.paymentInfo.id) ||
      (form.contactBankInformation === null && this.entity.payOrderLines[0]?.paymentInfo !== null)
    ) {
      request.defaultInfoPayLineKey = form.contactBankInformation ? form.contactBankInformation.id : null;
    }
    if (!compareDates(form.payCreationDate, this.entity.payCreationDate)) {
      request.payCreationDate = form.payCreationDate;
    }
    return request;
  }

  async loadEntity(entity: PayOrderForm) {
    this.payOrderStatus = this.payOrderStatusEnum;
    let lineNumber = 1;
    const lines = entity.payOrderLines ?? [];
    this.companyId = entity.companyId;
    entity.counterparty = entity.payOrderLines[0]?.paymentInfo.contact;
    this.contactId = entity.counterparty?.id;
    entity.contactBankInformation = entity.payOrderLines[0]?.paymentInfo;
    entity.ourAccount = entity.payOrderLines[0]?.ourAccount;
    this.currencyId = entity.ourAccount?.currKey;
    await this.refreshBankAccounts();
    await this.refreshContactBankAccounts();
    this.form.patchValue({
      ...entity,
      payOrderLines: lines
        .map((l) => {
          let entryText: string;
          if (l.paymentEntryId !== null) {
            l.type = PayOrderLineType.PAYMENT;
            entryText = l.paymentEntry ? `${l.paymentEntry.fiscalYear} / ${l.paymentEntry.paymentHeader.documentReference} / ${l.paymentEntry.lineNumber}` : '';
            l.payExecDate = l.paymentEntry ? l.paymentEntry.paymentHeader.valueDate : null;
            l.balanceAmount = this.formatter.roundAmount(-l.amount, this.currencyId);
          } else if (l.voucherKey !== null) {
            l.type = PayOrderLineType.VOUCHER;
            entryText = l.voucher ? `${l.voucher.fiscalYearNumber} / ${l.voucher.number}` : '';
            l.payExecDate = l.voucher ? l.voucher.invoiceDate : null;
            const voucherPaymentEntriesAmount = Math.abs(
              this.formatter.roundAmount(
                l.voucher.paymentEntries.reduce((acc, line) => (acc += line.amount), 0),
                this.currencyId
              )
            );
            let sumOfExistingPayOrder = 0;
            for (const payOrderLineForVoucher of l.voucher.payOrderLines) {
              if (payOrderLineForVoucher.payOrderId !== entity.id && payOrderLineForVoucher.payOrderHeader && payOrderLineForVoucher.payOrderHeader.payOrderStatus !== PayOrderStatus.BOOKED) {
                sumOfExistingPayOrder += this.formatter.roundAmount(payOrderLineForVoucher.payOrderHeader.totalAmount, this.currencyId);
              }
            }
            const balanceAmount = this.formatter.roundAmount(l.voucher.amount, this.currencyId) - voucherPaymentEntriesAmount - sumOfExistingPayOrder;
            const lineAmountSign = l.amount > 0 ? -1 : 1;
            l.balanceAmount = lineAmountSign * balanceAmount;
          } else {
            l.type = PayOrderLineType.MANUAL;
            l.balanceAmount = this.formatter.roundAmount(-l.amount, this.currencyId);
            entryText = '';
          }
          l.payComment = l.payComment ? l.payComment : entryText;
          l.account = l.glAccount ? l.glAccount : null;
          return {
            ...l,
            lineNumber: lineNumber++,
            entryText: entryText,
          };
        })
        .sort((a, b) => a.lineNumber - b.lineNumber),
    });
  }

  clickBooking() {
    const prompt = this.delegate.getService('prompt');
    if (!this.form.valid) return prompt.htmlDialog('Error', `<div style="white-space: pre">Some fields are invalid or missing. ${this.entityName} could not be saved.</div>`);
    if (this.form.touched && this.form.dirty) return prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to book pay order: Please save before continuing.</div>`);
    markFormGroupTouched(this.form);
    if (this.form.invalid || this.form.pending) return;

    openFlexForm(this.delegate, createBookingPayOrderPreset, this.entity.id, (res: any) => {
      if (!!res) {
        const path = this.entityPath + this.entityKey;
        const queryParams = this.route.snapshot?.queryParams || {};
        this.router.navigate([path], { relativeTo: this.route, queryParams });
      }
    });
  }

  clickUnbooking() {
    const prompt = this.delegate.getService('prompt');
    if (!this.form.valid) return prompt.htmlDialog('Error', `<div style="white-space: pre">Some fields are invalid or missing. ${this.entityName} could not be saved.</div>`);
    if (this.form.touched && this.form.dirty) return prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to unbook pay order: Please save before continuing.</div>`);
    markFormGroupTouched(this.form);
    if (this.form.invalid || this.form.pending) return;

    openFlexForm(this.delegate, unbookingPayOrderPreset, this.entity.id, (res: any) => {
      if (!!res) {
        const path = this.entityPath + this.entityKey;
        const queryParams = this.route.snapshot?.queryParams || {};
        this.router.navigate([path], { relativeTo: this.route, queryParams });
      }
    });
  }

  onGridReady(gridEvent: any) {}

  get payOrderStatusEnum() {
    return this.isBooked ? (this.payOrderStatus = PayOrderStatuses) : (this.payOrderStatus = PayOrderStatusesFilteredBooked);
  }

  get isBooked() {
    return !!this.entity && this.entity.payOrderStatus === PayOrderStatus.BOOKED;
  }

  get isCheck() {
    return !!this.entity && this.entity.payOrderType === PayOrderType.CHECK;
  }

  get isWire() {
    return !!this.entity && this.entity.payOrderType === PayOrderType.WIRE_ACH;
  }

  get isOpen() {
    return !!this.entity && this.entity.payOrderStatus === PayOrderStatus.OPENED;
  }

  get isWaiting() {
    return !!this.entity && this.entity.payOrderStatus === PayOrderStatus.WAITING;
  }

  get isProcessed() {
    return !!this.entity && this.entity.payOrderStatus === PayOrderStatus.PROCESSED;
  }

  get linesEditable() {
    return !!this.entity && (this.isWaiting || this.isProcessed);
  }

  get isReadOnly() {
    return !!this.entity && (this.entity.payOrderStatus !== PayOrderStatus.OPENED || this.isBooked);
  }

  get bookeable(): boolean {
    return this.authorized[endpoints.bookPayOrder] && !!this.entity && (this.entity.payOrderStatus === PayOrderStatus.WAITING || this.entity.payOrderStatus === PayOrderStatus.PROCESSED);
  }

  get unbookeable() {
    return !!this.authorized[endpoints.unbookPayOrder];
  }

  get checkReadOnly(): boolean {
    return this.isReadOnly || (!!this.entity && this.entity.payOrderType !== PayOrderType.CHECK);
  }

  get isCheckRequired() {
    return this.isCheck;
  }

  get totalAmount() {
    if (!this.entity) return null;
    return this.entity.totalAmount;
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.requestsPending.length === 0 && !this.form.pending && !this.form.invalid;
  }

  async prefillForm(data: CreatePayOrderPrefill) {
    let lineNumber = 1;
    const yourReferences: string[] = [];

    for (const item of data.items) if (item.yourReference !== '') yourReferences.push(item.yourReference);

    this.companyId = data.items[0].companyId;
    const accountingInformation = data.counterparty.accountingInformation;
    const companyAccountingInformation = accountingInformation?.find((acc) => acc.companyId === this.companyId);
    const payFromAccount = companyAccountingInformation ? companyAccountingInformation.payFromAccount : null;

    this.contactId = data.counterparty.id;
    const bankAccount = payFromAccount;
    const contactBankAccout = data.counterparty.bankInformation.find((item) => item.default === YN.Y);
    this.currencyId = bankAccount?.currKey;
    this.prefill = {
      counterparty: data.counterparty,
      ourAccount: bankAccount,
      contactBankInformation: contactBankAccout,
      description: yourReferences.length !== 0 ? yourReferences.join(', ') : '',
      payOrderLines: data.items.map((payOrder) => {
        if (payOrder.entryType === EntryType.PMT) {
          const line: PayOrderLinePaymentForm = {
            lineNumber: lineNumber++,
            type: PayOrderLineType.PAYMENT,
            payComment: payOrder.entryTitle,
            amount: this.formatter.roundAmount(-payOrder.balanceAmount, this.currencyId),
            balanceAmount: this.formatter.roundAmount(payOrder.balanceAmount, this.currencyId),
            entryText: payOrder.entryReference,
            payExecDate: payOrder.valueDate,
            paymentEntryId: payOrder.entryId,
            account: {
              id: payOrder.accountId,
              companyId: payOrder.companyId,
              currencyId: payOrder.currencyId,
              accountType: payOrder.accountType,
              accountOption: payOrder.accountOption,
              idenLong: payOrder.accountIdenLong,
            },
          };
          return line;
        } else if (payOrder.entryType === EntryType.INV) {
          const line: PayOrderLineVoucherForm = {
            lineNumber: lineNumber++,
            type: PayOrderLineType.VOUCHER,
            entryText: payOrder.entryReference,
            payExecDate: payOrder.valueDate,
            payComment: payOrder.entryTitle,
            balanceAmount: this.formatter.roundAmount(payOrder.balanceAmount, this.currencyId),
            amount: this.formatter.roundAmount(-payOrder.balanceAmount, this.currencyId),
            voucherKey: payOrder.entryId,
            account: {
              id: payOrder.accountId,
              companyId: payOrder.companyId,
              currencyId: payOrder.currencyId,
              accountType: payOrder.accountType,
              accountOption: payOrder.accountOption,
              idenLong: payOrder.accountIdenLong,
            },
          };
          return line;
        }
      }),
    };
  }
}

export type PayOrderForm = Subset<PayOrderHeader, 'id' | 'description' | 'payCreationDate', 'payOrderStatus' | 'companyId' | 'checkNumber' | 'payOrderStatus' | 'fedRef' | 'totalAmount'> & {
  counterparty: Contact;
  contactBankInformation: ContactBankInformation;
  ourAccount: BankAccount;
  payOrderLines?: PayOrderLineForm[];
  documents?: DocumentRow[];
};

export type PayOrderLineForm = Subset<
  PayOrderLine,
  'amount' | 'payComment',
  'voucherKey' | 'accountId' | 'payOrderLineId' | 'payOrderId' | 'paymentEntryId' | 'payExecDate' | 'type' | 'paymentEntry' | 'voucher' | 'balanceAmount' | 'paymentInfo' | 'ourAccount'
> & {
  companyId?: number;
  entryText: string;
  lineNumber?: number;
  glAccount?: Pick<GlAccountLeaves, 'id' | 'companyId' | 'currencyId' | 'accountType' | 'accountOption' | 'idenLong'>;
  account?: Pick<GlAccountLeaves, 'id' | 'companyId' | 'currencyId' | 'accountType' | 'accountOption' | 'idenLong'>;
};

export type PayOrderLineForms = PayOrderLinePaymentForm | PayOrderLineVoucherForm | PayOrderLineManualForm | PayOrderLineBankFeeForm;

export type PayOrderLineBankFeeForm = {
  type: PayOrderLineType.BANK_FEE;
  lineNumber: number;
  payExecDate: number | Date;
  payComment: string;
  amount: number;
  payOrderId?: number;
  payOrderLineId?: number;
};

export type PayOrderLineManualForm = Omit<UpsertPayOrderLineManualRequest, 'accountId'> & {
  lineNumber: number;
  account: Pick<GlAccountLeaves, 'id' | 'companyId' | 'currencyId' | 'accountType' | 'accountOption' | 'idenLong'>;
  payExecDate: number | Date;
};

export type PayOrderLineVoucherForm = UpsertPayOrderLineVoucherRequest & {
  lineNumber: number;
  entryText: string;
  payExecDate: number | Date;
  account: Pick<GlAccountLeaves, 'id' | 'companyId' | 'currencyId' | 'accountType' | 'accountOption' | 'idenLong'>;
  balanceAmount: number;
};

export type PayOrderLinePaymentForm = UpsertPayOrderLinePaymentRequest & {
  lineNumber: number;
  entryText: string;
  payExecDate: number | Date;
  account: Pick<GlAccountLeaves, 'id' | 'companyId' | 'currencyId' | 'accountType' | 'accountOption' | 'idenLong'>;
  balanceAmount: number;
  amount: number;
};
