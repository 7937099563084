import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GetContextMenuItemsParams, GridApi, GridOptions, GridReadyEvent, MenuItemDef } from 'ag-grid-community';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ModalFormComponent, SelectorApi } from 'src/app/core/services/selector-popup.service';
import { Store } from 'src/app/core/services/store.service';
import { DebitCreditRenderer } from 'src/app/shared/aggrid/debitcreditrenderer/DebitCreditRenderer';
import { defaultComplexGrid, getContextMenuItems, gotoMenu, quantityColumn } from 'src/lib/agGridFunctions';
import { SearchPaymentsPrefill } from 'src/lib/flex/forms/invoiceSearchPayments';
import { getCompanyByDefaultAuthorizedCompany } from 'src/lib/helperFunctions';
import { Contact, SourceEntityType } from 'src/lib/newBackendTypes';
import { PaymentEntry } from 'src/lib/newBackendTypes/payment';
import { ExternalLinkIcon, InvoiceIcon } from 'src/lib/uiConstants';

@Component({
  selector: 'voucher-search-payments',
  templateUrl: './voucher-search-payments.component.html',
  styleUrls: ['./voucher-search-payments.component.scss'],
})
export class VoucherSearchPaymentsComponent implements ModalFormComponent<SearchPaymentsPrefill> {
  gridOptions: GridOptions;
  gridApi: GridApi;

  selectorApi?: SelectorApi;

  data: PaymentEntry[];

  popup: boolean = true;

  companies: Contact[] = [];
  defaultCompany: Contact;
  baseCurrencyId: number | null = null;

  constructor(private delegate: DelegateService, private router: Router, store: Store) {
    this.data = [];
    this.companies = delegate.commonData.staticCompanies.value;
    this.defaultCompany = getCompanyByDefaultAuthorizedCompany(store);
    this.baseCurrencyId = this.defaultCompany && this.defaultCompany.fiscalCompany ? this.defaultCompany.fiscalCompany.currKey : null;
    this.gridOptions = {
      ...defaultComplexGrid(this.delegate, 'paymentEntryId'),
      columnDefs: [
        {
          field: 'counterparty.displayName',
          headerName: 'Counterparty',
          width: 180,
          filter: 'agTextColumnFilter',
        },
        { field: 'entryText', headerName: 'Description', width: 250, filter: 'agTextColumnFilter' },
        {
          field: 'externalRef',
          headerName: 'External Reference',
          width: 200,
          filter: 'agTextColumnFilter',
        },
        {
          field: 'glAccount.idenLong',
          headerName: 'Account',
          width: 250,
          filter: 'agTextColumnFilter',
        },
        {
          field: 'amount',
          headerName: 'Amount',
          width: 170,
          ...quantityColumn(),
          cellRenderer: DebitCreditRenderer,
          cellRendererParams: { currencyField: 'glAccount.currKey' },
        },
        {
          field: 'baseAmount',
          headerName: 'Base Amount',
          width: 170,
          ...quantityColumn(),
          cellRenderer: DebitCreditRenderer,
          cellRendererParams: { currency: this.baseCurrencyId },
        },
      ],
      getContextMenuItems: getContextMenuItems(gotoMenu(this.goToPayments())),
    };
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    if (this.popup) {
      this.gridOptions.api.setDomLayout('normal');
      this.gridOptions!.api.setPopupParent(document.querySelector('.k-dialog'));
    }
  }

  goToPayments() {
    return (params: GetContextMenuItemsParams) => {
      let data = params.node.data;
      const lookup = this.delegate.getService('entityLookup');
      let existsLink = lookup.entityPathExists('list', SourceEntityType.PAYMENT_KEY);
      let linkPayment = existsLink ? lookup.getLink(SourceEntityType.PAYMENT_KEY, 'get') : null;
      let menu: MenuItemDef[] = [];
      if (data) {
        menu.push({
          name: `Payment ${data.paymentHeader.documentReference}`,
          icon: `<i class="${InvoiceIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
          action: () => {
            this.router.navigate([`${linkPayment}/${data.paymentId}`]);
          },
          subMenu: [
            {
              name: 'New Tab',
              icon: `<i class="${ExternalLinkIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
              action: () => {
                window.open(`${linkPayment}/${data.paymentId}`);
              },
            },
          ],
        });
      }
      return menu;
    };
  }

  prefillForm(data: SearchPaymentsPrefill) {
    this.data = data.entries;
  }

  allowSubmit() {
    return null;
  }

  submit(): SearchPaymentsPrefill {
    return;
  }
}
