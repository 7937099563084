import { from, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { InvoiceHeader, OpenItem, SourceEntityType, UpdateVoucherRequest } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import { Validators } from '@angular/forms';
import { bradyDateValidator } from 'src/lib/genericValidators';
import { formatDate } from 'src/lib/helperFunctions';
import { ListResponse } from 'src/lib/ListResponse';

const updateVoucherDueDatePrefill: prefillCallback<InvoiceHeader> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const voucher = await api.run<InvoiceHeader>(endpoints.getVoucher, { filters: { id } });
      if (!voucher) return 'Unable to update Due date: Voucher not found.';

      const openBalance = await api.run<ListResponse<OpenItem>>(endpoints.listOpenItems, { filters: { counterpartyId: voucher.counterpartyId, voucherId: voucher.id } });
      if (openBalance.list && openBalance.list.length === 0) return 'Unable to update Due date: The voucher does not have an open balance.';
      return voucher;
    })()
  );
};

const updateVoucherDueDateCheck: checkPrefillCallback<InvoiceHeader> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  return of(true);
};

const updateVoucherDueDateForm: createFormCallback<InvoiceHeader, UpdateVoucherDueDateForm> = (delegate, id, prefill) => {
  return [
    {
      type: 'Label',
      text: `Voucher number: ${prefill ? prefill.number : 'Unknown'}`,
      style: { 'font-weight': 'bold', 'margin-bottom': '15px' },
    },
    {
      type: 'Label',
      text: `Counterparty: ${prefill ? prefill.counterparty.displayName : 'Unknown'}`,
      style: { 'font-weight': 'bold', 'margin-bottom': '15px' },
    },
    {
      type: 'Label',
      text: `Due Date: ${prefill ? formatDate(prefill.dueDate) : 'Unknown'}`,
      style: { 'font-weight': 'bold', 'margin-bottom': '15px' },
    },
    {
      type: 'Date',
      label: 'New Due Date',
      field: 'dueDate',
      validator: [Validators.required, bradyDateValidator()],
      startingValue: new Date(),
    },
  ];
};

const updateVoucherDueDateCallback: submitFormCallback<InvoiceHeader, UpdateVoucherDueDateForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<InvoiceHeader>((resolve, reject) => {
      (async () => {
        const request: UpdateVoucherRequest = {
          id,
          dueDate: form.dueDate,
        };
        if (prefill.dueDate === request.dueDate) return reject('Unable to update Due Date: Due Date is the same as the existing one.');

        try {
          const response = await api.run<InvoiceHeader>(endpoints.updateVoucherDueDate, request, null);
          return resolve(response);
        } catch (err) {
          return reject(err);
        }
      })();
    })
      .then((res) => {
        if (res) return prompt.htmlDialog('Success', `<div style="white-space: pre">Voucher ${res.number} Due Date successfuly updated.</div>`);
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const updateVoucherDueDatePreset: DynamicFormConstant<InvoiceHeader, UpdateVoucherDueDateForm> = {
  allowMultipleRows: false,
  checkPrefill: updateVoucherDueDateCheck,
  createForm: updateVoucherDueDateForm,
  entityType: SourceEntityType.INVOICE_KEY,
  getPrefill: updateVoucherDueDatePrefill,
  label: 'Update Voucher Due Date',
  submitForm: updateVoucherDueDateCallback,
  title: 'Update Voucher Due Date',
  value: DynamicFormType.UPDATE_VOUCHER_DUE_DATE,
  endpoints: [endpoints.updateVoucherDueDate, endpoints.getVoucher],
  width: 350,
};

export type UpdateVoucherDueDateForm = Pick<InvoiceHeader, 'dueDate'>;
