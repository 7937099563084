import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { TypedFormGroup } from 'src/lib/typedForms';
import { CommonSteelTypeGroups, Item, LinePriceType, MetalControlGroup, PackingType, ReclassifyShipmentItemData, YN } from 'src/lib/newBackendTypes';
import { DropdownConfig } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { ReclassifyShipmentItemForm } from 'src/lib/flex/forms/reclassifyShipmentItem';

@Component({
  selector: 'logistics-reclassify-shipment-item',
  templateUrl: './reclassify-shipment-item.component.html',
  styleUrls: ['./reclassify-shipment-item.component.scss'],
})
export class ReclassifyShipmentItemComponent implements ModalFormComponent<ReclassifyShipmentItemForm, ReclassifyShipmentItemData> {
  form: TypedFormGroup<ReclassifyShipmentItemForm>;
  popup = true;

  shipmentUnitId: number;
  contractId: number;
  priceType: LinePriceType;
  price: number;
  contractLineId: number;
  productId: number;
  remainingGrossTotal: number;
  remainingNetTotal: number;
  remainingPackingTotal: number;

  royceItemSource = new DropdownConfig<Item>(endpoints.listItems, 'name', 'id', [], {
    groupTypeId: CommonSteelTypeGroups.DEFAULT,
    archived: YN.N,
  });

  metalControlDropdown = new DropdownConfig<MetalControlGroup>({
    listProcedure: endpoints.listMetalControlGroups,
    labelField: 'statusName',
    valueField: 'statusKey',
    postFilter: (v) => !!v.statusKey && !!v.statusName,
  });

  constructor(public commonData: CommonDataService, public delegate: DelegateService) {
    this.form = new TypedFormGroup<ReclassifyShipmentItemForm>({
      shipmentId: new UntypedFormControl(null, Validators.required),
      contractId: new UntypedFormControl(null, Validators.required),
      contractNumber: new UntypedFormControl(null, Validators.required),
      lines: new UntypedFormControl([]),
    });
  }

  prefillForm(data: ReclassifyShipmentItemData) {
    if (!!data) {
      let linePosition = 0;
      let contractLineId: number;
      let packingType: PackingType;
      let priceType: LinePriceType;
      let price: number;

      const lines = data.lines.map((line) => {
        linePosition += 1;
        if (line.isOriginalLine) {
          contractLineId = line.elementId;
          packingType = line.packingType;
          priceType = line.priceType;
          price = line.price;
        }
        return {
          ...line,
          linePosition,
        };
      });

      lines.push({
        linePosition: 2,
        elementId: null,
        lineNumber: null,
        element: null,
        item: null,
        metalControlGroup: null,
        grossWeight: null,
        netWeight: null,
        packingType,
        packingQuantity: null,
        lotNumber: '',
        isOriginalLine: false,
      });

      this.form.patchValue({
        shipmentId: data.shipmentId,
        contractId: data.contractId,
        contractNumber: data.contractNumber,
        lines,
      });

      this.shipmentUnitId = data.shipmentUnitId;
      this.contractId = data.contractId;
      this.priceType = priceType;
      this.price = price;
      this.contractLineId = contractLineId;
      this.productId = data.productId;
      this.remainingGrossTotal = data.grossWeight;
      this.remainingNetTotal = data.netWeight;
      this.remainingGrossTotal = data.grossWeight;
      this.remainingPackingTotal = data.packingQuantity;
    }
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): ReclassifyShipmentItemForm {
    markFormGroupTouched(this.form);
    const formVal = this.form.value;
    return {
      shipmentId: formVal.shipmentId,
      contractId: formVal.contractId,
      contractNumber: formVal.contractNumber,
      lines: formVal.lines,
    };
  }
}
