import { Subset } from '../generics';
import { Contact, YN } from '../newBackendTypes';
import { ListColumn } from './listColumn';
import { EntityLayoutDefault, ListLayoutDefault } from './listLayoutDefault';

export type ListLayout = {
  id: number;
  name: string;
  flexViewId: number;
  userId: number;
  columnState: string;
  default: YN.Y | null;
  shared: YN.Y;
  groupsExpanded: number | null;
  layoutDefaults?: ListLayoutDefault[];
  layoutFilters?: any;
};

export type SetDefaultFlexLayoutRequest = {
  userId: number;
  flexLayoutId: number;
  flexViewId: number;
};

export type CreateFlexLayoutRequest = Omit<ListLayout, 'id'>;
export type UpdateFlexLayoutRequest = Partial<CreateFlexLayoutRequest> & Pick<ListLayout, 'id'>;

export type EntityLayout = Pick<ListLayout, 'id' | 'name' | 'columnState' | 'groupsExpanded'> & {
  listId: ListViews;
  ownerId: number;
  default: YN;
  shared: YN;
  owner?: Contact;
  userDefaults?: EntityLayoutDefault[];
  columns?: ListColumn[];
  layoutFilters?: any;
};

export type CreateEntityLayoutRequest = Subset<EntityLayout, 'name' | 'listId' | 'columnState' | 'default' | 'shared' | 'groupsExpanded' | 'layoutFilters'>;
export type UpdateEntityLayoutRequest = Subset<EntityLayout, 'id', 'name' | 'listId' | 'ownerId' | 'columnState' | 'default' | 'shared' | 'groupsExpanded' | 'layoutFilters'>;

export type EntityListConfiguration = Pick<EntityLayout, 'columns'>;

export enum ViewType {
  flexView = 'FLEX',
  entityView = 'ENTITY',
}

export enum ListViews {
  OPEN_ITEMS = 1,
  JOURNALS = 2,
  VOUCHERS = 3,
  PAYMENTS = 4,
  PAY_ORDERS = 5,
  CONTRACTS = 6,
  CONTACTS = 7,
  DRAFTS = 8,
  CLAUSE_TEMPLATES = 9,
  DISCREPANCIES = 10,
  LOGISTICS_BOOKINGS = 11,
  ARRIVAL_CONFIRMATION = 12,
  ROUTINE_CARGO = 13,
  FILE_ASSIGNMENT = 14,
  ADVANCES_TO_OPEN = 15,
  COMMISIONS = 16,
  EXCHANGE_CONTRACTS = 17,
  MATCHING = 18,
  METAL_CONTROL_BOOKING = 19,
  SERVICE_ORDERS = 20,
  OCEAN_RATE_FINDER = 21,
  FLEX_VIEWS = 22,
  CONTACT_TYPES = 23,
  APPROVAL_TYPE = 24,
  FUTURE_MARKETS = 25,
  CONTAINER_TYPES = 26,
  INCOTERMS = 27,
  AUTOMATED_REPORTS = 28,
  GL_ACCOUNTS = 29,
  CITIES = 30,
  USER_GROUPS = 31,
  DOCUMENT_PACKETS = 32,
  DOCUMENT_TEMPLATES = 33,
  TAGS = 34,
  CONFIGURATION = 35,
  JOURNAL_VIEW = 36,
  GENERAL_SIMPLE_TYPE = 37,
  LETTER_OF_CREDIT = 38,
  LETTER_OF_CREDIT_PRESENTATIONS = 39,
  OCEAN_RATES = 40,
  BUDGET_ELEMENTS = 41,
  BUDGET_ELEMENTS_ACC = 42,
  CONTRACT_FIXATION_DETAILS = 43,
  OPEN_FUTURES_MTM = 44,
  APPOINTMENTS = 45,
  JOURNAL_RECONCILIATION = 46,
  BOOKING_UPDATE_TRACK = 47,
  PAYMENT_TERMS = 48,
  PAYMENT_TERM_DATES = 49,
  NET_POSITION_SUMMARY = 50,
  NET_POSITION_ENTRIES = 51,
  NET_POSITION_PRICE_REPORT = 52,
  HEDGE_CONTROL = 53,
  USER_AUTHORIZED_COMPANIES = 54,
  NET_POSITION_DAILY_REVIEW = 55,
  COLLATERAL_AR = 56,
  WAREHOUSE_INVENTORY_REPORT = 57,
  BANK_RECONCILIATION = 58,
}
