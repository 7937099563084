import { BookingStage } from './freightBooking';

export class LogisticsFileAssignmentQueueData {
  id!: number;
  number!: number;
  description!: string;
  shippingLineName!: string | null;
  draftToCustomerSentDate!: Date | null;
  placeOfReceiptName!: string | null;
  unloadingPortName!: string | null;
  finalDestinationName!: string | null;
  finalDestinationETA!: Date | null;
  supplierName!: string | null;
  customerName!: string | null;
  term!: string | null;
  stage!: BookingStage;
  btsOperator!: number | null;
  btsOperatorName!: string | null;
  btsAssignmentDate!: number;
  ctsOperator!: number | null;
  ctsOperatorName!: string | null;
  ctsAssignmentDate!: number;
  freightForwarderName!: string;
  companyId!: number;
}
