<form [formGroup]="form">
  <div class="row">
    <vertical-field name="Contract Number" class="col-2 reclassify-line-info contract-number-margin">
      <input-wrapper [formControlName]="'contractNumber'" [readonly]="true"></input-wrapper>
    </vertical-field>
  </div>
  <div class="form-divider-line"></div>
  <collapsible-card [title]="'Contract Lines'">
    <logistics-reclassify-shipment-list
      [formControlName]="'lines'"
      [shipmentUnitId]="shipmentUnitId"
      [contractId]="contractId"
      [priceType]="priceType"
      [price]="price"
      [contractLineId]="contractLineId"
      [productId]="productId"
      [remainingGrossTotal]="remainingGrossTotal"
      [remainingNetTotal]="remainingNetTotal"
      [remainingPackingTotal]="remainingPackingTotal"
    >
    </logistics-reclassify-shipment-list>
  </collapsible-card>
</form>
