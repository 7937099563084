import { OnDestroy } from '@angular/core';
import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { GetContextMenuItemsParams } from 'ag-grid-community';
import { filter, first, from, isObservable, map, Observable, of, startWith, Subscriber, switchMap } from 'rxjs';
import { LOADED_USER_ENDPOINTS, LOADED_USER_GRAPHQLS } from 'src/app/core/reducers/actions';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { SelectorResult } from 'src/app/core/services/selector-popup.service';
import { Store } from 'src/app/core/services/store.service';
import { endpoints } from './apiEndpoints';
import { IDListColumn } from './views';
import { DynamicFormConstant } from './flex/forms/types';
import { EnumLabels, enumValues } from './generics';
import { InvoiceDocumentType, UserAuthorizedCompanies, VoucherType, YN } from './newBackendTypes';
import { Document } from './newBackendTypes/document';
import { FormControlStatus, TypedFormGroup } from './typedForms';
import { randomFetchSynonym } from './uiConstants';
import { ExistingGraphql } from './graphql/graphQlEnums';
import { State } from 'src/app/core/reducers';

export enum ZeroDayBehavior {
  LAST_DAY_PREV_MONTH = -1,
  FIRST_DAY_CURR_MONTH = 1,
  THROW = 0,
}

/**
 * Converts a legacy numeric date into a JS date object.  If the input is invalid, an error will be thrown.
 *
 * @param bradyDate The numeric date in the format YYYYMMDD, for example 20211216 for December 16th 2021.
 * @returns A javascript date matching the input.
 */
export function fromBradyDate(bradyDate: number | null, zeroDayBehavior: ZeroDayBehavior = ZeroDayBehavior.THROW): Date | null {
  //validate the length of the date
  if (bradyDate === 0 || bradyDate === 21501231 || !bradyDate) return null;

  if (bradyDate > 99999999 || bradyDate < 10000000) {
    throw new Error('Date has wrong length');
  }

  const year = Math.floor(bradyDate / 10000);
  const month = Math.floor((bradyDate % 10000) / 100);
  const day = bradyDate % 100;

  if (year < 1970) throw new Error(`Error validating date (${bradyDate}): year cannot be less than 1970`);
  if (year > new Date().getFullYear() + 3) throw new Error(`Error validating date (${bradyDate}): year cannot be more than 3 years from today`);
  if (month > 13 || month < 1) throw new Error(`Error validating date (${bradyDate}): month out of range`);

  const daysInMonth =
    month === 4 || month === 6 || month === 9 || month === 11
      ? 30
      : month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12
      ? 31
      : month === 2 && year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)
      ? 29
      : 28;

  if (day > daysInMonth || day < (zeroDayBehavior === ZeroDayBehavior.THROW ? 1 : 0)) throw new Error(`Error validating date (${bradyDate}): day out of range`);

  const date = new Date(Date.UTC(year, month - 1, day === 0 ? (zeroDayBehavior === ZeroDayBehavior.FIRST_DAY_CURR_MONTH ? 1 : 0) : day));

  return date;
}

/**
 * Converts JS date to legacy numeric date.
 *
 * @param date A JS date object.
 * @param defaultValue This value will be returned if the date input is null. Defaults to null.
 * @returns The numeric date in the format YYYYMMDD, for example 20211216 for December 16th 2021.
 */
export function toBradyDate(date: Date | string): number;
export function toBradyDate(date: Date | string | null): number | null;
export function toBradyDate(date: Date | string | null, defaultValue: number): number;
export function toBradyDate(date: Date | string | null, defaultValue: number | null = null): number | null {
  if (date === null) return defaultValue;

  // If the input date is an string value then cast it to Date
  const bradyDate = typeof date === 'string' ? new Date(date) : date;
  if (isNaN(bradyDate.getTime())) throw new Error('Cannot change to Brady Date: Invalid input date');

  return bradyDate.getFullYear() * 10000 + (bradyDate.getMonth() + 1) * 100 + bradyDate.getDate();
}

export function toBradyUTCDate(date: Date | string): number;
export function toBradyUTCDate(date: Date | string | null): number | null;
export function toBradyUTCDate(date: Date | string | null, defaultValue: number): number;
export function toBradyUTCDate(date: Date | string | null, defaultValue: number | null = null): number | null {
  if (date === null) return defaultValue;

  // If the input date is an string value then cast it to Date
  const bradyDate = typeof date === 'string' ? new Date(date) : date;
  if (isNaN(bradyDate.getTime())) throw new Error('Cannot change to Brady Date: Invalid input date');

  return bradyDate.getUTCFullYear() * 10000 + (bradyDate.getUTCMonth() + 1) * 100 + bradyDate.getUTCDate();
}

/**
 * Converts legacy numeric date into a JS date object.  If the input is invalid, returns null instead of throwing.
 *
 * @param bradyDate The numeric date in the format YYYYMMDD, for example 20211216 for December 16th 2021.
 * @returns The JS date object.  If input is invalid, returns null.
 */
export function fromBradyDateOrNull(bradyDate: number | null): Date | null {
  let date: Date;
  try {
    date = fromBradyDate(bradyDate);
  } catch (e) {
    date = null;
  }
  return date;
}

export function fromBradyDateZeroes(bradyDate: number | null): Date | null {
  //validate the length of the date
  if (bradyDate === 0 || bradyDate === 21501231 || !bradyDate) return null;

  if (bradyDate > 99999999 || bradyDate < 10000000) {
    throw new Error('Date has wrong length');
  }

  const year = Math.floor(bradyDate / 10000);
  const month = Math.floor((bradyDate % 10000) / 100);
  const day = bradyDate % 100;

  if (year < 1970) throw new Error(`Error validating date (${bradyDate}): year cannot be less than 1970`);
  if (year > new Date().getFullYear() + 3) throw new Error(`Error validating date (${bradyDate}): year cannot be more than 3 years from today`);
  if (month > 13 || month < 1) throw new Error(`Error validating date (${bradyDate}): month out of range`);
  if (day > 31 || day < 0) throw new Error(`Error validating date (${bradyDate}): day out of range`);

  //Check for leap years

  const leapYear: boolean = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

  if (month === 2) {
    if (leapYear && day > 29) {
      throw new Error('Day out of range');
    } else if (!leapYear && day > 28) {
      throw new Error('Day out of range');
    }
  } else if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
    throw new Error('Day out of range');
  }

  const date = new Date(year, month - 1, day);
  return date;
}

/**
 * Converts JS date to legacy numeric date.
 *
 * @param date A JS date object.
 * @param withZeros Boleean to set or not zeros. e.g. if true then result is 09/04/2023, if false then 9/4/2023.
 * @returns The date in the format MM/DD/YYYY, for example 9/4/2023 for September 4th 2023.
 */
export function formatDate(date: Date, withZeros: boolean = false): string {
  const d = new Date(date),
    month = d.getUTCMonth() + 1,
    day = d.getUTCDate(),
    year = d.getUTCFullYear();

  if (withZeros) return [month < 10 ? '0' + month : month, day < 10 ? '0' + day : day, year].join('/');
  return [month, day, year].join('/');
}

export function createFormGroup<T>(
  options: { [key in keyof T]?: AbstractControl },
  validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions,
  asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
) {
  return new UntypedFormGroup(options, validatorOrOpts || null, asyncValidator || null);
}

/**
 * Compares two JS dates and returns true if they are the same calendar day.
 *
 * @param date1 String or js object.  Must be in JS date compatible format.  If value is a string, new Date() will be called.
 * @param date2 String or js object.  Must be in JS date compatible format.  If value is a string, new Date() will be called.
 * @returns If the dates are equal, true, otherwise false.
 */
export function compareDates(date1: string | Date, date2: string | Date): boolean {
  try {
    if (!date1 && !date2) return true;
    if (!date1 || !date2) return false;
    let firstDate = typeof date1 === 'string' ? new Date(date1) : date1;
    let secondDate = typeof date2 === 'string' ? new Date(date2) : date2;
    return firstDate.getDate() === secondDate.getDate() && firstDate.getMonth() === secondDate.getMonth() && firstDate.getFullYear() === secondDate.getFullYear();
  } catch (e) {
    console.warn(date1, date2, 'Tried to compare these invalid dates');
    return false;
  }
}

/**
 * Compares two JS dates and returns true if date1 is greater (further point in time ) than date2.
 * @param date1 String or js object.  Must be in JS date compatible format.  If value is a string, new Date() will be called.
 * @param date2 String or js object.  Must be in JS date compatible format.  If value is a string, new Date() will be called.
 * @returns date1 > date2
 */
export function dateGreaterThan(date1: string | Date, date2: string | Date): boolean {
  const firstDate = typeof date1 === 'string' ? new Date(date1) : date1;
  const secondDate = typeof date2 === 'string' ? new Date(date2) : date2;

  return firstDate > secondDate && !compareDates(firstDate, secondDate);
}

export function dateGreaterThanOrSameAs(date1: string | Date, date2: string | Date): boolean {
  const firstDate = typeof date1 === 'string' ? new Date(date1) : date1;
  const secondDate = typeof date2 === 'string' ? new Date(date2) : date2;

  return firstDate >= secondDate;
}

export function getToday() {
  let d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
}

/**
 * @returns The date exactly one year before the current day at 12:00 AM as a JS date object.
 */
export function getAYearAgo() {
  const currentDate = getToday();
  currentDate.setFullYear(currentDate.getFullYear() - 1);
  return currentDate;
}

/**
 * @returns The current calendar day at 12:00AM UTC as a JS date object.
 */
export function getTodayUTC() {
  let d = new Date();
  d.setUTCFullYear(d.getFullYear(), d.getMonth(), d.getDate());
  d.setUTCHours(0, 0, 0, 0);
  return d;
}

/**
 * @returns The previous calendar day at 12:00AM UTC as a JS date object.
 */
export function getYesterdayUTC() {
  let d = getTodayUTC();
  d.setUTCDate(d.getUTCDate() - 1);
  return d;
}

/**
 * @returns The next calendar day at 12:00AM UTC as a JS date object.
 */
export function getTomorrowUTC() {
  let d = getTodayUTC();
  d.setUTCDate(d.getUTCDate() + 1);
  return d;
}

/**
 * @returns The date exactly one year before the current day at 12:00 AM UTC as a JS date object.
 */
export function getAYearAgoUTC() {
  let d = getTodayUTC();
  d.setUTCFullYear(d.getUTCFullYear() - 1);
  return d;
}

/**
 * @returns The date exactly one month before the current day at 12:00 AM UTC as a JS date object.
 */
export function getAMonthAgoUTC() {
  return getNMonthsAgoUTC(1);
}

/**
 * @param n Number of months.
 * @returns The date exactly n months before the current day at 12:00 AM UTC as a JS date object.
 */
export function getNMonthsAgoUTC(n: number) {
  let d = getTodayUTC();
  d.setUTCMonth(d.getUTCMonth() - n);
  return d;
}

/**
 * @param _enum A TS enum.
 * @returns An array of the values from the input enum, assuming they are numeric.
 */
export function valuesFromEnum(_enum: object) {
  return Object.values(_enum).filter((v) => typeof v === 'number');
}

/**
 * @typeguard
 * @param file Either a file or a Thalos document entity.
 * @returns True if the object is a Thalos document.
 */
export function isDocument(file: File | Document): file is Document {
  return !!(file as Document).fileName;
}

/**
 * Recursively marks the input form group and all children as touched and upates their validity.
 * Excludes async validators to keep function entirely synchronous.
 * @param formGroup A formGroup
 */
export function markFormGroupTouched(formGroup: UntypedFormGroup | UntypedFormArray) {
  Object.keys(formGroup.controls)
    .map((x) => formGroup.controls[x])
    .forEach((control: AbstractControl) => {
      control.markAsTouched();

      control.updateValueAndValidity({ emitEvent: true });
      if ((control as UntypedFormGroup).controls) {
        markFormGroupTouched(control as UntypedFormGroup);
      }
    });
}
/**
 * Recursively marks the input form group and all children as touched and upates their validity.
 * Includes async validators
 * @param formGroup A formGroup
 * @returns An observable that emits the first non-pending status from the formGroup.
 */
export function markFormGroupTouchedAsync(formGroup: UntypedFormGroup | UntypedFormArray) {
  return from(
    (async () => {
      await new Promise(async (resolve) => {
        Object.keys(formGroup.controls)
          .map((x) => formGroup.controls[x])
          .forEach((control: AbstractControl) => {
            control.markAsTouched();

            control.updateValueAndValidity({ emitEvent: true });

            if ((control as UntypedFormGroup).controls) {
              markFormGroupTouched(control as UntypedFormGroup);
            }
          });
        await delay(2000);
        resolve(
          formGroup.statusChanges.pipe(
            startWith(formGroup.status),
            filter((status) => status !== FormControlStatus.PENDING),
            first()
          )
        );
      });
    })()
  );
}

/**
 * Recursively runs .clear on all formArrays and .reset on all formGroups that are children of the input formGroup.
 * @param form A form group
 * @param defaultValues Default values to reset the form to.  Optional.
 */
export function deepCleanForm<T>(form: TypedFormGroup<T>, defaultValues?: Partial<T>);
export function deepCleanForm(form: UntypedFormGroup, defaultValues: object = {}) {
  for (let i in form.controls) {
    let control = form.controls[i];
    if (control instanceof UntypedFormArray) {
      control.clear();
    }
    if (control instanceof UntypedFormGroup) {
      deepCleanForm(control);
    }
  }
  form.reset(defaultValues);
}

/**
 * @param x A number, either as a numbe or a stringified number
 * @returns The number as a string with thousand comma seperators added.  Ignores numbers after decimal.
 */
export function numberWithCommas(x: string | number) {
  let str = x.toString();
  let stringParts = str.split('.');
  if (stringParts.length === 0) {
    return str;
  }
  let preDecimal = stringParts.shift().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  stringParts.unshift(preDecimal);
  return stringParts.join('.');
}

/**
 * @param n A number
 * @param places Number of decimal places
 * @returns N with significant digits past n removed.
 */
export function limitDecimalPlaces(n: number, places: number) {
  return Number(n.toFixed(places));
}

/**
 * @param n A number
 * @param places Decimal places.  Defaults to 2
 * @returns The number as a string with thousand comma seperators added and exactly the given number of decimal places.
 */
export function dollarAmountFormat(n: number, places: number = 2) {
  return numberWithCommas(n.toFixed(places));
}

/**
 *
 * @param n A number
 * @param places Decimal places.  Defaults to 2
 * @returns The number as a string with thousand comma seperators added and at most the given number of decimal places.
 */
export function weightFormat(n: number, places: number = 2) {
  return numberWithCommas(limitDecimalPlaces(n, places));
}

/**
 * @param str A string
 * @returns Converts snake_case to Title Case.
 */
export function snakeCaseToTitleCase(str: string) {
  return str.replace(/[a-zA-Z0-9]*([-_]|$)/g, (s) => {
    s = s.toLowerCase().replace(/[-_]/g, ' ');
    if (s.length > 0) s = s[0].toUpperCase() + s.substr(1, s.length - 1);
    return s;
  });
}

export function enumOptionsFromEnum<T extends number>(obj: Object, exclude?: T[]): EnumLabels<T> {
  if (!obj) return [];

  const values: T[] = Object.values(obj).filter((v) => typeof v === 'number');

  const enumLabels: EnumLabels<T> = [];
  for (let i in values) {
    if (!values[i] && values[i] !== 0) continue;
    let label = obj[`${values[i]}` as string];
    if (label && (!exclude || !exclude.includes(values[i]))) {
      enumLabels.push({ value: values[i], label: snakeCaseToTitleCase(label) });
    }
  }

  return enumLabels;
}

/**
 *
 * @param store The store service.
 * @param component Any component with an ngOnDestroy hook and a endpointsAuthorized property.
 * @returns An observable that completes when the endpoints are loaded into the store.  This should only be relevant if logic is needed in the constructor.
 */
export function endpointAuthorizationSubscription<T extends { authorized: endpointsAuthorized } & OnDestroy>(store: Store, component: T) {
  let observer: Subscriber<any>;
  let initialized: boolean = false;
  let observable = new Observable((sub) => {
    observer = sub;
    if (initialized) {
      observer.next(true);
      observer.complete();
    }
  });
  const storeSub = store.subscribe((state) => state.user.authorization, [LOADED_USER_ENDPOINTS]);
  storeSub.$.pipe(untilDestroyed(component)).subscribe((userEndpoints) => {
    if (!component.authorized) {
      initialized = true;
      if (observer) {
        observer.next(true);
        observer.complete();
      }
    }
    component.authorized = userEndpoints;
  });

  let originalDestroy = component.ngOnDestroy.bind(component);
  let newDestroy = function () {
    storeSub.unsubscribe();
    originalDestroy();
  };
  component.ngOnDestroy = newDestroy.bind(component);

  return observable;
}

/**
 *
 * @param store The store service.
 * @param component Any component with an ngOnDestroy hook and a graphqlAuthorized property.
 * @returns An observable that completes when the graphqls are loaded into the store.  This should only be relevant if logic is needed in the constructor.
 */
export function graphqlAuthorizationSubscription<T extends { graphqlAuthorized: graphqlAuthorized } & OnDestroy>(store: Store, component: T) {
  let observer: Subscriber<any>;
  let initialized: boolean = false;
  const observable = new Observable((sub) => {
    observer = sub;
    if (initialized) {
      observer.next(true);
      observer.complete();
    }
  });
  const storeSub = store.subscribe((state) => state.user.graphqlAuthorization, [LOADED_USER_GRAPHQLS]);
  storeSub.$.pipe(untilDestroyed(component)).subscribe((userGraphqls) => {
    if (!component.graphqlAuthorized) {
      initialized = true;
      if (observer) {
        observer.next(true);
        observer.complete();
      }
    }
    component.graphqlAuthorized = userGraphqls;
  });

  const originalDestroy = component.ngOnDestroy.bind(component);
  const newDestroy = function () {
    storeSub.unsubscribe();
    originalDestroy();
  };
  component.ngOnDestroy = newDestroy.bind(component);

  return observable;
}

/**
 * A key value map of all endpoints and whether not the user has access to them.
 */
export type endpointsAuthorized = { [key in endpoints]?: boolean };

/**
 * A key value map of all graphqls and whether not the user has access to them.
 */
export type graphqlAuthorized = { [key in ExistingGraphql]?: boolean };

/**
 *
 * @param data Opens an array buffer in the browser for downloading.
 * @param fileName Name of the file.
 */
export function downloadFile(data: ArrayBuffer, fileName: string) {
  let blob = new Blob([data]);
  let a = document.createElement('a');
  let url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = fileName;
  a.click();

  window.URL.revokeObjectURL(url);
}

/**
 *
 * @param v Any value.
 * @returns True if the value is null, undefined or an empty string.
 */
export function isNonZeroEmpty(v: any) {
  return v === null || v === undefined || v === '';
}

export function getVoucherSign(documentType: InvoiceDocumentType, voucherType: VoucherType) {
  if (!enumValues(InvoiceDocumentType).includes(documentType)) return;
  if (!enumValues(VoucherType).includes(voucherType)) return;

  const documentTypeFactor: { [x in InvoiceDocumentType]: 1 | -1 } = { 1: 1, 2: -1 };
  const voucherTypeFactor: { [x in VoucherType]: 1 | -1 } = { 1: -1, 2: 1, 3: -1, 4: -1 };

  return documentTypeFactor[documentType] * voucherTypeFactor[voucherType];
}

export function multipleGroupByArray<T>(dataArray: T[], groupPropertyArray: (property: T) => number[]) {
  const groups = {};
  dataArray.forEach((item) => {
    const group = JSON.stringify(groupPropertyArray(item));
    groups[group] = groups[group] || [];
    groups[group].push(item);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group] as T[];
  });
}

export function removeLineBreaks(text: string) {
  const regex = /(\n\r|\r\n|\t|\n)+/g;
  const value = !!text ? text.replace(regex, ' ') : text;
  return value;
}

export function openFlexForm<T, R = T>(
  delegate: DelegateService,
  preset: DynamicFormConstant<T, R>,
  id: number,
  callback?: (result: any, params: GetContextMenuItemsParams) => void,
  prefillOverride?: (params: GetContextMenuItemsParams) => Observable<Partial<T>> | Partial<T>,
  column?: IDListColumn,
  params?: GetContextMenuItemsParams,
  data?: any[]
) {
  const selector = delegate.getService('selector');
  const spinner = delegate.getService('spinner');
  const dialog = delegate.getService('dialog');
  const title = `${preset.title || preset.label}`;

  let obsv: Observable<Partial<T>>;
  if (prefillOverride) {
    const override = prefillOverride(params);
    if (!isObservable(override)) {
      obsv = of(override);
    } else {
      obsv = override;
    }
  } else {
    obsv = preset.getPrefill ? preset.getPrefill(delegate, id, column, data) : of({});
  }

  let rid = spinner.startRequest(randomFetchSynonym() + ' Data');
  obsv
    .pipe(
      switchMap((prefill) => {
        if (preset.checkPrefill) {
          return preset.checkPrefill(delegate, id, prefill).pipe(map((prefillValid) => (prefillValid === true ? prefill : prefillValid)));
        } else {
          return of(prefill);
        }
      })
    )
    .subscribe((prefill) => {
      spinner.completeRequest(rid);
      if (typeof prefill === 'string') {
        dialog.open({
          title: 'Error',
          content: prefill,
        });
        return;
      } else if (prefill === null || prefill === false) {
        return;
      }

      let formResult: Observable<Partial<T> | SelectorResult<R>>;
      if (preset.createForm) {
        formResult = selector.dynamicForm<T, R>(title, prefill as R, preset.width, ...preset.createForm(delegate, id, prefill, column));
      } else if (preset.openForm) {
        // TODO: General refactor of types for modal forms/flexviews.
        // Prefill is actually a Partial of T in many instances, adding typecast to T to bypass TypeScript errors after making the modal form types a bit more strict
        formResult = preset.openForm(delegate, id, prefill as T, column);
      } else {
        formResult = of(prefill);
      }

      formResult.subscribe((formResult) => {
        if (formResult !== 'Close') {
          let rid = spinner.startBackgroundRequest('Submitting');
          preset.submitForm(delegate, id, formResult as R, prefill).subscribe((result) => {
            spinner.completeRequest(rid);
            if (result && callback) {
              callback(result, params);
            }
          });
        }
      });
    });
}

export function getRandomColor() {
  let color = '#';
  for (let i = 0; i < 3; i++) color += ('0' + Math.floor((Math.random() * Math.pow(16, 2)) / 1.3).toString(16)).slice(-2);
  return color;
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Function to split lines separated by commas and remove leading and trailing whitespace from each line
export function splitLinesByCommas(text: string): string {
  // Split the text into lines
  const lines = text.split(/\r?\n/);

  // Process each line
  const processedLines = lines
    .map((line) => line.trim()) // Remove leading and trailing whitespace
    .filter((line) => line.length > 0) // Filter out empty lines
    .map((line) =>
      line
        .split(/\s+/)
        .filter((word) => word.length > 0)
        .join(',')
    ); // Split line into words, filter out empty words, and join with commas

  // Join the processed lines with commas
  return processedLines.join(',');
}

// Function to join lines separated by commas into lines separated by line breaks
export function joinLinesByLineBreaks(text: string) {
  // Split the text into lines by commas and remove leading and trailing whitespace from each line
  const lines = text.split(',').map((line) => line.trim());

  // Join the lines with line breaks
  return lines.join('\n');
}

export function getUniqueArray(array: any[]) {
  const uniqueArray = array.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return uniqueArray;
}

/**
 * Fetches the list of companies authorized for the user from the store.
 *
 * @param  store - The store instance from which to retrieve the state.
 * @returns  - An array of user authorized companies.
 */
export function getAuthorizedCompaniesByUser(store: Store): UserAuthorizedCompanies[] {
  return store.snapshot((state: State) => state.user.authorizedCompanies);
}

/**
 * Retrieves the default authorized company from a list of companies.
 *
 * @param companies - An array of company contacts to search from.
 * @param  store - The store instance to use for fetching user authorized companies.
 * @returns  The default authorized company contact or null if not found.
 */
export function getCompanyByDefaultAuthorizedCompany(store: Store) {
  const authorizedCompanies = getAuthorizedCompaniesByUser(store);
  const defaultAuthorizedCompany = authorizedCompanies.find((item) => item.isDefault === YN.Y);
  return defaultAuthorizedCompany?.company ?? null;
}

/**
 * @param date initial date to which days are going to be added/subtracted
 * @param daysToAddOrSubtract number of days to add/subtract
 * @param addDays if true add days else subtract days
 * @returns returns a new date with the days added/subtracted
 */
export function addOrSubstractWorkDays(date: Date, daysToAddOrSubtract: number, addDays: boolean) {
  for (let i = 0; i < daysToAddOrSubtract; i++) {
    date.setDate(date.getDate() + (addDays ? +1 : -1)); // Add or subtract days depending on addDays value
    // Check if is weekend day
    if (date.getDay() === 0 || date.getDay() === 6) daysToAddOrSubtract++; // If is weekend day then add 1 day
  }
  return date;
}

/**
 *
 * @param input A number
 * @param numOfDecimals Decimal places.  Defaults to 2
 * @returns The rounded absolute number with the original sign (-) or (+)
 */
export function roundAmount(input: number, numOfDecimals: number = 2) {
  if (input === 0) return 0;
  const inputAbsValue = Math.abs(input);
  const powNumber = Math.pow(10, numOfDecimals);
  return (Math.round(inputAbsValue * powNumber) / powNumber) * (inputAbsValue / input);
}

// Returns the first day of the month of the specified date
export function firstDayOfMonth(date: Date | string): Date {
  const convertedDate = typeof date === 'string' ? new Date(date) : date;
  return new Date(Date.UTC(convertedDate.getUTCFullYear(), convertedDate.getUTCMonth(), 1));
}

// Returns the last day of the month of the specified date
export function lastDayOfMonth(date: Date | string): Date {
  const convertedDate = typeof date === 'string' ? new Date(date) : date;
  return new Date(Date.UTC(convertedDate.getUTCFullYear(), convertedDate.getUTCMonth() + 1, 0));
}
