import { BankAccount, BudgetElement, DocumentPacket, ExchangeContract, FiscalCompany, Item, JournalEntry, JournalHeader, NetPositionLines, PaymentTerm, WaTermDate } from '.';
import { EnumLabels } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { ReportAutomationDefinition } from './automatedReports';
import { Contact } from './contact';
import { Container } from './container';
import { PhysicalContract } from './contract';
import { BaseDiscrepancy } from './discrepancy';
import { PhysicalDraft } from './draft';
import { Booking } from './freightBooking';
import { FutureContract } from './futureContract';
import { FutureMatching } from './futureMatching';
import { GlAccountBranches, GlAccountLeaves } from './glAccount';
import { getVoucherLabel, InvoiceHeader, PayableItem } from './invoice';
import { LetterOfCredit } from './letterOfCredit';
import { LoCPresentations } from './letterOfCreditPresentation';
import { PaymentEntry, PaymentHeader } from './payment';
import { PayOrderHeader } from './payOrder';
import { PremiumFixationList } from './PremiumFixation';
import { PcPriceFixing } from './priceFixation';
import { PropertyDocument } from './propertyDocument';
import { ServiceOrder } from './serviceOrder';
import { ThalosLetterOfCredit } from './thalosLetterOfCredit';
import { OceanratesRate, OceanratesSurchargeDefinition } from './oceanRate';
import { NetPositionPriceReportItems } from './netPositionPriceReport';
import { Impute } from './impute';
import { Advance } from './advance';
import { WaConstelliumAppointment } from './waConstelliumAppointment';
import { UpdateBookingStatsTrack } from './updateBookingStats';
import { NetPositionSummaryItems } from './netPositionSummary';

export enum SourceEntityType {
  PAY_ORDER_KEY = 190, //Brady
  ALF_CODE = 1,
  LETTER_OF_CREDIT_KEY = 164,
  FREIGHT_BOOKING_KEY = 44086,
  CONTRACT_KEY = 44000,
  CHUNK_KEY = 44003,
  INVOICE_KEY = 240,
  SERVICE_ORDER_KEY = 44010,
  JOURNAL_ID = 14,
  PRICE_FIXING_KEY = 44022,
  DRAFT_KEY = 900000,
  DISCREPANCY_ID = 900002,
  CONTAINER_ID = 900003,
  CONTRACT_LINE_ID = 900200,
  FX_FIXATION_DEAL = 900210,
  RESERVED_INVOICE_ID = 900211,
  EXCHANGE_CONTRACT = 900212,
  PREMIUM_FIXATION_KEY = 900213,
  PAYMENT_KEY = 5,
  PAYABLE_ITEM_KEY = 900214,
  JOURNAL_ENTRY_ID = 900215,
  AUTOMATED_REPORTS_ID = 900216,
  GL_ACCOUNT_LEAVES_ID = 900217,
  FISCAL_COMPANY_KEY = 900218, // This ID must not be used in flexViews
  FUTURE_KEY = 44006, //Brady
  FUTURE_MATCHING = 44014, //Brady
  THALOS_LETTER_OF_CREDIT = 900219,
  LETTER_OF_CREDIT_PRESENTATION = 900220,
  ITEM_KEY = 900221,
  RATE_KEY = 900222,
  SURCHARGE_TYPE_KEY = 900223,
  BUDGET_ELEMENT_KEY = 900224,
  IMPUTE_ID = 900225,
  ADVANCE_KEY = 900226,
  OPEN_FUTURE_MTM_KEY = 900227,
  APPOINTMENTS = 900228,
  NET_POSITION_SUMMARY_KEY = 900229,
  BOOKING_UPDATE_TRACK = 900230,
  GL_ACCOUNT_BRANCHES_ID = 900231,
  PAYMENT_TERM_ID = 900232,
  PAYMENT_TERM_DATE_ID = 900233,
  NET_POSITION_ENTRIES_KEY = 900234,
  NET_POSITION_PRICE_REPORT_KEY = 900235,
  BANK_ACCOUNT_KEY = 900236,
  DOCUMENT_PACKET_KEY = 900237,
}
export const SourceEntityTypes = enumOptionsFromEnum(SourceEntityType);

export const SourceEntityTypeEntityNameMap: { [key in SourceEntityType]: string } = {
  [SourceEntityType.ALF_CODE]: 'Contact',
  [SourceEntityType.CONTAINER_ID]: 'Container',
  [SourceEntityType.CONTRACT_KEY]: 'Contract',
  [SourceEntityType.DISCREPANCY_ID]: 'Discrepancy',
  [SourceEntityType.DRAFT_KEY]: 'Draft',
  [SourceEntityType.FREIGHT_BOOKING_KEY]: 'Booking',
  [SourceEntityType.SERVICE_ORDER_KEY]: 'Service Order',
  [SourceEntityType.CHUNK_KEY]: 'Shipment',
  [SourceEntityType.INVOICE_KEY]: 'Invoice',
  [SourceEntityType.PAY_ORDER_KEY]: 'Pay Order',
  [SourceEntityType.CONTRACT_LINE_ID]: 'Contract Line',
  [SourceEntityType.LETTER_OF_CREDIT_KEY]: 'Letter of Credit',
  [SourceEntityType.FX_FIXATION_DEAL]: 'Fx Fixation Deal',
  [SourceEntityType.JOURNAL_ID]: 'Journal',
  [SourceEntityType.EXCHANGE_CONTRACT]: 'Exchange Contract',
  [SourceEntityType.RESERVED_INVOICE_ID]: 'Draft Invoice',
  [SourceEntityType.PRICE_FIXING_KEY]: 'Price Fixation',
  [SourceEntityType.PREMIUM_FIXATION_KEY]: 'Premium Fixation',
  [SourceEntityType.PAYMENT_KEY]: 'Payments',
  [SourceEntityType.PAYABLE_ITEM_KEY]: 'Payable Item',
  [SourceEntityType.JOURNAL_ENTRY_ID]: 'Journal Entry',
  [SourceEntityType.GL_ACCOUNT_LEAVES_ID]: 'GL Account Leaf',
  [SourceEntityType.AUTOMATED_REPORTS_ID]: 'Automated Report',
  [SourceEntityType.FISCAL_COMPANY_KEY]: 'Fiscal Company',
  [SourceEntityType.FUTURE_KEY]: 'Futures Contracts',
  [SourceEntityType.FUTURE_MATCHING]: 'Futures Matching',
  [SourceEntityType.THALOS_LETTER_OF_CREDIT]: 'Letters Of Credit',
  [SourceEntityType.LETTER_OF_CREDIT_PRESENTATION]: 'LoC Presentation',
  [SourceEntityType.RATE_KEY]: 'Rate',
  [SourceEntityType.ITEM_KEY]: 'Item',
  [SourceEntityType.SURCHARGE_TYPE_KEY]: 'Surcharge',
  [SourceEntityType.BUDGET_ELEMENT_KEY]: 'Budget Element',
  [SourceEntityType.IMPUTE_ID]: 'Budget Element Accounting',
  [SourceEntityType.ADVANCE_KEY]: 'Advance',
  [SourceEntityType.OPEN_FUTURE_MTM_KEY]: 'Open Futures MTM',
  [SourceEntityType.APPOINTMENTS]: 'Appointments',
  [SourceEntityType.NET_POSITION_SUMMARY_KEY]: 'Net Position Summary',
  [SourceEntityType.NET_POSITION_ENTRIES_KEY]: 'Net Position Entries',
  [SourceEntityType.NET_POSITION_PRICE_REPORT_KEY]: 'Net Position Price Report',
  [SourceEntityType.BOOKING_UPDATE_TRACK]: 'Booking Update Track',
  [SourceEntityType.GL_ACCOUNT_BRANCHES_ID]: 'GL Account Branch',
  [SourceEntityType.PAYMENT_TERM_ID]: 'Payment Term',
  [SourceEntityType.PAYMENT_TERM_DATE_ID]: 'Payment Term Date',
  [SourceEntityType.BANK_ACCOUNT_KEY]: 'Bank Account',
  [SourceEntityType.DOCUMENT_PACKET_KEY]: 'Document Packet',
};

export const SourceEntityTypeNames: EnumLabels<SourceEntityType> = Object.keys(SourceEntityTypeEntityNameMap).flatMap((v) => {
  let n: number;
  try {
    n = parseInt(v);
  } catch (e) {
    return [];
  }
  return { label: SourceEntityTypeEntityNameMap[v], value: n };
});

export type Entity<T extends SourceEntityType> = T extends SourceEntityType.ALF_CODE
  ? Contact
  : T extends SourceEntityType.CHUNK_KEY
  ? PropertyDocument
  : T extends SourceEntityType.CONTAINER_ID
  ? Container
  : T extends SourceEntityType.CONTRACT_KEY
  ? PhysicalContract
  : T extends SourceEntityType.DISCREPANCY_ID
  ? BaseDiscrepancy
  : T extends SourceEntityType.DRAFT_KEY
  ? PhysicalDraft
  : T extends SourceEntityType.FREIGHT_BOOKING_KEY
  ? Booking
  : T extends SourceEntityType.SERVICE_ORDER_KEY
  ? ServiceOrder
  : T extends SourceEntityType.INVOICE_KEY
  ? InvoiceHeader
  : T extends SourceEntityType.LETTER_OF_CREDIT_KEY
  ? LetterOfCredit
  : T extends SourceEntityType.FX_FIXATION_DEAL
  ? any
  : T extends SourceEntityType.RESERVED_INVOICE_ID
  ? any
  : T extends SourceEntityType.EXCHANGE_CONTRACT
  ? ExchangeContract
  : T extends SourceEntityType.JOURNAL_ID
  ? JournalHeader
  : T extends SourceEntityType.PAY_ORDER_KEY
  ? PayOrderHeader
  : T extends SourceEntityType.PRICE_FIXING_KEY
  ? PcPriceFixing
  : T extends SourceEntityType.PREMIUM_FIXATION_KEY
  ? PremiumFixationList
  : T extends SourceEntityType.PAYMENT_KEY
  ? PaymentHeader
  : T extends SourceEntityType.PAYABLE_ITEM_KEY
  ? PayableItem
  : T extends SourceEntityType.JOURNAL_ENTRY_ID
  ? JournalEntry
  : T extends SourceEntityType.GL_ACCOUNT_LEAVES_ID
  ? GlAccountLeaves
  : T extends SourceEntityType.GL_ACCOUNT_BRANCHES_ID
  ? GlAccountBranches
  : T extends SourceEntityType.AUTOMATED_REPORTS_ID
  ? ReportAutomationDefinition
  : T extends SourceEntityType.FISCAL_COMPANY_KEY
  ? FiscalCompany
  : T extends SourceEntityType.FUTURE_KEY
  ? FutureContract
  : T extends SourceEntityType.FUTURE_MATCHING
  ? FutureMatching
  : T extends SourceEntityType.THALOS_LETTER_OF_CREDIT
  ? ThalosLetterOfCredit
  : T extends SourceEntityType.LETTER_OF_CREDIT_PRESENTATION
  ? LoCPresentations
  : T extends SourceEntityType.RATE_KEY
  ? OceanratesRate
  : T extends SourceEntityType.ITEM_KEY
  ? Item
  : T extends SourceEntityType.SURCHARGE_TYPE_KEY
  ? OceanratesSurchargeDefinition
  : T extends SourceEntityType.NET_POSITION_SUMMARY_KEY
  ? NetPositionSummaryItems
  : T extends SourceEntityType.NET_POSITION_ENTRIES_KEY
  ? NetPositionLines
  : T extends SourceEntityType.NET_POSITION_PRICE_REPORT_KEY
  ? NetPositionPriceReportItems
  : T extends SourceEntityType.BUDGET_ELEMENT_KEY
  ? BudgetElement
  : T extends SourceEntityType.IMPUTE_ID
  ? Impute
  : T extends SourceEntityType.ADVANCE_KEY
  ? Advance
  : T extends SourceEntityType.OPEN_FUTURE_MTM_KEY
  ? any
  : T extends SourceEntityType.APPOINTMENTS
  ? WaConstelliumAppointment
  : T extends SourceEntityType.NET_POSITION_SUMMARY_KEY
  ? NetPositionSummaryItems
  : T extends SourceEntityType.BOOKING_UPDATE_TRACK
  ? UpdateBookingStatsTrack
  : T extends SourceEntityType.PAYMENT_TERM_ID
  ? PaymentTerm
  : T extends SourceEntityType.PAYMENT_TERM_DATE_ID
  ? WaTermDate
  : T extends SourceEntityType.BANK_ACCOUNT_KEY
  ? BankAccount
  : T extends SourceEntityType.DOCUMENT_PACKET_KEY
  ? DocumentPacket
  : never;

export function getEntityName<T extends SourceEntityType>(type: T, entity: Entity<T>): string {
  switch (type) {
    case SourceEntityType.ALF_CODE:
      return (entity as Entity<SourceEntityType.ALF_CODE>).displayName;
    case SourceEntityType.CHUNK_KEY:
      return `${(entity as PropertyDocument).id}`;
    case SourceEntityType.CONTAINER_ID:
      return `${(entity as Container).id}`;
    case SourceEntityType.CONTRACT_KEY:
      return `${(entity as PhysicalContract).number}`;
    case SourceEntityType.DISCREPANCY_ID:
      return `${(entity as BaseDiscrepancy).id}`;
    case SourceEntityType.DRAFT_KEY:
      return `${(entity as PhysicalDraft).number}`;
    case SourceEntityType.EXCHANGE_CONTRACT:
      return `${(entity as ExchangeContract).contractNumber}`;
    case SourceEntityType.FREIGHT_BOOKING_KEY:
      return `${(entity as Booking).number}`;
    case SourceEntityType.INVOICE_KEY:
      return `${(entity as InvoiceHeader).number}`;
    case SourceEntityType.PAY_ORDER_KEY:
      return `${(entity as PayOrderHeader).payOrderNumber}`;
    case SourceEntityType.SERVICE_ORDER_KEY:
      return `${(entity as ServiceOrder).id}`;
    case SourceEntityType.JOURNAL_ID:
      return `${(entity as JournalHeader).journalIndex}`;
    case SourceEntityType.PRICE_FIXING_KEY:
      return `${(entity as PcPriceFixing).id}`;
    case SourceEntityType.PREMIUM_FIXATION_KEY:
      return `${(entity as PremiumFixationList).id}`;
    case SourceEntityType.PAYMENT_KEY:
      return `${(entity as PaymentHeader).documentReference}`;
    case SourceEntityType.PAYABLE_ITEM_KEY:
      return getPayableItemName(entity as PayableItem);
    case SourceEntityType.JOURNAL_ENTRY_ID:
      return `${(entity as JournalEntry).journalEntryId}`;
    case SourceEntityType.GL_ACCOUNT_LEAVES_ID:
      return `${(entity as GlAccountLeaves).id}`;
    case SourceEntityType.GL_ACCOUNT_BRANCHES_ID:
      return `${(entity as GlAccountBranches).id}`;
    case SourceEntityType.AUTOMATED_REPORTS_ID:
      return `${(entity as ReportAutomationDefinition).id}`;
    case SourceEntityType.FISCAL_COMPANY_KEY:
      return `${(entity as FiscalCompany).companyId}`;
    case SourceEntityType.FUTURE_KEY:
      return `${(entity as FutureContract).contractNumber}`;
    case SourceEntityType.FUTURE_MATCHING:
      return `${(entity as FutureMatching).futurMatchKey}`;
    case SourceEntityType.THALOS_LETTER_OF_CREDIT:
      return `${(entity as ThalosLetterOfCredit).id}`;
    case SourceEntityType.LETTER_OF_CREDIT_PRESENTATION:
      return `${(entity as LoCPresentations).id}`;
    case SourceEntityType.RATE_KEY:
      return `${(entity as OceanratesRate).rateKey}`;
    case SourceEntityType.ITEM_KEY:
      return `${(entity as Item).id}`;
    case SourceEntityType.SURCHARGE_TYPE_KEY:
      return `${(entity as OceanratesSurchargeDefinition).sdefinitionKey}`;
    case SourceEntityType.NET_POSITION_ENTRIES_KEY:
      return `${(entity as NetPositionLines).id}`;
    case SourceEntityType.NET_POSITION_PRICE_REPORT_KEY:
      return `${(entity as NetPositionPriceReportItems).futureContractKey}`;
    case SourceEntityType.BUDGET_ELEMENT_KEY:
      return `${(entity as BudgetElement).id}`;
    case SourceEntityType.IMPUTE_ID:
      return `${(entity as Impute).codeImpute}`;
    case SourceEntityType.ADVANCE_KEY:
      return `${(entity as Advance).id}`;
    case SourceEntityType.NET_POSITION_SUMMARY_KEY:
      return `${(entity as NetPositionSummaryItems).netPosition}`;
    case SourceEntityType.APPOINTMENTS:
      return `${(entity as WaConstelliumAppointment).appointmentReference}`;
    case SourceEntityType.BOOKING_UPDATE_TRACK:
      return `${(entity as UpdateBookingStatsTrack).bookingId}`;
    case SourceEntityType.PAYMENT_TERM_ID:
      return `${(entity as PaymentTerm).id}`;
    case SourceEntityType.PAYMENT_TERM_DATE_ID:
      return `${(entity as WaTermDate).id}`;
    case SourceEntityType.BANK_ACCOUNT_KEY:
      return `${(entity as BankAccount).id}`;
    case SourceEntityType.DOCUMENT_PACKET_KEY:
      return `${(entity as DocumentPacket).id}`;
    default:
      return 'Unknown';
  }
}

function getPayableItemName(item: PayableItem) {
  if ('paymentId' in item) {
    if (!!item.paymentHeader) return `Payment ${item.paymentHeader.documentReference} / ${item.lineNumber}`;
    return `Payment ${item.paymentId} / ${item.lineNumber}`;
  } else if ('voucherType' in item) {
    return `${getVoucherLabel({ documentType: item.documentType, voucherType: item.voucherType })} ${item.number}`;
  } else {
    return `Payable ${typeof item === 'object' && 'id' in item ? (item as any).id : 'unknown'}`;
  }
}

export function getEntityId<T extends SourceEntityType>(type: T, entity: Entity<T>): number | null {
  switch (type) {
    case SourceEntityType.ALF_CODE:
      return (entity as Entity<SourceEntityType.ALF_CODE>).id;
    case SourceEntityType.CHUNK_KEY:
      return (entity as PropertyDocument).id;
    case SourceEntityType.CONTAINER_ID:
      return (entity as Container).id;
    case SourceEntityType.CONTRACT_KEY:
      return (entity as PhysicalContract).id;
    case SourceEntityType.DISCREPANCY_ID:
      return (entity as BaseDiscrepancy).id;
    case SourceEntityType.DRAFT_KEY:
      return (entity as PhysicalDraft).id;
    case SourceEntityType.EXCHANGE_CONTRACT:
      return (entity as ExchangeContract).id;
    case SourceEntityType.FREIGHT_BOOKING_KEY:
      return (entity as Booking).id;
    case SourceEntityType.INVOICE_KEY:
      return (entity as InvoiceHeader).id;
    case SourceEntityType.PAY_ORDER_KEY:
      return (entity as PayOrderHeader).id;
    case SourceEntityType.SERVICE_ORDER_KEY:
      return (entity as ServiceOrder).id;
    case SourceEntityType.JOURNAL_ID:
      return (entity as JournalHeader).id;
    case SourceEntityType.PRICE_FIXING_KEY:
      return (entity as PcPriceFixing).id;
    case SourceEntityType.PREMIUM_FIXATION_KEY:
      return (entity as PremiumFixationList).id;
    case SourceEntityType.PAYMENT_KEY:
      return (entity as PaymentHeader).id;
    case SourceEntityType.PAYABLE_ITEM_KEY:
      return 'id' in (entity as InvoiceHeader) ? entity.id : 'paymentEntryId' in (entity as PaymentEntry) ? entity.paymentEntryId : null;
    case SourceEntityType.JOURNAL_ENTRY_ID:
      return (entity as JournalEntry).journalEntryId;
    case SourceEntityType.GL_ACCOUNT_LEAVES_ID:
      return (entity as GlAccountLeaves).id;
    case SourceEntityType.GL_ACCOUNT_BRANCHES_ID:
      return (entity as GlAccountBranches).id;
    case SourceEntityType.AUTOMATED_REPORTS_ID:
      return (entity as ReportAutomationDefinition).id;
    case SourceEntityType.FISCAL_COMPANY_KEY:
      return (entity as FiscalCompany).companyId;
    case SourceEntityType.FUTURE_KEY:
      return (entity as FutureContract).id;
    case SourceEntityType.FUTURE_MATCHING:
      return (entity as FutureMatching).futurMatchKey;
    case SourceEntityType.THALOS_LETTER_OF_CREDIT:
      return (entity as ThalosLetterOfCredit).id;
    case SourceEntityType.LETTER_OF_CREDIT_PRESENTATION:
      return (entity as LoCPresentations).id;
    case SourceEntityType.RATE_KEY:
      return (entity as OceanratesRate).rateKey;
    case SourceEntityType.ITEM_KEY:
      return (entity as Item).id;
    case SourceEntityType.SURCHARGE_TYPE_KEY:
      return (entity as OceanratesSurchargeDefinition).sdefinitionKey;
    case SourceEntityType.NET_POSITION_ENTRIES_KEY:
      return (entity as NetPositionLines).id;
    case SourceEntityType.NET_POSITION_PRICE_REPORT_KEY:
      return (entity as NetPositionPriceReportItems).futureContractKey;
    case SourceEntityType.BUDGET_ELEMENT_KEY:
      return (entity as BudgetElement).id;
    case SourceEntityType.IMPUTE_ID:
      return (entity as Impute).codeImpute;
    case SourceEntityType.ADVANCE_KEY:
      return (entity as Advance).id;
    case SourceEntityType.PAYMENT_TERM_ID:
      return (entity as PaymentTerm).id;
    case SourceEntityType.PAYMENT_TERM_DATE_ID:
      return (entity as WaTermDate).id;
    case SourceEntityType.BANK_ACCOUNT_KEY:
      return (entity as BankAccount).id;
    case SourceEntityType.DOCUMENT_PACKET_KEY:
      return (entity as DocumentPacket).id;
    default:
      return null;
  }
}
