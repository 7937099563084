import { Component, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { NumerictextboxWrapperComponent } from 'src/app/shared/form-elements/numerictextbox-wrapper/numerictextbox-wrapper.component';
import { DropdownConfig } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { bankDropdown, budgetElementDropdown } from 'src/lib/commonTypes';
import { CreateAdvancePaymentForm, CreateAdvancePaymentPrefill, feesForm, OpenItemsLineForm } from 'src/lib/flex/forms/createAdvancePayment';
import { bradyDateValidator } from 'src/lib/genericValidators';
import { getTodayUTC, markFormGroupTouched } from 'src/lib/helperFunctions';
import { BankAccount, YN } from 'src/lib/newBackendTypes';
import { TypedFormArray, TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'accounting-advance-payment',
  templateUrl: './advance-payment.component.html',
  styleUrls: ['./advance-payment.component.scss'],
})
export class AdvancePaymentComponent implements ModalFormComponent<CreateAdvancePaymentForm, CreateAdvancePaymentPrefill> {
  @ViewChild('amountField', { static: false })
  amountField: NumerictextboxWrapperComponent;
  popup: boolean = true;

  form: TypedFormGroup<CreateAdvancePaymentForm>;

  currencyId: number | null;

  companyId: number | null;
  bankId: number | null;

  amount: number | null;
  requestedAmount: number | null;
  balancedAmount: number | null;
  totalFees: number | null;

  bankDropdown = bankDropdown();
  accountDropdown: DropdownConfig<BankAccount>;
  budgetElementDropdown = budgetElementDropdown();

  amountFormat: NumberFormatOptions;

  constructor(private delegate: DelegateService, private formatter: DataFormattingService) {
    this.form = new TypedFormGroup<CreateAdvancePaymentForm>({
      valueDate: new UntypedFormControl(getTodayUTC(), [Validators.required, bradyDateValidator()]),
      bank: new UntypedFormControl(null, Validators.required),
      account: new UntypedFormControl(null, Validators.required),
      amount: new UntypedFormControl(null),
      requestedAmount: new UntypedFormControl(null),
      balancedAmount: new UntypedFormControl(null),
      fees: new UntypedFormArray([]),
      openItemsLines: new UntypedFormControl([{ lineNumber: 1 }], []),
    });
  }

  ngAfterViewInit(): void {
    if (this.form && this.form.touched) {
      this.amountField.focus();
    }
  }

  prefillForm(data: CreateAdvancePaymentPrefill) {
    let lineNumber = 1;

    this.currencyId = data.advanceItem.currencyId;
    this.requestedAmount = data.advanceItem.totalAmount;
    this.bankId = data.advanceItem.bankId;
    this.companyId = data.advanceItem.companyId;

    // Requested Amount less Payments Amounts
    this.balancedAmount = data.advanceItem.totalAmount + data.advanceItem.advancePayments?.reduce((total, row) => total + (row.paymentEntry && row.paymentEntry.amount) || 0, 0 as number | number);

    const accountingInformation = data.advanceItem.counterparty.accountingInformation;
    const companyAccountingInformation = accountingInformation?.find((acc) => acc.companyId === this.companyId);
    const depositToAccount = companyAccountingInformation ? companyAccountingInformation.depositToAccount : null;

    this.form.patchValue({
      bank: data.advanceItem.bank,
      account: depositToAccount,
      requestedAmount: data.advanceItem.totalAmount,
      balancedAmount: this.balancedAmount,
      openItemsLines: data.openItems.map((openItem) => {
        const line: OpenItemsLineForm = {
          lineNumber: lineNumber++,
          entryType: openItem.entryType,
          entryTitle: openItem.entryTitle,
          balanceAmount: openItem.balanceAmount,
          amountToApply: openItem.balanceAmount,
          entryReference: openItem.entryReference,
          valueDate: openItem.valueDate,
          entryId: openItem.entryId,
          currencyId: openItem.currencyId,
          accountId: openItem.accountId,
          balanceBaseAmount: openItem.balanceBaseAmount,
          ourReference: openItem.ourReference,
        };
        return line;
      }),
    });

    this.accountDropdown = new DropdownConfig({
      listProcedure: endpoints.listBankAccounts,
      valueField: 'id',
      labelField: 'iden',
      additionalFilters: { ownerCode: this.companyId, depositaryCode: this.bankId, id: { not: 0 }, currKey: this.currencyId, invoiceBank: YN.Y, redAccount: YN.Y },
      take: 20,
    });
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): CreateAdvancePaymentForm {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    return this.form.value;
  }

  addFee() {
    let formGroup = new TypedFormGroup<feesForm>({
      budgetElement: new UntypedFormControl(null, Validators.required),
      amount: new UntypedFormControl(null, [Validators.required, Validators.min(0.01)]),
    });

    (<UntypedFormArray>this.form.controls.fees).push(formGroup);
  }

  removeFee(index: number) {
    const prompt = this.delegate.getService('prompt');
    const array = <TypedFormArray<feesForm>>this.form.controls.fees;

    prompt.deleteConfirmation('Remove Fee').subscribe((toDelete) => {
      if (toDelete) {
        array.removeAt(index);
      }
    });
  }

  getTotalFees() {
    this.totalFees = this.formatter.roundAmount(
      this.form.value.fees.reduce((total, row) => total + row.amount, 0 as number | number),
      this.currencyId
    );
  }

  getTotalAmount() {
    return (this.amount = this.formatter.roundAmount(this.form.value.amount, this.currencyId));
  }
}
