import { Component, ElementRef, NgZone } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ModalFormComponent, SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { ShipmentFinderComponent } from 'src/app/shared/shipment-finder/shipment-finder.component';
import { DropdownConfig, toMetricTons } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { budgetElementDropdown, contactDropdown, dollarFormat } from 'src/lib/commonTypes';
import { EntityContainer, SaveOptions } from 'src/lib/EntityContainer';
import { EnumLabels, Subset } from 'src/lib/generics';
import { conditionalValidators } from 'src/lib/genericValidators';
import { getTodayUTC, markFormGroupTouched, removeLineBreaks } from 'src/lib/helperFunctions';
import { BudgetElement, InvoiceHeader, PropertyDocument, ShipmentFinderResult, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { Service } from 'src/lib/newBackendTypes/service';
import { CreateServiceOrderRequest, expenseTypes, ServiceOrder, ServiceOrderLine, serviceOrderLineFromServiceOrder, UpdateServiceOrderRequest } from 'src/lib/newBackendTypes/serviceOrder';
import { TypedFormArray, TypedFormGroup } from 'src/lib/typedForms';
import { CommentsIcon, DocumentsIcon, ServiceOrderIcon, ShipmentIcon, TasksIcon } from 'src/lib/uiConstants';
import { ServiceOrderWithShipmentFinderResults } from '../../resolvers/service-order.resolver';
import { BaseInvoiceServiceOrderRequest, ServiceOrderInvoiceComponent } from '../service-order-invoice/service-order-invoice.component';
import { basicGraphqlCityDropdown } from 'src/lib/graphql/graphQlFunctions';

@UntilDestroy()
@Component({
  selector: 'core-service-order',
  templateUrl: './service-order.component.html',
  styleUrls: ['./service-order.component.scss'],
})
export class ServiceOrderComponent
  extends EntityContainer<ServiceOrder, CreateServiceOrderRequest[], UpdateServiceOrderRequest>
  implements ModalFormComponent<ServiceOrder | ServiceOrder[], Partial<ServiceOrderForm>>
{
  form: TypedFormGroup<ServiceOrderForm> = null;

  showSaveButtonsInPopup: boolean;
  requiredIfIsReadonly: (c: AbstractControl) => ValidationErrors;

  contactDropdown = contactDropdown();
  budgetElementConfig = budgetElementDropdown();
  cityConfig = basicGraphqlCityDropdown();
  serviceConfig = new DropdownConfig<Service>({
    valueField: 'id',
    labelField: 'name',
    listProcedure: endpoints.listServices,
    take: 20,
  });
  dollarFormat = dollarFormat();
  expenseTypes = expenseTypes;
  quantityFormat: NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
    useGrouping: true,
  };
  byPackingOptions: EnumLabels<YN> = [
    { value: YN.Y, label: 'Containers' },
    { value: YN.N, label: 'By Unit' },
  ];

  totalNetWeight = 0;
  // totalSupplierFinalWeight = 0
  // totalCustomerFinalWeight = 0
  totalOrderPrice = 0;
  totalCurrencyId: number;

  ServiceOrderIcon = ServiceOrderIcon;
  ShipmentIcon = ShipmentIcon;
  CommentsIcon = CommentsIcon;
  DocumentsIcon = DocumentsIcon;
  TasksIcon = TasksIcon;

  voucherEntityType = SourceEntityType.INVOICE_KEY;

  orderComment: string = '';

  constructor(route: ActivatedRoute, elementRef: ElementRef, zone: NgZone, public commonData: CommonDataService, private selectorService: SelectorPopupService, delegate: DelegateService) {
    super(
      {
        entityName: 'Service Order',
        idFields: ['id'],
        createProcedureId: endpoints.createServiceOrder,
        updateProcedureId: endpoints.updateServiceOrder,
        deleteProcedureId: endpoints.deleteServiceOrder,
        getProcedureId: endpoints.getServiceOrder,
        sourceEntityType: SourceEntityType.SERVICE_ORDER_KEY,
        labelField: 'id',
      },
      route,
      elementRef,
      zone,
      delegate
    );

    const nav = this.router.getCurrentNavigation();

    const state = nav ? nav.extras?.state : null;
    if (state && state['service-order-shipments']) {
      const shipments: ShipmentFinderResult[] = state['service-order-shipments'];

      this.prefill = { shipments };
    }
  }

  get voucherKey() {
    const firstHistory = this.entity?.serviceOrderHistories?.[0];
    return firstHistory?.voucherKey ?? 'N/A';
  }

  get voucherNumber() {
    const firstHistory = this.entity?.serviceOrderHistories?.[0];
    return firstHistory?.voucher?.number ?? 'N/A';
  }

  get isReadonly() {
    return this.entity?.completelyInvoiced === 'Y';
  }

  initializeForm() {
    this.requiredIfIsReadonly = conditionalValidators(() => !this.isReadonly, Validators.required);
    this.form = new TypedFormGroup<ServiceOrderForm>({
      id: new UntypedFormControl(),
      contact: new UntypedFormControl(null, this.requiredIfIsReadonly),
      origin: new UntypedFormControl(null),
      destination: new UntypedFormControl(null),
      orderReference: new UntypedFormControl(null, Validators.required),
      expenseType: new UntypedFormControl(null),
      orderDate: new UntypedFormControl(getTodayUTC()),
      orderComment: new UntypedFormControl('', Validators.maxLength(1000)),
      companyId: new UntypedFormControl(null),
      service: new UntypedFormControl(null),
      productId: new UntypedFormControl(null),
      containerTypeId: new UntypedFormControl(null),
      shipments: new UntypedFormControl([], Validators.required),
      comments: new UntypedFormControl(null),
      documents: new UntypedFormControl(null),
      lines: new UntypedFormArray([], Validators.required),
    });
    if (!!this.prefill) {
      this.form.patchValue(this.prefill);
      this.form.patchValue({ orderComment: this.orderComment });
    }

    this.addServiceOrderLine();
  }

  initializeFormListeners() {}

  addServiceOrderLine() {
    const fg: TypedFormGroup<ServiceOrderLineForm> = new TypedFormGroup<ServiceOrderLineForm>({
      budgetElement: new UntypedFormControl(null, this.requiredIfIsReadonly),
      byPacking: new UntypedFormControl(YN.Y, this.requiredIfIsReadonly),
      genericQuantity: new UntypedFormControl(null, this.requiredIfIsReadonly),
      orderPrice: new UntypedFormControl(null, this.requiredIfIsReadonly),
      orderUnitPrice: new UntypedFormControl(null, this.requiredIfIsReadonly),
      currencyId: new UntypedFormControl(null, this.requiredIfIsReadonly),
      originalQuantity: new UntypedFormControl(null),
    });

    fg.get('byPacking')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((val) => {
        if ((fg.get('genericQuantity').pristine || (fg.get('genericQuantity').value === fg.value.originalQuantity && fg.value.originalQuantity !== null)) && this.form.value.shipments?.length > 0) {
          const shipments = this.form.value!.shipments;

          if (val === YN.Y) {
            const containerAmount = new Set(shipments.map((s) => s.containerMarks)).size;
            fg.get('genericQuantity').setValue(containerAmount);
          } else if (val === YN.N) {
            const shipmentTotalWeight = shipments.reduce((p: number, c: ShipmentFinderResult) => {
              const unit = this.commonData.staticUnits.value.find((u) => u.unitId === c.unitId);
              if (!unit) return p;
              const net = toMetricTons(c.netWeight, unit);
              return p + net;
            }, 0);

            fg.get('genericQuantity').setValue(shipmentTotalWeight);
          }
          fg.get('genericQuantity').markAsPristine();
          fg.get('originalQuantity').setValue(fg.get('genericQuantity').value);
        } else {
          fg.get('originalQuantity').setValue(null);
        }

        const quantity: number | null = fg.get('genericQuantity').value;

        if (val === YN.Y && quantity) {
          if (quantity % 1 !== 0) {
            if (fg.value.originalQuantity !== null && fg.value.originalQuantity === quantity) {
              fg.get('originalQuantity').setValue(Math.floor(quantity));
            }
            fg.get('genericQuantity').setValue(Math.floor(quantity));
            fg.get('genericQuantity').markAsPristine();
          }
        }
      });

    (this.form.get('lines') as UntypedFormArray).push(fg);

    if (this.form.value.shipments?.length) this.getDataFromShipments(this.form.value.shipments).subscribe();

    return fg;
  }

  removeServiceOrder(i: number) {
    (this.form.get('lines') as UntypedFormArray).removeAt(i);
  }

  getCreateEntityRequest(options: SaveOptions<ServiceOrder, CreateServiceOrderRequest[]>) {
    const value = this.form.value;

    const shipments = (value.shipments || []).map((s) => {
      return { id: s.shipmentId };
    });

    const lineArray = this.form.get('lines') as TypedFormArray<ServiceOrderLineForm>;

    const requests: CreateServiceOrderRequest[] = lineArray.controls.map((c) => {
      const line: ServiceOrderLineForm = c.value;

      const request: CreateServiceOrderRequest = {
        counterpartyId: value.contact ? value.contact.id : null,
        budgetElementKey: line.budgetElement ? line.budgetElement.id : null,
        originId: value.origin ? value.origin.id : null,
        destinationId: value.destination ? value.destination.id : null,
        orderReference: value.orderReference,
        orderPrice: line.orderPrice,
        currencyId: line.currencyId,
        byPacking: line.byPacking,
        expenseType: !!value.expenseType && !options?.forceCreate ? value.expenseType : undefined,
        shipments,
      };
      if (value.orderDate) {
        request.orderDate = value.orderDate;
      }
      if (value.orderComment) {
        const orderComment = removeLineBreaks(value.orderComment);
        request.orderComment = orderComment;
      }
      if (line.byPacking === YN.Y) {
        request.numberOfPacking = line.genericQuantity;
      }
      if (line.byPacking === YN.N) {
        request.orderQuantity = line.genericQuantity;
      }
      if (value.productId) {
        request.productId = value.productId;
      }
      if (value.containerTypeId) {
        request.containerTypeId = value.containerTypeId;
      }
      if (value.service) {
        request.serviceKey = value.service.id;
      }

      return request;
    });

    return requests;
  }

  getUpdateEntityRequest() {
    const formVal = this.form.value;

    const firstLine = formVal.lines.length > 0 ? formVal.lines[0] : null;
    if (!firstLine) throw new Error('Form not initialized correctly');

    const shipments = (formVal.shipments || []).map((s) => {
      return { id: s.shipmentId };
    });

    const request: UpdateServiceOrderRequest = {
      id: formVal.id,
      shipments,
    };

    if (formVal.orderReference !== this.entity.orderReference) {
      request.orderReference = formVal.orderReference;
    }
    if (formVal.orderDate !== this.entity.orderDate) {
      request.orderDate = formVal.orderDate;
    }
    if (firstLine.orderPrice !== this.entity.orderPrice && this.entity.completelyInvoiced === YN.N) {
      request.orderPrice = firstLine.orderPrice;
    }
    if (firstLine.currencyId !== this.entity.currencyId) {
      request.currencyId = firstLine.currencyId;
    }
    if (firstLine.genericQuantity !== this.entity.orderQuantity && firstLine.byPacking === YN.N && this.entity.completelyInvoiced === YN.N) {
      request.orderQuantity = firstLine.genericQuantity;
    }
    if (firstLine.genericQuantity !== this.entity.numberOfPacking && firstLine.byPacking === YN.Y && this.entity.completelyInvoiced === YN.N) {
      request.numberOfPacking = firstLine.genericQuantity;
    }
    if (firstLine.byPacking !== this.entity.byPacking && this.entity.completelyInvoiced === YN.N) {
      request.byPacking = firstLine.byPacking;
    }
    if (formVal.expenseType !== this.entity.expenseType) {
      request.expenseType = formVal.expenseType;
    }
    if (formVal.orderComment !== this.entity.orderComment) {
      const orderComment = removeLineBreaks(formVal.orderComment);
      request.orderComment = orderComment;
    }
    if ((!!formVal.contact && formVal.contact.id !== this.entity.counterpartyId) || (formVal.contact === null && this.entity.counterpartyId !== null)) {
      request.counterpartyId = formVal.contact ? formVal.contact.id : null;
    }
    if ((!!firstLine.budgetElement && firstLine.budgetElement.id !== this.entity.budgetElementKey) || (firstLine.budgetElement === null && this.entity.budgetElementKey !== null)) {
      request.budgetElementKey = firstLine.budgetElement ? firstLine.budgetElement.id : null;
    }
    if ((!!formVal.origin && formVal.origin.id !== this.entity.originId) || (formVal.origin === null && this.entity.originId !== null)) {
      request.originId = formVal.origin ? formVal.origin.id : null;
    }
    if ((!!formVal.destination && formVal.destination.id !== this.entity.destinationId) || (formVal.destination === null && this.entity.destinationId !== null)) {
      request.destinationId = formVal.destination ? formVal.destination.id : null;
    }
    if (firstLine.currencyId !== this.entity.currencyId && this.entity.completelyInvoiced === YN.N) {
      request.currencyId = firstLine.currencyId;
    }
    if ((!!formVal.service && formVal.service.id !== this.entity.serviceKey) || (formVal.service === null && this.entity.serviceKey !== null)) {
      request.serviceKey = formVal.service ? formVal.service.id : null;
    }
    if (formVal.containerTypeId !== this.entity.containerTypeId) {
      request.containerTypeId = formVal.containerTypeId;
    }
    if (formVal.productId !== this.entity.productId) {
      request.productId = formVal.productId;
    }

    return request;
  }

  loadEntity(entity: ServiceOrderWithShipmentFinderResults) {
    const lineForm = this.addServiceOrderLine();
    const firstLine = serviceOrderLineFromServiceOrder(entity);
    this.form.patchValue({
      ...entity,
      lines: [
        {
          ...firstLine,
          genericQuantity: firstLine.byPacking === YN.Y ? firstLine.numberOfPacking : firstLine.orderQuantity,
        },
      ],
    });

    this.calculateUnitPrice(lineForm);
    if (this.form.value.shipments) {
      this.getDataFromShipments(this.form.value.shipments).subscribe();
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.calculatePriceTotals();
  }

  selectShipments() {
    if (!this.shipmentsAuthorized) return;
    this.selectorService
      .open(ShipmentFinderComponent, {
        title: 'Shipment Finder',
        preselectedItems: this.form.value.shipments,
      })
      .subscribe((shipments) => {
        if (shipments !== 'Close') {
          this.form.patchValue({ shipments });
          this.getDataFromShipments(shipments).subscribe();
        }
      });
  }

  getDataFromShipments(shipments: ShipmentFinderResult[]): Observable<any> {
    const shipmentFinderResult = shipments.length > 0 ? shipments[0] : null;

    this.totalNetWeight = 0;
    // this.totalCustomerFinalWeight = 0;
    // this.totalSupplierFinalWeight = 0;
    if (!shipmentFinderResult) return of(null);

    const shipmentTotalWeight = shipments.reduce((p: number, c: ShipmentFinderResult) => {
      const unit = this.commonData.staticUnits.value.find((u) => u.unitId === c.unitId);
      if (!unit) return p;
      const net = toMetricTons(c.netWeight || 0, unit);
      return p + net;
    }, 0);
    // let totalCustomerFinalWeight = shipments.reduce((p: number, c: ShipmentFinderResult) => {
    //     let unit = this.commonData.staticUnits.value.find(u => u.unitId === c.unitId)
    //     if(!unit) return p
    //     let net = toMetricTons(c.customerFinalWeight || 0, unit)
    //     return p + net
    // }, 0)

    // let totalSupplierFinalWeight = shipments.reduce((p: number, c: ShipmentFinderResult) => {
    //     let unit = this.commonData.staticUnits.value.find(u => u.unitId === c.unitId)
    //     if(!unit) return p
    //     let net = toMetricTons(c.supplierFinalWeight || 0, unit)
    //     return p + net
    // }, 0)
    this.totalNetWeight = shipmentTotalWeight;
    // this.totalCustomerFinalWeight = totalCustomerFinalWeight
    // this.totalSupplierFinalWeight = totalSupplierFinalWeight
    if (!!this.entity) return of(null);

    const productControl = this.form.get('productId');
    const containerTypeControl = this.form.get('containerTypeId');
    const originControl = this.form.get('origin');
    const destinationControl = this.form.get('destination');

    const untouchedArr = [productControl, containerTypeControl, originControl, destinationControl].map((c) => c.untouched);
    if (untouchedArr.every((untouched) => !untouched)) return of(null);

    const rid = this.spinnerService.startRequest('Getting data from Shipment(s)');
    const obsv = shipmentFinderResult.shipment ? of(shipmentFinderResult.shipment) : this.api.rpc<PropertyDocument>(endpoints.getShipment, { filters: { id: shipmentFinderResult.shipmentId } }, null);
    return obsv.pipe(
      tap((shipment) => {
        this.spinnerService.completeRequest(rid);

        if (productControl.untouched) productControl.setValue(shipment.productId);
        if (containerTypeControl.untouched) containerTypeControl.setValue(shipment.container?.containerTypeId || null);
        if (originControl.untouched) originControl.setValue(shipment.origin || null);
        if (destinationControl.untouched) destinationControl.setValue(shipment.destination || null);

        for (const line of (this.form.get('lines') as TypedFormArray<ServiceOrderLineForm>).controls) {
          const quantityControl = line['controls'].genericQuantity;
          const quantityTypeControl = line['controls'].byPacking;
          const prevalue = quantityControl.value;
          if (quantityControl.untouched) {
            if (quantityTypeControl.value === YN.Y) {
              const containerAmount = new Set(shipments.map((s) => s.containerMarks)).size;
              quantityControl.setValue(containerAmount);
            } else if (quantityTypeControl.value === YN.N) {
              quantityControl.setValue(shipmentTotalWeight);
            }
            if (prevalue !== quantityControl.value) line.patchValue({ originalQuantity: quantityControl.value });
          }
        }
      })
    );
  }

  onQuantityBlur(form: TypedFormGroup<ServiceOrderLineForm>) {
    if (form.value.orderPrice && !form.value.orderUnitPrice) {
      this.calculateUnitPrice(form);
    } else {
      this.calculateOrderPrice(form);
    }
    this.calculatePriceTotals();
  }

  onUnitPriceBlur(form: TypedFormGroup<ServiceOrderLineForm>) {
    this.calculateOrderPrice(form);
    this.calculatePriceTotals();
  }

  onOrderPriceBlur(form: TypedFormGroup<ServiceOrderLineForm>) {
    this.calculateUnitPrice(form);
    this.calculatePriceTotals();
  }

  calculateOrderPrice(form: TypedFormGroup<ServiceOrderLineForm>) {
    const packingValue = form.value.genericQuantity;
    if (packingValue === null) return;

    const orderUnitPrice = form.value.orderUnitPrice;
    if (!orderUnitPrice) return;

    const orderPrice = packingValue * orderUnitPrice;
    form.patchValue({ orderPrice });
  }

  calculateUnitPrice(form: TypedFormGroup<ServiceOrderLineForm>) {
    const packingValue = form.value.genericQuantity;
    if (packingValue === null) return;

    const orderPrice = form.value.orderPrice;
    if (!orderPrice) return;

    const orderUnitPrice = orderPrice / packingValue;
    form.patchValue({ orderUnitPrice });
  }

  numberOfPackingValidator(form: TypedFormGroup<ServiceOrderLineForm>) {
    return (control: AbstractControl) => {
      if (!form) return null;
      const byPacking = form.value.byPacking;
      if (!byPacking || byPacking === YN.N) return null;
      return Validators.required(control);
    };
  }

  orderQuantityValidator(form: TypedFormGroup<ServiceOrderLineForm>) {
    return (control: AbstractControl) => {
      if (!form) return null;
      const byPacking = form.value.byPacking;
      if (!byPacking || byPacking === YN.Y) return null;
      return Validators.required(control);
    };
  }

  calculatePriceTotals() {
    this.totalOrderPrice = 0;
    const currencyIds: number[] = [];

    for (const l of this.form.value.lines) {
      this.totalOrderPrice += l.orderPrice ?? 0;
      if (l.currencyId) {
        currencyIds.push(l.currencyId);
      }
    }

    const set = new Set(currencyIds);
    if (set.size !== 1) {
      this.totalCurrencyId = null;
    } else {
      this.totalCurrencyId = currencyIds[0] ?? null;
    }
  }

  clickSaveAndInvoice() {
    markFormGroupTouched(this.form);
    if (this.form.invalid || this.form.pending) return;
    let allPositive: boolean = false;
    let allNegative: boolean = false;
    if (this.form.value.lines) {
      allPositive = this.form.value.lines.every((so) => so.orderPrice >= 0);
      allNegative = this.form.value.lines.every((so) => so.orderPrice < 0);
    }
    if (!allPositive && !allNegative) {
      this.dialogService.open({
        title: 'Unable to Invoice',
        content: 'Service Order lines must all be positive or all be negative to be invoiced together.',
      });
      return;
    }
    this.getInvoiceRequest().subscribe((req) => {
      if (!req) return;
      this.clickSave({ callback: this.invoiceServiceOrder(req) });
    });
  }

  getInvoiceRequest(): Observable<BaseInvoiceServiceOrderRequest> {
    const serviceOrders: (ServiceOrderForm & ServiceOrderLineForm)[] = [];
    let allLinesZero = true;
    if (this.form.value.lines) {
      this.form.value.lines.forEach((so) => {
        if (!!so && so.budgetElement && so.orderPrice !== null && so.orderUnitPrice !== null) {
          allLinesZero = allLinesZero && so.orderPrice === 0;
          serviceOrders.push({ ...this.form.value, ...so });
        }
      });
    }
    if (allLinesZero) {
      return this.promptService
        .simpleConfirmation('Invoice Service Order', `Service Order will be invoiced.  Continue?`, {
          confirmText: 'Invoice',
        })
        .pipe(map((response) => (response ? {} : null)));
    }
    const refs = serviceOrders.flatMap((so) => so.orderReference ?? []);
    const serviceOrderIds = serviceOrders.map((so) => so.id).join(', ');
    return this.selectorService
      .openForm<BaseInvoiceServiceOrderRequest, ServiceOrderInvoiceComponent>(ServiceOrderInvoiceComponent, {
        title: 'Invoice Service Order',
        initializer: (c) => {
          c.serviceOrders = serviceOrders;
          c.expenseType = this.form.value.expenseType;
          c.serviceOrderIds = serviceOrderIds;
        },
        prefillValue: {
          invoiceDate: getTodayUTC(),
          dueDate: getTodayUTC(),
          yourReference: refs[0] ?? '',
        },
      })
      .pipe(
        map((res) => {
          if (res === 'Close') return null;
          return res;
        })
      );
  }

  invoiceServiceOrder(request: BaseInvoiceServiceOrderRequest) {
    return (so: ServiceOrder[] | ServiceOrder) =>
      from(
        (async () => {
          if (!!so) {
            const rid = this.spinnerService.startRequest('Invoicing');
            const ids = Array.isArray(so) ? (so as ServiceOrder[]).map((so) => so.id) : [(so as ServiceOrder).id];
            await this.api.run<{ voucher: InvoiceHeader; serviceOrders: ServiceOrder[] }>(endpoints.invoiceServiceOrder, { ...request, id: ids }, null);

            this.spinnerService.completeRequest(rid);
            this.notificationService.show('Service Order Invoiced', 'success');
          }

          this.goToEntity(so);
          return so;
        })()
      );
  }

  async reverseVoucher() {
    markFormGroupTouched(this.form);
    if (this.form.invalid || this.form.pending) return;
    if (
      !(await lastValueFrom(
        this.promptService
          .simpleConfirmation('Reverse Service Order Invoicing', `Service Order Invoice will be reversed. Continue?`, { confirmText: 'Reverse' })
          .pipe(map((response) => (response ? true : false)))
      ))
    )
      return;

    this.clickSave({
      callback: (so: ServiceOrder[] | ServiceOrder) => {
        return from(
          (async () => {
            if (!!so) {
              const rid = this.spinnerService.startRequest('Reversing Voucher');
              const serviceOrders = Array.isArray(so) ? so : [so];
              for (const serviceOrder of serviceOrders) {
                await this.api.run<{ reversedVoucherNumber: string | null }>(endpoints.uninvoiceServiceOrder, { id: serviceOrder.id }, null);
              }
              this.spinnerService.completeRequest(rid);
              this.notificationService.show('Service Order Invoice Reversed', 'success');
            }
            this.goToEntity(so);
            return so;
          })()
        );
      },
    });
  }

  get invoiceable(): boolean {
    return this.authorized[endpoints.invoiceServiceOrder] && this.authorized[endpoints.listPaymentTerms] && (!this.entity || this.entity.completelyInvoiced === YN.N);
  }

  get reverseInvoiceAuthorized(): boolean {
    return this.authorized[endpoints.uninvoiceServiceOrder];
  }

  get shipmentsAuthorized(): boolean {
    return this.authorized[endpoints.shipmentLookup];
  }

  get viewVoucherAuthorized(): boolean {
    return (
      this.authorized[endpoints.getVoucher] &&
      this.authorized[endpoints.getFiscalCompany] &&
      this.authorized[endpoints.listVouchers] &&
      this.authorized[endpoints.listBankAccounts] &&
      this.authorized[endpoints.listGlAccounts]
    );
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.requestsPending.length === 0 && !this.form.pending && !this.form.invalid;
  }
  prefill: Partial<ServiceOrderForm> = null;

  clearForm() {
    super.clearForm();
    // this.totalCustomerFinalWeight = 0
    // this.totalSupplierFinalWeight = 0
    this.totalNetWeight = 0;
  }

  async clickSave(options: SaveOptions<ServiceOrder, CreateServiceOrderRequest[] | UpdateServiceOrderRequest>) {
    let obsv: Observable<boolean>;

    const restrictedBudgetElements: BudgetElement[] = this.form.value.lines.flatMap((l) => (l.budgetElement?.thirdPartyAllowed === YN.N ? l.budgetElement : []));

    const usingRestrictedBudgetElementForThirdParty =
      restrictedBudgetElements.length > 0 &&
      this.form.value.shipments &&
      this.form.value.contact &&
      !this.form.value.shipments.some((s) => s.saleCounterpartyId === this.form.value.contact.id) &&
      !this.form.value.shipments.some((s) => s.purchaseCounterpartyId === this.form.value.contact.id);

    obsv = usingRestrictedBudgetElementForThirdParty
      ? this.promptService.simpleConfirmation(
          'Warning: Non-third-party budget element',
          `The selected counterparty is a third party.  The following budget elements are not for use with third parties.
s
        ${Array.from(new Set(restrictedBudgetElements.map((be) => be.name))).join(',\n')}

        Are you sure you wish to continue?`,
          { confirmText: 'Save' }
        )
      : of(true);

    obsv.subscribe((toSave) => {
      if (toSave) {
        if (this.popup) {
          super.clickSave({
            ...(options || {}),
            blockRedirect: true,
            reloadInPlace: (e: ServiceOrder | ServiceOrder[]) => {
              if (!e) return null;
              const entity = Array.isArray(e) ? e[0] : e;
              return {
                ...entity,
                shipments: this.form.value.shipments,
              };
            },
          });
        } else {
          super.clickSave({
            ...options,
            blockRedirect: true,
            blockNotification: true,
            callback: options?.callback
              ? options.callback
              : (e: ServiceOrder | ServiceOrder[]) => {
                  if (!!e) {
                    this.goToEntity(e);
                  }
                  return of(e);
                },
          });
        }
      }
    });
  }

  goToEntity(e: ServiceOrder[] | ServiceOrder) {
    const entity = Array.isArray(e) ? e[0] : e;
    this.router.navigate(['portal/core/service-orders/' + entity.id]);
  }

  get linesFormArray() {
    return this.form.get('lines') as TypedFormArray<ServiceOrderLineForm>;
  }
}

export type ServiceOrderForm = Subset<
  ServiceOrder,
  'id' | 'contact' | 'origin' | 'destination' | 'orderReference',
  'expenseType' | 'orderDate' | 'orderComment' | 'companyId' | 'service' | 'productId' | 'containerTypeId'
> & {
  shipments?: ShipmentFinderResult[];
  comments?: Comment[];
  documents?: Document;

  lines: ServiceOrderLineForm[];
};

export type ServiceOrderLineForm = Pick<ServiceOrderLine, 'budgetElement' | 'byPacking' | 'currencyId' | 'orderPrice' | 'orderUnitPrice'> & {
  genericQuantity: number;
  originalQuantity?: number;
};
