<div class="card desktop-h-100 w-100">
  <div class="card-body" style="display: flex; flex-direction: column">
    <form class="row form-group" [formGroup]="filterForm">
      <vertical-field name="Company" class="col-lg-2 col-md-3 col-sm-3 col-6">
        <dropdown-wrapper [formControlName]="'company'" [dropdownConfig]="companyDropdown"></dropdown-wrapper>
      </vertical-field>
      <vertical-field class="col-xl-1 col-lg-2 col-md-3 col-sm-6 col-12">
        <button type="submit" kendoButton (click)="fetchShipments()">Search</button>
      </vertical-field>
    </form>
  </div>
  <ag-grid-angular
    class="ag-grid-container ag-theme-balham"
    [rowData]="shipmentsData"
    [gridOptions]="gridOptions"
    (cellValueChanged)="onCellValueChanged($event)"
    (cellEditingStopped)="onCellEditingStopped($event)"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</div>
