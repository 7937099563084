import { from, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { Booking, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib/ListResponse';
import { simpleConfirmation } from '../flexDynamicForm';

const markBookingAsPaidPrefillCallback: prefillCallback<MarkBookingAsPaidPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const bookingIds = Array.isArray(id) ? id : [id];
      const bookings = await api.run<ListResponse<Booking>>(endpoints.listBookings, { filters: { id: bookingIds } });
      if (!(bookings || bookings.list)) return 'Unable to Mark Booking as Paid: Booking(s) not found.';

      return { bookings: bookings.list };
    })()
  );
};

const markBookingAsPaidPrefillCheck: checkPrefillCallback<MarkBookingAsPaidPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  return of(true);
};

export const confirmMarkBookingAsPaid: openFormCallback<MarkBookingAsPaidPrefill> = (delegate, id, prefill, column) => {
  const title = `Mark Booking(s) As Paid`;
  const content = `Do you wish to mark as Paid? 
  BK #: ${prefill.bookings ? prefill.bookings.map((item) => `${item.number}`).join(', ') : 'Unknown'}`;

  return simpleConfirmation<MarkBookingAsPaidPrefill>(title, content)(delegate, id, prefill, column);
};

export const markBookingAsPaidCallback: submitFormCallback<MarkBookingAsPaidPrefill> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<Booking[]>((resolve, reject) => {
      (async () => {
        const request: MarkBookingAsPaidRequest = {
          bookingIds: prefill.bookings.map((b) => b.id),
          markAsPaid: YN.Y,
        };

        const bookings = await api.run<Booking[]>(endpoints.markBookingAsPaid, request, null);

        if (bookings) return resolve(bookings);
        return reject('Unknown result. Please check if the Bookings were marked as paid and try again if necessary.');
      })();
    })
      .then((res) => {
        if (res) {
          const response = res.filter((item) => item !== null);
          if (response) {
            return prompt.htmlDialog('Success', `<div style="white-space: pre">Booking(s) successfully marked as paid: \n${response.map((bk) => (bk ? `- ${bk.number}` : null)).join(`\n`)}</div>`);
          }
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const MarkBookingAsPaidPreset: DynamicFormConstant<MarkBookingAsPaidPrefill> = {
  allowMultipleRows: true,
  value: DynamicFormType.MARK_BOOKING_AS_PAID,
  label: 'Mark Booking As Paid',
  title: 'Mark Booking As Paid',
  endpoints: [endpoints.getBooking, endpoints.listShipments, endpoints.markBookingAsPaid],
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  getPrefill: markBookingAsPaidPrefillCallback,
  checkPrefill: markBookingAsPaidPrefillCheck,
  openForm: confirmMarkBookingAsPaid,
  submitForm: markBookingAsPaidCallback,
  width: 500,
};

export type MarkBookingAsPaidPrefill = {
  bookings: Booking[];
};

type MarkBookingAsPaidRequest = {
  bookingIds: number[];
  markAsPaid: YN;
};
