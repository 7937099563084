import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { BankAccount, BankAccountBalance, SourceEntityType } from 'src/lib/newBackendTypes';
import { createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import { from } from 'rxjs';
import { FormElementConfig, _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { Validators } from '@angular/forms';
import _ from 'lodash';
import { ListResponse } from 'src/lib/ListResponse';

export const updateBankAccountBalancePrefill: prefillCallback<BankAccount> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const singleId = Array.isArray(id) ? id.shift() : id;
      const accounts = await api.run<ListResponse<BankAccount>>(endpoints.listActiveBankAccounts, { filters: { accountAccKey: singleId } }, null);

      return accounts.list[0];
    })()
  );
};

const updateBankAccountBalanceForm: createFormCallback<BankAccount, updateBankAccountBalanceForm> = (delegate, id, prefill) => {
  const form: FormElementConfig<updateBankAccountBalanceForm>[] = [
    { type: 'Label', text: `Account: ${prefill && prefill.iden ? prefill.iden : 'Unknown'}`, style: { 'margin-bottom': '5px' } },
    _fe('balance', 'Balance', { minimumFractionDigits: 0, maximumFractionDigits: 4, useGrouping: false, fieldType: 2 }, null, Validators.required),
  ];

  return form;
};

const updateBankAccountBalanceSubmit: submitFormCallback<BankAccount, updateBankAccountBalanceForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<BankAccountBalance>((resolve, reject) => {
      (async () => {
        const request: updateBankAccountBalanceRequest = {
          accountId: prefill.id,
          balance: result.balance,
        };

        try {
          const updateBankAccountBalance = await api.run<BankAccountBalance>(endpoints.updateBankAccountBalance, request, null);
          if (request.accountId && request.balance) return resolve(updateBankAccountBalance);
        } catch (err) {
          return reject(err);
        }
      })();
    })
      .then((res) => {
        if (res.accountId) return prompt.htmlDialog('Success', `<div style="white-space: pre">Bank Account Balance successfully updated</div>`);
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const updateBankAccountBalancePreset: DynamicFormConstant<BankAccount, updateBankAccountBalanceForm> = {
  allowMultipleRows: true,
  value: DynamicFormType.UPDATE_BANK_ACCOUNT_BALANCE,
  label: 'Update Bank Account Balance',
  title: 'Update Bank Account Balance',
  endpoints: [endpoints.updateBankAccountBalance, endpoints.listActiveBankAccounts],
  entityType: SourceEntityType.BANK_ACCOUNT_KEY,
  width: 400,
  getPrefill: updateBankAccountBalancePrefill,
  createForm: updateBankAccountBalanceForm,
  submitForm: updateBankAccountBalanceSubmit,
};

export type updateBankAccountBalanceForm = Pick<BankAccount, 'id'> & { balance: number };
export type updateBankAccountBalanceRequest = { accountId: number; balance: number };
