import { map } from 'rxjs/operators';
import { applyFixationToInvoicePreset } from './forms/applyFixationToInvoice';
import { applyItemsToVoucherPreset } from './forms/applyItemsToVoucher';
import { archiveContractPreset } from './forms/archiveUnarchiveContract';
import { archiveUnarchiveItemPreset } from './forms/archiveUnarchiveItem';
import { cancelContractPreset } from './forms/cancelContract';
import { contractLineRolloverPreset } from './forms/contractLineRollOver';
import { createAdvancePaymentPreset } from './forms/createAdvancePayment';
import { createBookingPayOrderPreset } from './forms/createBookingPayOrder';
import { clientInvoicePreset } from './forms/createClientInvoice';
import { createFutureMatchingPreset } from './forms/createFutureMatching';
import { createMatchingPaymentPreset } from './forms/createMatchingPayment';
import { createPayOrderPreset } from './forms/createPaymentOrder';
import { createPriceFixationPreset } from './forms/createPriceFixation';
import { createProposalPaymentPreset } from './forms/createProposalPayment';
import { createSurchargeTypePreset } from './forms/createSurchargeType';
import { deleteAdvancePreset } from './forms/deleteAdvance';
import { deletePremiumFixationPreset } from './forms/deletePremiumFixation';
import { deletePriceFixationPreset } from './forms/deletePriceFixation';
import { finishContractLinePreset } from './forms/finishContractLine';
import { generateDraftInvoicePreset } from './forms/generateDraftInvoice';
import { invoiceSearchPaymentsPreset } from './forms/invoiceSearchPayments';
import { invoiceServiceOrderPreset } from './forms/invoiceServiceOrder';
import { manageQPPreset } from './forms/manageQP';
import { markAdvancePreset } from './forms/markAdvance';
import { markForTrackingPreset } from './forms/markBookingForTracking';
import { modifyPremiumFixationPreset } from './forms/modifyPremiumFixation';
import { netPositionEntryUpdatePreset } from './forms/netPositionEntryUpdate';
import { printAdvancePreset } from './forms/printAdvance';
import { printDocumentPreset } from './forms/printDocument';
import { reconcileJournalEntriesPreset } from './forms/reconcileJournalEntries';
import { reconcilePaymentPreset } from './forms/reconcilePayment';
import { reverseFutureMatchingPreset } from './forms/reverseFutureMatching';
import { reverseJournalPreset } from './forms/reverseJournal';
import { reverseVoucherPreset } from './forms/reverseVoucher';
import { setInvoiceSentDatePreset } from './forms/setInvoiceSentDate';
import { setIVADeclarationDatePreset } from './forms/setIVADeclarationDate';
import { setSalePricePreset } from './forms/setSalePrice';
import { shipmentFixationSummaryPreset } from './forms/shipmentFixationSummary';
import { bankingTrackingPreset, carrierTrackingPreset, customerTrackingPreset } from './forms/trackBooking';
import { DynamicFormConstant, openFormCallback } from './forms/types';
import { unapplyFixationFromInvoicePreset } from './forms/unapplyFixationFromInvoice';
import { unbookingPayOrderPreset } from './forms/unbookingPayOrder';
import { unreconcileJournalEntriesPreset } from './forms/unreconcileJournalEntries';
import { updateAdvancePreset } from './forms/updateAdvance';
import { deleteFinanceReleasePreset, financeReleasePreset, financeReleaseTodayPreset } from './forms/updateBooking';
import { updateBookingCobPreset } from './forms/updateBookingCob';
import { updateBookingStatusPreset } from './forms/updateBookingStatus';
import { updateSTBPreset } from './forms/updateBookingStbStatus';
import { updateBookingTrackingDatesPreset } from './forms/updateBookingTrackingDates';
import { editLineExecPreset } from './forms/updateContractLine';
import { editContractsExecPreset } from './forms/updateContractTerms';
import { updateItemPreset } from './forms/updateItem';
import { updatePaymentTermPreset } from './forms/updatePaymentTerm';
import { updatePriceFixationPreset } from './forms/updatePriceFixation';
import { updateRefReceivedPreset } from './forms/updateRefReceived';
import { updateShipmentValueDatePreset } from './forms/updateShipmentValueDate';
import { updateVoucherTitleAndRefsPreset } from './forms/updateVoucherTitleAndRefs';
import { archiveUnarchiveGlAccountPreset } from './forms/archiveUnarchiveGlAccount';
import { inventoryWriteOffPreset } from './forms/inventoryWriteOff';
import { printPresentationPreset } from './forms/printPresentation';
import { deleteServiceOrderPreset } from './forms/deleteServiceOrder';
import { reverseInventoryWriteOffPreset } from './forms/reverseInventoryWriteOff';
import { deleteAppointmentReferencePreset } from './forms/deleteAppointmentReference';
import { createAppointmentReferencePreset } from './forms/createAppointmentReference';
import { updateItemMetalUnitPercentagePreset } from './forms/updateItemMetalUnitPercentage';
import { archiveUnarchiveDiscrepancyPreset } from './forms/archiveUnarchiveDiscrepancy';
import { linkUnlinkDiscrepanciesPreset } from './forms/linkUnlinkDiscrepancies';
import { linkUnlinkAdvancePaymentPreset } from './forms/linkUnlinkAdvancePayment';
import { updateVoucherPaymentTermPreset } from './forms/updateVoucherPaymentTerm';
import { updateNetPositionJournalPreset } from './forms/updateNetPositionJournal';
import { updateNetPositionJournalDatesPreset } from './forms/updateNetPositionJournalDates';
import { duplicateLinePreset } from './forms/duplicateContractLine';
import { bookingPreAdvisoryPreset } from './forms/bookingPreAdvisory';
import { bankReconcileJournalEntriesPreset } from './forms/bankReconcileJournalEntries';
import { updateVoucherDueDatePreset } from './forms/updateVoucherDueDate';
import { createJournalBankReconciliationPreset } from './forms/createJournalBankReconciliation';
import { MarkBookingAsPaidPreset } from './forms/markBookingAsPaid';
import { updateBankAccountBalancePreset } from './forms/updateBankAccountBalance';

export function simpleConfirmation<T>(
  title: string,
  content?: string,
  options?: {
    confirmText?: string;
    initializer?: (c: void) => void;
    width?: string | number;
    height?: string | number;
    cancelText?: string;
  }
): openFormCallback<T> {
  return (delegate, id, prefill: T) => {
    const prompt = delegate.getService('prompt');
    return prompt.simpleConfirmation(title, content, options).pipe(map((v) => (v ? prefill : 'Close')));
  };
}

export function confirmationForm<T>(title: string, body: string | ((prefill: Partial<T>) => string), confirmText?: string, cancelText?: string): openFormCallback<T, boolean> {
  return (delegate, id, prefill) => {
    const prompt = delegate.getService('prompt');

    let content = typeof body === 'string' ? body : body(prefill);

    return prompt.simpleConfirmation(title, content).pipe(
      map((result) => {
        return result || 'Close';
      })
    );
  };
}

export const DynamicFormPresets: DynamicFormConstant<any>[] = [
  updateBookingStatusPreset,
  financeReleasePreset,
  financeReleaseTodayPreset,
  carrierTrackingPreset,
  invoiceServiceOrderPreset,
  updatePaymentTermPreset,
  archiveContractPreset,
  updateSTBPreset,
  cancelContractPreset,
  editContractsExecPreset,
  editLineExecPreset,
  duplicateLinePreset,
  contractLineRolloverPreset,
  markForTrackingPreset,
  printAdvancePreset,
  updateAdvancePreset,
  clientInvoicePreset,
  markAdvancePreset,
  deleteAdvancePreset,
  updateRefReceivedPreset,
  setSalePricePreset,
  generateDraftInvoicePreset,
  createAdvancePaymentPreset,
  linkUnlinkAdvancePaymentPreset,
  setInvoiceSentDatePreset,
  modifyPremiumFixationPreset,
  deletePremiumFixationPreset,
  createPayOrderPreset,
  createMatchingPaymentPreset,
  createBookingPayOrderPreset,
  reverseVoucherPreset,
  reconcileJournalEntriesPreset,
  unreconcileJournalEntriesPreset,
  unbookingPayOrderPreset,
  printDocumentPreset,
  applyItemsToVoucherPreset,
  createProposalPaymentPreset,
  reverseJournalPreset,
  createPriceFixationPreset,
  updatePriceFixationPreset,
  deletePriceFixationPreset,
  createFutureMatchingPreset,
  reverseFutureMatchingPreset,
  updateShipmentValueDatePreset,
  finishContractLinePreset,
  reconcilePaymentPreset,
  applyFixationToInvoicePreset,
  unapplyFixationFromInvoicePreset,
  invoiceSearchPaymentsPreset,
  deleteFinanceReleasePreset,
  updateVoucherTitleAndRefsPreset,
  updateBookingCobPreset,
  updateBookingTrackingDatesPreset,
  shipmentFixationSummaryPreset,
  bankingTrackingPreset,
  customerTrackingPreset,
  manageQPPreset,
  archiveUnarchiveItemPreset,
  updateItemPreset,
  setIVADeclarationDatePreset,
  createSurchargeTypePreset,
  netPositionEntryUpdatePreset,
  archiveUnarchiveGlAccountPreset,
  inventoryWriteOffPreset,
  reverseInventoryWriteOffPreset,
  printPresentationPreset,
  deleteServiceOrderPreset,
  deleteAppointmentReferencePreset,
  createAppointmentReferencePreset,
  updateItemMetalUnitPercentagePreset,
  archiveUnarchiveDiscrepancyPreset,
  linkUnlinkDiscrepanciesPreset,
  updateVoucherPaymentTermPreset,
  updateNetPositionJournalPreset,
  updateNetPositionJournalDatesPreset,
  bookingPreAdvisoryPreset,
  bankReconcileJournalEntriesPreset,
  updateVoucherDueDatePreset,
  createJournalBankReconciliationPreset,
  MarkBookingAsPaidPreset,
  updateBankAccountBalancePreset,
];
