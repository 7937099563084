import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridReadyEvent,
  NewValueParams,
  StatusPanelDef,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { round } from 'lodash';
import Mexp from 'math-expression-evaluator';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { Store } from 'src/app/core/services/store.service';
import { AutoCompleteEditor } from 'src/app/shared/aggrid/autocompleteeditor/AutoCompleteEditor';
import { TableForm } from 'src/app/shared/aggrid/TableForm';
import { ValidationStatusPanel } from 'src/app/shared/aggrid/validationstatuspanel/ValidationStatusPanel';
import { DropdownConfig } from 'src/lib';
import { getContextMenuItems, quantityColumn } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { CommonSteelTypeGroups, ContractLine, Item, LinePriceType, MetalControlGroup, ReclassifyLinesData, YN } from 'src/lib/newBackendTypes';

@UntilDestroy()
@Component({
  selector: 'logistics-reclassify-shipment-list',
  templateUrl: './reclassify-shipment-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReclassifyShipmentsListComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => ReclassifyShipmentsListComponent),
    },
  ],
})
export class ReclassifyShipmentsListComponent extends TableForm<ReclassifyShipmentItemLinesForm> {
  /**
   * Flags status of the grid
   */
  private _readonly: boolean;
  private _shipmentUnitId: number;
  private _contractId: number;
  private _priceType: LinePriceType;
  private _price: number;
  private _contractLineId: number;
  private _productId: number;
  private _remainingGrossTotal: number;
  private _remainingNetTotal: number;
  private _remainingPackingTotal: number;

  @Input()
  set readonly(val: boolean) {
    this._readonly = val;
    if (this.gridApi) {
      const statusPanel: ValidationStatusPanel | undefined = this.gridApi.getStatusPanel('validationStatusPanel') as ValidationStatusPanel;
      if (!!statusPanel) {
        statusPanel.setReadonly(val);
      }
    }
    this.refreshDefinitions();
  }

  get readonly() {
    return this._readonly;
  }

  @Input()
  set shipmentUnitId(shipmentUnitId: number) {
    this._shipmentUnitId = shipmentUnitId;
  }

  get shipmentUnitId() {
    return this._shipmentUnitId;
  }

  @Input()
  set contractId(contractId: number) {
    this._contractId = contractId;
  }

  get contractId() {
    return this._contractId;
  }

  @Input()
  set priceType(priceType: LinePriceType) {
    this._priceType = priceType;
  }

  get priceType() {
    return this._priceType;
  }

  @Input()
  set price(price: number) {
    this._price = price;
  }

  get price() {
    return this._price;
  }

  @Input()
  set contractLineId(contractLineId: number) {
    this._contractLineId = contractLineId;
  }

  get contractLineId() {
    return this._contractLineId;
  }

  @Input()
  set productId(productId: number) {
    this._productId = productId;
  }

  get productId() {
    return this._productId;
  }

  @Input()
  set remainingGrossTotal(remainingGrossTotal: number) {
    this._remainingGrossTotal = remainingGrossTotal;
  }

  get remainingGrossTotal() {
    return this._remainingGrossTotal;
  }

  @Input()
  set remainingNetTotal(remainingNetTotal: number) {
    this._remainingNetTotal = remainingNetTotal;
  }

  get remainingNetTotal() {
    return this._remainingNetTotal;
  }

  @Input()
  set remainingPackingTotal(remainingPackingTotal: number) {
    this._remainingPackingTotal = remainingPackingTotal;
  }

  get remainingPackingTotal() {
    return this._remainingPackingTotal;
  }

  /**
   * Any possible error messages or null if the grid is valid
   */
  errors: string[] = [];
  remainingGrossQty: number = 0;
  reassignedGrossQty: number = 0;

  remainingNetQty: number = 0;
  reassignedNetQty: number = 0;

  remainingPackingQty: number = 0;
  reassignedPackingQty: number = 0;

  constructor(store: Store, private formatter: DataFormattingService, public delegate: DelegateService, private commonData: CommonDataService) {
    super();
    const body = document.querySelector('body');
    const statusPanels: StatusPanelDef[] = [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: ValidationStatusPanel,
        align: 'right',
        key: 'validationStatusPanel',
        statusPanelParams: {
          errors: this.errors,
          readonly: this.readonly,
        },
      },
    ];

    this.gridOptions = {
      getRowId: (params) => params.data.linePosition,
      domLayout: 'normal',
      popupParent: body,
      suppressAggFuncInHeader: true,
      animateRows: false,
      getRowStyle: (params) => (params?.node?.group ? { 'font-weight': 'bold' } : {}),
      groupIncludeFooter: true,
      groupDefaultExpanded: 1,
      groupSuppressAutoColumn: false,
      groupUseEntireRow: false,
      defaultColDef: {
        resizable: true,
        sortable: true,
        width: 135,
      },
      stopEditingWhenCellsLoseFocus: true,
      columnDefs: this.getColumnDefinitions(),
      rowSelection: 'single',
      suppressRowClickSelection: true,
      getContextMenuItems: getContextMenuItems('separator', this.addLineOption(), this.deleteLineOption()),
      statusBar: { statusPanels },
    };
  }

  onGridReady(event: GridReadyEvent) {
    if (!this.data) return;
    super.onGridReady(event);
    this.refreshDefinitions();
    this.gridApi.refreshCells();
  }

  private refreshDefinitions() {
    const definitions = this.getColumnDefinitions();
    if (this.gridApi) this.gridApi.setColumnDefs(definitions);
  }

  private getColumnDefinitions(): (ColDef | ColGroupDef)[] {
    const definitions: (ColDef | ColGroupDef)[] = [
      {
        field: 'elementId',
        hide: true,
      },
      {
        field: 'linePosition',
        headerName: '#',
        width: 50,
        cellClassRules: {
          'readonly-cell': (params) => params.data && params.data.elementId !== null,
        },
      },
      {
        field: 'element',
        headerName: 'Element Number',
        width: 135,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (params.data.elementId) return false;
          return true;
        },
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          queryChars: 0,
          minWidth: 300,
          propertyRendered: 'lineNumber',
          returnObject: true,
          asyncListConfig: new DropdownConfig<ContractLinesDropdown>({
            listProcedure: endpoints.listContractLines,
            labelField: 'lineNumber',
            valueField: 'id',
            postFilter: (item: ContractLinesDropdown) => (item.priceType === LinePriceType.FIXED ? item.price === this.price : item.price !== null),
            additionalFilters: { contractId: this.contractId, id: { not: this.contractLineId }, priceType: this.priceType },
            newItems: { atStart: false, items: [{ id: null, lineNumber: 'New line', quantity: null }] },
          }),
          columnDefs: [
            { headerName: 'Line number', field: 'lineNumber' },
            { headerName: 'Quantity', field: 'quantity' },
            { headerName: 'Line ID', field: 'id', hide: true },
          ],
        },
        valueFormatter: this.contractLineValueFormatter,
        filter: 'agTextColumnFilter',
        filterParams: {
          valueGetter: (params) => {
            return params.data.element?.lineNumber;
          },
        },
        cellClassRules: {
          'invalid-cell': (params) => params.data && !params.data.element,
          'readonly-cell': (params) => params.data && params.data.elementId !== null,
        },
      },
      {
        field: 'item',
        headerName: 'Item',
        width: 230,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (params.data.elementId) return false;
          return true;
        },
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 300,
          propertyRendered: 'name',
          returnObject: true,
          asyncListConfig: new DropdownConfig<Item>({
            listProcedure: endpoints.listItems,
            labelField: 'name',
            valueField: 'id',
            additionalFilters: { productId: this.productId, groupTypeId: CommonSteelTypeGroups.DEFAULT, archived: YN.N },
            orderBy: { fieldName: 'name', order: 'ASC' },
          }),
          columnDefs: [
            { headerName: 'ID', field: 'id', hide: true },
            { headerName: 'Name', field: 'name' },
          ],
        },
        valueFormatter: this.itemValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (!params.data.elementId && (params.event.key === 'Delete' || params.event.key === 'Backspace')) {
            params.node.setDataValue('item', null);
            return true;
          }
          return false;
        },
        comparator: (valueA: Item | null, valueB: Item | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.name > valueB.name ? 1 : -1;
        },
        filter: 'agTextColumnFilter',
        cellClassRules: {
          'invalid-cell': (params) => params.data && (!params.data.item || this.validateOriginalLineDuplicatedItem()),
          'readonly-cell': (params) => params.data && params.data.elementId !== null,
        },
      },
      {
        field: 'metalControlGroup',
        headerName: 'MC Group',
        width: 180,
        editable: true,
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 300,
          propertyRendered: 'statusName',
          returnObject: true,
          asyncListConfig: new DropdownConfig<MetalControlGroup>({
            listProcedure: endpoints.listMetalControlGroups,
            labelField: 'statusName',
            valueField: 'statusKey',
            additionalFilters: {},
            orderBy: { fieldName: 'statusName', order: 'ASC' },
          }),
          columnDefs: [
            { headerName: 'ID', field: 'statusKey', hide: true },
            { headerName: 'Name', field: 'statusName' },
          ],
        },
        valueFormatter: this.mcGroupValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (!params.data.elementId && (params.event.key === 'Delete' || params.event.key === 'Backspace')) {
            params.node.setDataValue('metalControlGroup', null);
            return true;
          }
          return false;
        },
        comparator: (valueA: MetalControlGroup | null, valueB: MetalControlGroup | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.statusName > valueB.statusName ? 1 : -1;
        },
        filter: 'agTextColumnFilter',
        cellClassRules: {
          'invalid-cell': (params) => params.data && !params.data.metalControlGroup,
        },
      },
      {
        field: 'grossWeight',
        headerName: 'Gross Weight',
        width: 120,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (params.data.elementId && params.data.isOriginalLine) return false;
          return true;
        },
        valueFormatter: this.quantityValueFormatter(),
        ...quantityColumn(),
        valueSetter: (params: ValueSetterParams) => {
          let value: number;
          try {
            value = typeof params.newValue === 'number' ? params.newValue : parseFloat(Mexp.eval(`${params.newValue || ''}`));
          } catch (err) {
            value = NaN;
          }
          if (isNaN(value)) {
            params.data.grossWeight = null;
            return params.oldValue !== params.newValue;
          } else {
            params.data.grossWeight = this.formatter.roundQuantity(value, this.shipmentUnitId);
            return parseFloat(params.oldValue) !== value;
          }
        },
        onCellValueChanged: (event: NewValueParams) => {
          if (this.data.length === 0) return null;

          if (event.newValue) {
            this.reassignedGrossQty += event.oldValue ? -event.oldValue + event.newValue : event.newValue;
            this.reassignedGrossQty = this.formatter.roundQuantity(this.reassignedGrossQty, this.shipmentUnitId);
            this.remainingGrossQty = this.formatter.roundQuantity(this.remainingGrossTotal - this.reassignedGrossQty, this.shipmentUnitId);

            this.data.forEach((child) => {
              if (child && child.isOriginalLine) child.grossWeight = this.remainingGrossQty;
            });

            this.refreshDefinitions();
            event.api.redrawRows({ rowNodes: [event.node] });
          }
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (!params.data.elementId && (params.event.key === 'Delete' || params.event.key === 'Backspace')) {
            const grossWeight = params.node.data.grossWeight;
            params.node.setDataValue('grossWeight', null);
            this.reassignedGrossQty -= grossWeight;
            this.remainingGrossQty = this.remainingGrossTotal - this.reassignedGrossQty;

            this.data.forEach((child) => {
              if (child && child.isOriginalLine) child.grossWeight = this.remainingGrossQty;
            });

            this.refreshDefinitions();

            return true;
          }
          return false;
        },
        cellClassRules: {
          'invalid-cell': (params) => params.data && !params.data.grossWeight,
          'readonly-cell': (params) => params.data && params.data.elementId !== null && params.data.isOriginalLine,
        },
      },
      {
        field: 'netWeight',
        headerName: 'Net Weight',
        width: 120,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (params.data.elementId && params.data.isOriginalLine) return false;
          return true;
        },
        valueFormatter: this.quantityValueFormatter(),
        ...quantityColumn(),
        valueSetter: (params: ValueSetterParams) => {
          let value: number;
          try {
            value = typeof params.newValue === 'number' ? params.newValue : parseFloat(Mexp.eval(`${params.newValue || ''}`));
          } catch (err) {
            value = NaN;
          }
          if (isNaN(value)) {
            params.data.netWeight = null;
            return params.oldValue !== params.newValue;
          } else {
            params.data.netWeight = this.formatter.roundQuantity(value, this.shipmentUnitId);
            return parseFloat(params.oldValue) !== value;
          }
        },
        onCellValueChanged: (event: NewValueParams) => {
          if (this.data.length === 0) return null;

          if (event.newValue) {
            this.reassignedNetQty += event.oldValue ? -event.oldValue + event.newValue : event.newValue;
            this.remainingNetQty = this.remainingNetTotal - this.reassignedNetQty;

            this.data.forEach((child) => {
              if (child && child.isOriginalLine) child.netWeight = this.remainingNetQty;
            });
          }
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (!params.data.elementId && (params.event.key === 'Delete' || params.event.key === 'Backspace')) {
            const netWeight = params.node.data.netWeight;
            params.node.setDataValue('netWeight', null);
            this.reassignedNetQty -= netWeight;
            this.remainingNetQty = this.remainingNetTotal - this.reassignedNetQty;

            this.data.forEach((child) => {
              if (child && child.isOriginalLine) child.netWeight = this.remainingNetQty;
            });

            this.refreshDefinitions();

            return true;
          }
          return false;
        },
        cellClassRules: {
          'invalid-cell': (params) => params.data && !params.data.netWeight,
          'readonly-cell': (params) => params.data && params.data.elementId !== null && params.data.isOriginalLine,
        },
      },
      {
        field: 'packingType',
        headerName: 'Packing Type',
        width: 165,
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 300,
          propertyRendered: 'packingName',
          returnObject: true,
          rowData: this.commonData.staticPackingTypes.value,
          columnDefs: [
            { headerName: 'Packing Key', field: 'packingKey', hide: true },
            { headerName: 'Name', field: 'packingName' },
          ],
        },
        valueFormatter: this.packingTypeValueFormatter,
        comparator: (valueA: Item | null, valueB: Item | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.name > valueB.name ? 1 : -1;
        },
        filter: 'agTextColumnFilter',
        cellClassRules: {
          'readonly-cell': () => true,
        },
      },
      {
        field: 'packingQuantity',
        headerName: 'Packing Quantity',
        width: 140,
        ...quantityColumn(),
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (params.data.elementId && params.data.isOriginalLine) return false;
          return true;
        },
        valueSetter: (params: ValueSetterParams) => {
          let newValue: number | null;
          if (!params.newValue && params.newValue !== 0) {
            newValue = null;
            params.data.packingQuantity = newValue;
          } else {
            const packingQuantity = parseFloat(params.newValue);
            newValue = isNaN(packingQuantity) ? params.newValue : packingQuantity;
            params.data.packingQuantity = round(newValue, 0);
          }
          return params.oldValue !== newValue;
        },
        onCellValueChanged: (event: NewValueParams) => {
          if (this.data.length === 0) return null;

          if (event.newValue) {
            this.reassignedPackingQty += event.oldValue ? -event.oldValue + event.newValue : event.newValue;
            this.remainingPackingQty = this.remainingPackingTotal - this.reassignedPackingQty;

            this.data.forEach((child) => {
              if (child && child.isOriginalLine) child.packingQuantity = this.remainingPackingQty;
            });
          }
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (!params.data.elementId && (params.event.key === 'Delete' || params.event.key === 'Backspace')) {
            const packingQuantity = params.node.data.packingQuantity;
            params.node.setDataValue('packingQuantity', null);
            this.reassignedPackingQty -= packingQuantity;
            this.remainingPackingQty = this.remainingPackingTotal - this.reassignedPackingQty;

            this.data.forEach((child) => {
              if (child && child.isOriginalLine) child.packingQuantity = this.remainingPackingQty;
            });

            this.refreshDefinitions();

            return true;
          }
          return false;
        },
        cellClassRules: {
          'invalid-cell': (params) => params.data && params.data.packingType && !params.data.packingQuantity,
          'readonly-cell': (params) => params.data && params.data.elementId !== null && params.data.isOriginalLine,
        },
      },
      {
        field: 'lotNumber',
        headerName: 'Lot Number',
        width: 210,
        editable: true,
        filter: 'agTextColumnFilter',
      },
    ];
    return definitions;
  }

  onCellValueChanged(event: NewValueParams) {
    this.onDataChanged();
  }

  onDataChanged() {
    if (typeof this.onChange === 'function') {
      this.onChange(
        this.data.map((line): ReclassifyShipmentItemLinesForm => {
          return {
            linePosition: line.linePosition,
            elementId: line.element?.id,
            lineNumber: line.element?.lineNumber,
            element: line.element,
            item: line.item,
            metalControlGroup: line.metalControlGroup,
            grossWeight: line.grossWeight || null,
            netWeight: line.netWeight || null,
            packingType: line.packingType,
            packingQuantity: line.packingQuantity,
            lotNumber: line.lotNumber ?? '',
            isOriginalLine: line.isOriginalLine,
          };
        })
      );
    }
    if (typeof this.onValidationChange === 'function') {
      this.onValidationChange();
    }
  }

  writeValue(lines: ReclassifyShipmentItemLinesForm[]): void {
    if (!lines) return;
    this.data = lines;
  }

  addLine() {
    if (this.gridApi) {
      const maxPosition = this.data.reduce((position, existing) => Math.max(position, existing.linePosition), 0) || 0;
      const packingType = this.data.filter((child) => child && child.isOriginalLine)[0].packingType ?? null;
      const row: ReclassifyShipmentItemLinesForm = {
        linePosition: maxPosition + 1,
        elementId: null,
        lineNumber: null,
        element: null,
        item: null,
        metalControlGroup: null,
        grossWeight: null,
        netWeight: null,
        packingType,
        packingQuantity: null,
        lotNumber: '',
        isOriginalLine: false,
      };
      this.data.push(row);
      this.gridApi.setRowData(this.data);
      this.onDataChanged();
      return row;
    }
  }

  addLineOption() {
    return () => {
      if (this.readonly) return [];
      const options = [];

      options.push({
        name: `Add Line`,
        action: this.addLine.bind(this),
      });

      return options;
    };
  }

  deleteLineOption() {
    return (params: GetMainMenuItemsParams | GetContextMenuItemsParams) => {
      if (this.readonly) return [];
      const selectedNodes = params.api.getSelectedNodes();
      const thisNode = (params as GetContextMenuItemsParams).node;
      const options = [];
      if (thisNode && thisNode.data && thisNode.data.elementId) return [];
      if (!!thisNode && (selectedNodes.length === 0 || selectedNodes.length === 1 || !selectedNodes.includes(thisNode)) && this.data.length > 1) {
        options.push({
          name: `Delete This Line`,
          action: () => {
            const index = this.data.findIndex((row) => row.linePosition === thisNode.data.linePosition);
            if (index < 0) return;
            this.data.splice(index, 1);
            this.gridApi.setRowData(this.data);
            this.onDataChanged();
          },
        });
      }
      return options;
    };
  }

  contractLineValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.lineNumber) return `${params.value.lineNumber}`;
    return '';
  }

  itemValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.name) return `${params.value.name}`;
    return '';
  }

  mcGroupValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.statusName) return `${params.value.statusName}`;
    return '';
  }

  packingTypeValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.packingName) return `${params.value.packingName}`;
    return '';
  }

  quantityValueFormatter() {
    return (params: ValueFormatterParams) => {
      return this.formatter.gridStaticUnitFormatter(this.shipmentUnitId)(params);
    };
  }

  validateOriginalLineDuplicatedItem() {
    if (this.data.length > 0) {
      const originalLine = this.data.find((l) => l.isOriginalLine);

      if (!originalLine) return false;

      // Check if there is another line that has the same item.id as the original line
      const originalItemId = originalLine.item.id;
      return this.data.some((line) => line !== originalLine && line.item && line.item.id === originalItemId);
    }
    return false;
  }

  validate(control: AbstractControl): ValidationErrors {
    this.errors.splice(0, this.errors.length);
    if (!(control.value instanceof Array)) {
      this.errors.push('Invalid line values');
    } else {
      let count = 0;
      for (const line of control.value as ReclassifyShipmentItemLinesForm[]) {
        const pos = line.linePosition;
        if (!pos) continue;
        if (control.value.length < 2) this.errors.push('There should be at least two lines');
        if (!line.element) this.errors.push(`Missing Element Number on line #${pos}`);
        if (!line.item) this.errors.push(`Missing Item on line #${pos}`);
        else if (this.validateOriginalLineDuplicatedItem()) this.errors.push(`Item cannot be duplicated on line #${pos}`);
        if (!line.metalControlGroup) this.errors.push(`Missing MC Group on line #${pos}`);
        if (!line.grossWeight) this.errors.push(`Missing Gross Weight on line #${pos}`);
        if (!line.netWeight) this.errors.push(`Missing Net Weight on line #${pos}`);
        if (line.packingType && !line.packingQuantity) this.errors.push(`Missing Packing Quantity on line #${pos}`);
        count++;
      }
      if (count < 1) this.errors.push(`There should be at least one valid line`);
    }
    return !!this.errors && this.errors.length > 0 ? { custom: this.errors[0] } : null;
  }
}

export type ReclassifyShipmentItemLinesForm = ReclassifyLinesData & {
  linePosition: number;
};

export type ContractLinesDropdown = Omit<ContractLine, 'lineNumber'> & {
  lineNumber: string;
};
