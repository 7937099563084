import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of, BehaviorSubject, combineLatest } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { endpoints } from 'src/lib/apiEndpoints';
import { compareDates, endpointAuthorizationSubscription, endpointsAuthorized, getToday } from 'src/lib/helperFunctions';
import {
  ActiveMarketValuationHeader,
  ApprovalType,
  ApprovalTypeApprover,
  Contact,
  ContainerType,
  Currency,
  Incoterm,
  MarketValuationHeader,
  MarketValuationType,
  PackingType,
  Product,
  Unit,
  YN,
} from 'src/lib/newBackendTypes';
import { ListFilterService } from './list-filter.service';
import { Store } from './store.service';
import { ThalosApiService } from './thalos-api.service';
import { ListResponse } from 'src/lib';
import { ExistingGraphql } from 'src/lib/graphql/graphQlEnums';
import { PricePrecision } from 'src/lib/newBackendTypes/pricePrecision';
import { MaterialFamily } from 'src/lib/newBackendTypes/materialFamily';

const LocalStorageCacheKey = 'thalos-common-data';

@UntilDestroy()
@Injectable()
export class CommonDataService implements OnDestroy {
  public staticUnits: BehaviorSubject<readonly Unit[]> = new BehaviorSubject(null);
  public staticFilteredUnits: BehaviorSubject<readonly Unit[]> = new BehaviorSubject(null);
  public staticCurrencies: BehaviorSubject<readonly Currency[]> = new BehaviorSubject(null);
  public staticMarketValuations: BehaviorSubject<MarketValuationHeader[]> = new BehaviorSubject(null);
  public staticFilteredMarketValuations: BehaviorSubject<MarketValuationHeader[]> = new BehaviorSubject(null);
  public staticActiveMarketValuations: BehaviorSubject<ActiveMarketValuationHeader[]> = new BehaviorSubject(null);
  public staticPremiumValuations: BehaviorSubject<MarketValuationHeader[]> = new BehaviorSubject(null);
  public staticFilteredPremiumValuations: BehaviorSubject<MarketValuationHeader[]> = new BehaviorSubject(null);
  public staticActivePremiumValuations: BehaviorSubject<ActiveMarketValuationHeader[]> = new BehaviorSubject(null);
  public staticProducts: BehaviorSubject<Product[]> = new BehaviorSubject(null);
  public staticProductFamilies: BehaviorSubject<MaterialFamily[]> = new BehaviorSubject(null);
  public staticIncoterms: BehaviorSubject<Incoterm[]> = new BehaviorSubject(null);
  public staticCompanies: BehaviorSubject<Contact[]> = new BehaviorSubject(null);
  public staticAllCompanies: BehaviorSubject<Contact[]> = new BehaviorSubject(null);
  public staticApprovalTypes: BehaviorSubject<ApprovalType[]> = new BehaviorSubject(null);
  public staticContainerTypes: BehaviorSubject<ContainerType[]> = new BehaviorSubject(null);
  public staticPackingTypes: BehaviorSubject<PackingType[]> = new BehaviorSubject(null);
  public approvalTypeApprovers: BehaviorSubject<ApprovalTypeApprover[]> = new BehaviorSubject(null);
  public allEndpoints: BehaviorSubject<endpoints[]> = new BehaviorSubject(null);
  public allGraphqls: BehaviorSubject<ExistingGraphql[]> = new BehaviorSubject(null);
  public staticPricePrecisions: BehaviorSubject<readonly PricePrecision[]> = new BehaviorSubject(null);

  authorized: endpointsAuthorized;

  dataRecalled: commonDataCache = {};

  constructor(private listFilterService: ListFilterService, store: Store, private api: ThalosApiService) {
    endpointAuthorizationSubscription(store, this).subscribe(() => {
      this.getStaticLists();
    });
  }
  getStaticLists() {
    const cachedData = localStorage.getItem(LocalStorageCacheKey);
    let commonDataRecall: commonDataCache;
    if (cachedData) {
      try {
        commonDataRecall = JSON.parse(cachedData);
      } catch (e) {
        commonDataRecall = {};
      }
    } else {
      commonDataRecall = {};
    }

    this.dataRecalled = commonDataRecall;
    const expired = commonDataRecall.date === undefined || !compareDates(this.dataRecalled.date, getToday());

    const staticCurrencies = this.authorized[endpoints.listCurrencies]
      ? commonDataRecall.currencies && !expired
        ? of(commonDataRecall.currencies)
        : this.listFilterService.listData<Currency>(endpoints.listCurrencies, { filters: { name: '**' } }).pipe(
            first(),
            map((res) => res.list.filter((curr) => curr.id != 0))
          )
      : (of([]) as Observable<readonly Currency[]>);

    const units = this.authorized[endpoints.listUnits]
      ? commonDataRecall.units && !expired
        ? of(commonDataRecall.units)
        : this.listFilterService.listData<Unit>(endpoints.listUnits, { filters: { name: '**' } }).pipe(
            first(),
            map((res) => res.list.filter((unit) => unit.unitId !== 0))
          )
      : (of([]) as Observable<readonly Unit[]>);

    const valuations = this.authorized[endpoints.listMarketValuations]
      ? commonDataRecall.valuations && !expired
        ? of(commonDataRecall.valuations)
        : this.listFilterService
            .listData<MarketValuationHeader>(endpoints.listMarketValuations, {
              filters: { hidden: YN.N },
            })
            .pipe(
              first(),
              map((res) => res.list)
            )
      : of([]);
    const activeValuations = this.authorized[endpoints.listActiveMarketValuations]
      ? commonDataRecall.activeValuations && commonDataRecall.activeValuations.length > 0 && !expired
        ? of(commonDataRecall.activeValuations)
        : this.listFilterService.listData<ActiveMarketValuationHeader>(endpoints.listActiveMarketValuations, { filters: {} }).pipe(
            first(),
            map((res: ListResponse<ActiveMarketValuationHeader>) => res.list)
          )
      : of([]);
    const staticCompanies = this.authorized[endpoints.listCompanies] ? this.listFilterService.listData<Contact>(endpoints.listCompanies, { filters: {} }).pipe(map((res) => res.list)) : of([]);
    const staticAllCompanies = this.authorized[endpoints.listAllCompanies]
      ? this.listFilterService.listData<Contact>(endpoints.listAllCompanies, { filters: {} }).pipe(map((res) => res.list))
      : of([]);
    const staticIncoterms = this.authorized[endpoints.listIncoterms]
      ? commonDataRecall.incoterms && !expired
        ? of(commonDataRecall.incoterms)
        : this.listFilterService.listData<Incoterm>(endpoints.listIncoterms, { filters: {} }).pipe(map((res) => res.list.filter((i) => i.id !== 0)))
      : of([]);
    const staticProducts = this.authorized[endpoints.listProducts]
      ? commonDataRecall.products && !expired
        ? of(commonDataRecall.products)
        : this.listFilterService.listData<Product>(endpoints.listProducts, { filters: {} }).pipe(map((res) => res.list.filter((p) => p.productId !== 0)))
      : of([]);

    const staticProductFamilies = this.authorized[endpoints.listProductFamilies]
      ? commonDataRecall.productFamilies && !expired
        ? of(commonDataRecall.productFamilies)
        : this.listFilterService.listData<MaterialFamily>(endpoints.listProductFamilies, { filters: {} }).pipe(map((res) => res.list.filter((p) => p.id !== 0)))
      : of([]);

    const staticApprovalTypes = this.authorized[endpoints.listApprovalTypes]
      ? commonDataRecall.approvalTypes && !expired
        ? of(commonDataRecall.approvalTypes)
        : this.listFilterService.listData<ApprovalType>(endpoints.listApprovalTypes, { filters: {} }).pipe(map((res) => res.list.filter((a) => a.id !== 0)))
      : of([]);

    const staticContainerTypes = this.authorized[endpoints.listContainerTypes]
      ? commonDataRecall.containerTypes && !expired
        ? of(commonDataRecall.containerTypes)
        : this.listFilterService.listData<ContainerType>(endpoints.listContainerTypes, { filters: {} }).pipe(map((res) => res.list.filter((c) => c.id !== 0)))
      : of([]);

    const staticPackingTypes = this.authorized[endpoints.listPackingTypes]
      ? commonDataRecall.packingTypes && !expired
        ? of(commonDataRecall.packingTypes)
        : this.listFilterService.listData<PackingType>(endpoints.listPackingTypes, { filters: {} }).pipe(map((res) => res.list.filter((c) => c.packingKey !== 0)))
      : of([]);

    const approvalTypeApprovers = this.authorized[endpoints.listApprovalTypeApprovers]
      ? commonDataRecall.approvalTypeApprovers && !expired
        ? of(commonDataRecall.approvalTypeApprovers)
        : this.listFilterService.listData<ApprovalTypeApprover>(endpoints.listApprovalTypeApprovers, { filters: {} }).pipe(map((res) => res.list))
      : of([]);

    const allEndpoints = this.authorized[endpoints.listEndpointsAdmin] ? this.api.rpc<endpoints[]>(endpoints.listEndpointsAdmin, {}, []) : of([]);

    const allGraphqls = this.authorized[endpoints.listGraphqlAdmin] ? this.api.rpc<ExistingGraphql[]>(endpoints.listGraphqlAdmin, {}, []) : of([]);

    const staticPricePrecisions = this.authorized[endpoints.listPricePrecision]
      ? commonDataRecall.pricePrecisions && !expired
        ? of(commonDataRecall.pricePrecisions)
        : this.listFilterService.listData<PricePrecision>(endpoints.listPricePrecision, { filters: {} }).pipe(
            first(),
            map((res) => res.list.filter((price) => price.id !== 0))
          )
      : (of([]) as Observable<readonly PricePrecision[]>);

    staticCurrencies.subscribe((res) => this.staticCurrencies.next(res));
    units.subscribe((res) => {
      this.staticUnits.next(res);
      this.staticFilteredUnits.next(res.filter((u) => u.indivisible === YN.N));
    });

    valuations.subscribe((res) => {
      const valuations = res.filter((v) => v.line && v.line.lineType === MarketValuationType.PRICE);
      this.staticMarketValuations.next(valuations);
      this.staticFilteredMarketValuations.next(valuations.filter((mv) => mv.archived === YN.N));
      const premium = res.filter((v) => v.line && v.line.lineType === MarketValuationType.PREMIUM);
      this.staticPremiumValuations.next(premium);
      this.staticFilteredPremiumValuations.next(premium.filter((mv) => mv.archived === YN.N));
    });

    activeValuations.subscribe((res: ActiveMarketValuationHeader[]) => {
      this.staticActiveMarketValuations.next(res.filter((v) => v.lineType === MarketValuationType.PRICE));
      this.staticActivePremiumValuations.next(res.filter((v) => v.lineType === MarketValuationType.PREMIUM));
    });

    staticCompanies.subscribe((res) => {
      this.staticCompanies.next(res);
    });
    staticAllCompanies.subscribe((res) => {
      this.staticAllCompanies.next(res);
    });
    staticIncoterms.subscribe((res) => this.staticIncoterms.next(res));
    staticProducts.subscribe((res) => this.staticProducts.next(res));
    staticProductFamilies.subscribe((res) => this.staticProductFamilies.next(res));

    staticApprovalTypes.subscribe((res) => this.staticApprovalTypes.next(res));

    staticContainerTypes.subscribe((res) => this.staticContainerTypes.next(res));

    staticPackingTypes.subscribe((res) => this.staticPackingTypes.next(res));

    approvalTypeApprovers.subscribe((res) => this.approvalTypeApprovers.next(res));

    allEndpoints.subscribe((res) => this.allEndpoints.next(res));

    allGraphqls.subscribe((res) => this.allGraphqls.next(res));

    staticPricePrecisions.subscribe((res) => this.staticPricePrecisions.next(res));
  }

  dataLoaded() {
    return combineLatest([
      this.staticApprovalTypes,
      this.staticCompanies,
      this.staticAllCompanies,
      this.staticCurrencies,
      this.staticFilteredMarketValuations,
      this.staticFilteredPremiumValuations,
      this.staticFilteredUnits,
      this.staticIncoterms,
      this.staticMarketValuations,
      this.staticActiveMarketValuations,
      this.staticPremiumValuations,
      this.staticActivePremiumValuations,
      this.staticProducts,
      this.staticProductFamilies,
      this.staticUnits,
      this.staticContainerTypes,
      this.staticPackingTypes,
      this.approvalTypeApprovers,
      this.staticPricePrecisions,
      this.allEndpoints,
      this.allGraphqls,
    ]).pipe(
      filter((res) => res.every((a) => !!a)),
      tap(
        ([
          approvalTypes,
          companies,
          allCompanies,
          currencies,
          filteredValuations,
          filteredPremiumValuations,
          filteredUnits,
          incoterms,
          valuations,
          activeValuations,
          premiumValuations,
          activePremiumValuations,
          products,
          productFamilies,
          units,
          containerTypes,
          packingTypes,
          approvalTypeApprovers,
          pricePrecisions,
        ]) => {
          if (!validateCache(this.dataRecalled)) {
            try {
              const cacheObject: commonDataCache = {
                approvalTypeApprovers,
                currencies,
                approvalTypes,
                valuations: [...valuations, ...premiumValuations],
                activeValuations: [...activeValuations, ...activePremiumValuations],
                incoterms,
                products,
                productFamilies,
                units,
                containerTypes,
                packingTypes,
                date: getToday(),
                pricePrecisions,
              };
              const json = JSON.stringify(cacheObject);
              localStorage.setItem(LocalStorageCacheKey, json);
            } catch (e) {
              console.log(e);
            }
          }
        }
      ),
      map((_) => {
        return true;
      }),
      first()
    );
  }

  ngOnDestroy() {}
}

type commonDataCache = {
  date?: Date;
  units?: readonly Unit[];
  currencies?: readonly Currency[];
  valuations?: MarketValuationHeader[];
  activeValuations?: ActiveMarketValuationHeader[];
  products?: Product[];
  productFamilies?: MaterialFamily[];
  incoterms?: Incoterm[];
  approvalTypes?: ApprovalType[];
  containerTypes?: ContainerType[];
  packingTypes?: PackingType[];
  approvalTypeApprovers?: ApprovalTypeApprover[];
  pricePrecisions?: readonly PricePrecision[];
};

function validateCache(c: commonDataCache) {
  return (
    !!c.approvalTypeApprovers &&
    !!c.approvalTypes &&
    !!c.containerTypes &&
    !!c.currencies &&
    !!c.incoterms &&
    !!c.packingTypes &&
    !!c.products &&
    !!c.units &&
    !!c.valuations &&
    !!c.activeValuations &&
    !!c.date &&
    !!c.pricePrecisions &&
    compareDates(c.date, getToday())
  );
}
