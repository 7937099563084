import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { pairwise, startWith } from 'rxjs/operators';
import { State } from 'src/app/core/reducers';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { Store } from 'src/app/core/services/store.service';
import { DropdownConfig } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { intFormat } from 'src/lib/commonTypes';
import { conditionalValidators, specialCharactersValidator } from 'src/lib/genericValidators';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { ContactMethodType, Document, DocumentPacket, DocumentTemplate, SourceEntityType, StorageTypes } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';

@UntilDestroy()
@Component({
  selector: 'print-document',
  templateUrl: './print-document.component.html',
})
export class PrintDocumentComponent implements OnInit, ModalFormComponent<PrintDocumentForm> {
  popup = false;

  form: TypedFormGroup<PrintDocumentForm>;
  packetDropdown = new DropdownConfig<DocumentPacket>({
    listProcedure: endpoints.listDocumentPackets,
    valueField: 'id',
    labelField: 'name',
    take: 0,
  });

  @Input()
  documents!: Document[];

  @Input()
  entityName!: string;

  @Input()
  entityType!: SourceEntityType;

  @Input()
  entityNumber?: string;

  @Input()
  companyId?: number;

  @Input()
  isMultipleIds!: boolean;

  showAdvanced = false;

  locations: { label: string; value: number }[] = [
    {
      value: -1,
      label: 'Save Here',
    },
  ];

  intFormat = intFormat();

  constructor(protected store: Store) {
    this.form = new TypedFormGroup<PrintDocumentForm>({
      emailTo: new UntypedFormControl(null),
      entityName: new UntypedFormControl(''),
      saveAs: new UntypedFormControl('', [Validators.required, specialCharactersValidator()]),
      packet: new UntypedFormControl(null, Validators.required),
      location: new UntypedFormControl(null),
      copies: new UntypedFormArray([]),
    });

    this.form.get('packet').valueChanges.subscribe((p: DocumentPacket) => {
      if (!!p) {
        const saveAs = `${p.filename + ' ' + this.entityName}${!this.isMultipleIds && this.entityNumber ? ' ' + this.entityNumber : ''}`;

        if (this.form.get('saveAs').untouched) {
          this.form.patchValue({ saveAs });
        }
      }
    });
  }

  ngOnInit(): void {
    this.packetDropdown.additionalFilters = { sourceEntityTypeId: this.entityType, companyId: this.companyId };

    let email: string = '';

    if (this.isMultipleIds) {
      email = this.store.snapshot((state: State) => {
        const emailContactMethod = state.user.user.methods.filter((m) => m.type === ContactMethodType.EMAIL);
        const userEmail = emailContactMethod[0].value;
        return userEmail;
      });

      this.form.get('emailTo').addValidators([Validators.required, Validators.email]);
      this.form.patchValue({ emailTo: email, entityName: this.entityName });
    } else {
      this.form.get('location').addValidators(Validators.required);

      if (this.documents) {
        this.locations = [
          ...(this.locations || []),
          ...this.documents
            .filter((d) => [StorageTypes.FILESYSTEM_FOLDER, StorageTypes.URL].includes(d.storageType) && !!d.id)
            .map((d) => {
              return { value: d.id, label: d.fileName || d.fsPath };
            }),
        ];
      }
    }

    this.form
      .get('packet')
      .valueChanges.pipe(startWith<DocumentPacket>(null), pairwise(), untilDestroyed(this))
      .subscribe(([prev, curr]) => {
        if (prev?.id === curr?.id) return;
        (this.form.get('copies') as UntypedFormArray).clear();
        if (!!curr) {
          for (let t of curr.templateAssignments) {
            if (t.template) this.addTemplate(t.template);
          }
        }
      });
  }

  addTemplate(template: DocumentTemplate) {
    const arr = this.form.get('copies') as UntypedFormArray;

    const fg = new TypedFormGroup<PrintDocumentTemplateQuantity>({
      templateId: new UntypedFormControl(template.id),
      quantity: new UntypedFormControl(
        1,
        conditionalValidators(() => this.showAdvanced, Validators.required)
      ),
      name: new UntypedFormControl(template.name),
    });

    arr.push(fg);
  }

  prefillForm(prefillValue: Partial<PrintDocumentForm>) {
    this.form.patchValue(prefillValue);
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit() {
    return {
      ...this.form.value,
      copies: this.form.value.copies.map((q) => {
        return {
          quantity: this.showAdvanced ? q.quantity : 1,
          templateId: q.templateId,
        };
      }),
    };
  }

  toggleAdvancedView() {
    this.showAdvanced = !this.showAdvanced;
  }

  get copiesArray() {
    return this.form.get('copies') as UntypedFormArray;
  }
}

export type PrintDocumentForm = {
  emailTo?: string;
  entityName?: string;
  saveAs: string;
  packet: DocumentPacket;
  location?: number;
  copies: PrintDocumentTemplateQuantity[];
};
export type PrintDocumentTemplateQuantity = {
  templateId: number;
  name?: string;
  quantity: number;
};
