import { DialogCloseResult, DialogResult } from '@progress/kendo-angular-dialog';
import { from, of, switchMap, tap } from 'rxjs';
import { CustomDialogResult } from 'src/app/core/services/selector-popup.service';
import { handlePrintResponse } from 'src/app/shared/microsoft-entity-documents/microsoft-entity-documents.component';
import { PrintDocumentComponent, PrintDocumentForm } from 'src/app/shared/print-document/print-document.component';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import {
  Document,
  Entity,
  PrintDocumentRequest,
  PrintDocumentResponse,
  PrintMultipleDocumentsRequest,
  SourceEntityType,
  SourceEntityTypeEntityNameMap,
  StorageTypes,
  getEntityName,
} from 'src/lib/newBackendTypes';
import { ListColumnType } from '../../views/listColumn';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

export const printDocumentPrefill: prefillCallback<any> = (delegate, id, column) => {
  return from(
    (async () => {
      if (column.type && column?.type !== ListColumnType.ID) return of('Invalid column');

      const api = delegate.getService('api');

      const documentsResponse = await api.run<ListResponse<Document>>(endpoints.listDocuments, { filters: { entityId: id, entityType: column.typeConfiguration.entityType } });
      if (documentsResponse === null) return of('No Documents available');
      const documents = documentsResponse.list;

      const entityType = column.typeConfiguration.entityType;
      const entity = await api.run<Entity<SourceEntityType>>(endpoints.getGenericEntity, { entityType, entityId: id[0] });
      const entityNumber = getEntityName(entityType, entity);
      const companyId: number = entity.companyId ?? entity.companyCode;

      return { documents, entityNumber, companyId };
    })()
  );
};

export const printDocumentForm: openFormCallback<PrintDocumentPrefill, PrintDocumentForm> = (delegate, id, prefill, column) => {
  const selector = delegate.getService('selector');
  const isMultipleIds = id.length > 1 ? true : false;

  return selector.openForm<PrintDocumentForm, PrintDocumentComponent>(PrintDocumentComponent, {
    title: isMultipleIds ? 'Print Multiple Documents' : 'Print Document',
    width: 400,
    submitButtonText: 'Print',
    initializer: (c) => {
      c.documents = prefill.documents;
      c.entityName = SourceEntityTypeEntityNameMap[column.typeConfiguration.entityType];
      c.entityType = column.typeConfiguration.entityType;
      c.isMultipleIds = isMultipleIds;
      c.entityNumber = prefill.entityNumber;
      c.companyId = prefill.companyId;
    },
  });
};

export const printDocumentSubmit: submitFormCallback<PrintDocumentPrefill, PrintDocumentForm> = (delegate, id, form, prefill, column) => {
  const api = delegate.getService('api');
  const dialog = delegate.getService('dialog');
  const prompt = delegate.getService('prompt');
  const isMultipleIds = id.length > 1 ? true : false;
  let request: PrintDocumentRequest | PrintMultipleDocumentsRequest;

  if (isMultipleIds) {
    request = {
      emailTo: form.emailTo,
      entityName: form.entityName,
      entityIds: id,
      outputName: form.saveAs,
      packetId: form.packet.id,
      savingLocation: form.location === -1 ? null : form.location,
      copies: form.copies,
    };

    return api.rpc<{ message: string }>(endpoints.documentsFromPacketZipFile, request, null).pipe(
      switchMap((packet) => {
        if (!packet) return of(null);
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Print documents succesfully requested.</div>`);
      })
    );
  } else {
    request = {
      copies: form.copies,
      entityId: id[0],
      outputName: form.saveAs,
      packetId: form.packet.id,
      savingLocation: form.location === -1 ? null : form.location,
    };

    return api.rpc<PrintDocumentResponse>(endpoints.generateDocumentsFromPacket, request, null).pipe(
      switchMap((packet) => {
        if (!packet) return of(null);
        const actions: CustomDialogResult[] = [{ text: 'Close' }];
        if (/Win/.test(navigator.platform) || packet.locationType === StorageTypes.URL || packet.locationType === StorageTypes.DATABASE_FILE) {
          actions.push({ text: 'Open File', themeColor: 'primary' });
        }
        return dialog
          .open({
            title: 'Success',
            content: 'File generated successfully',
            actions,
          })
          .result.pipe(
            tap((res: DialogResult) => {
              if (!res) return;
              if (!(res instanceof DialogCloseResult) && res.text === 'Open File') {
                handlePrintResponse(delegate, packet);
              }
            })
          );
      })
    );
  }
};

export const printDocumentPreset: DynamicFormConstant<PrintDocumentPrefill, PrintDocumentForm> = {
  allowMultipleRows: true,
  title: 'Print',
  value: DynamicFormType.PRINT_DOCUMENT,
  label: 'Print Document',
  entityType: null,
  getPrefill: printDocumentPrefill,
  openForm: printDocumentForm,
  submitForm: printDocumentSubmit,
  endpoints: [endpoints.listDocuments, endpoints.getGenericEntity, endpoints.generateDocumentsFromPacket, endpoints.listDocumentPackets, endpoints.documentsFromPacketZipFile],
  width: 400,
};

export type PrintDocumentPrefill = {
  documents: Document[];
  entityNumber: string;
  companyId: number;
};
