import { from, lastValueFrom } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { CommonUnits, CommonUnitsNameMap, ReclassifyLinesData, ReclassifyShipmentItemData, ReclassifyShipmentItemRequest, SourceEntityType } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { ReclassifyShipmentItemComponent } from 'src/app/+modules/+logistics/reclassify-shipment-item/reclassify-shipment-item.component';
import { roundAmount } from 'src/lib/helperFunctions';

const reclassifyShipmentItemPrefill: prefillCallback<ReclassifyShipmentItemData> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const reclassifyData = await lastValueFrom(api.rpc<ReclassifyShipmentItemData>(endpoints.getReclassifyShipmentData, { shipmentId: id }, null));
      return reclassifyData;
    })()
  );
};

const reclassifyShipmentItemForm: openFormCallback<ReclassifyShipmentItemData, ReclassifyShipmentItemForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<ReclassifyShipmentItemForm, ReclassifyShipmentItemComponent, ReclassifyShipmentItemData>(ReclassifyShipmentItemComponent, {
    title: `Reclassify Item Quantity - Shipment ${prefill.shipmentId}`,
    prefillValue: prefill,
    width: '1420px',
    maxWidth: '90%',
  });
};

const submitReclassifyShipmentItem: submitFormCallback<ReclassifyShipmentItemData, ReclassifyShipmentItemForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<ReclassifyShipmentItemResponseData>((resolve, reject) => {
      (async () => {
        const request: ReclassifyShipmentItemRequest = {
          shipmentId: result.shipmentId,
          contractId: result.contractId,
          lines: result.lines,
        };
        const reclassifyShipmentItemResponse = await api.run<ReclassifyShipmentItemResponseData>(endpoints.reclassifyShipmentItem, request, null);
        if (reclassifyShipmentItemResponse) return resolve(reclassifyShipmentItemResponse);
        return reject('Unknown result. Please check if shipment was updated and try again if necessary.');
      })();
    })
      .then((res) => {
        if (res && res.data && res.data.length > 0) {
          const data = res.data;
          const originalShipment = data.find((s) => s.isOriginalLine);
          const roundingPrecision = originalShipment.shipmentUnitId === CommonUnits.MT ? 3 : 0;
          const originalShipmentAbbreviation = CommonUnitsNameMap[originalShipment.shipmentUnitId];

          return prompt.htmlDialog(
            'Success',
            `<div style="white-space: pre">The following shipments have been updated/created due to reclassification of item for Contract Number ${prefill.contractNumber}:\n\nUpdated:\nShipment ${
              originalShipment.shipmentId
            } with Item ${originalShipment.item?.name ?? 'Unknown'} and Net Weight ${roundAmount(originalShipment.netWeight, roundingPrecision)} ${originalShipmentAbbreviation}
            \nCreated: \n${data
              .map((s) => `- Shipment ${s.shipmentId} with Item ${s.item?.name ?? 'Unknown'} and Net Weight ${roundAmount(s.netWeight, roundingPrecision)} ${originalShipmentAbbreviation}`)
              .join('\n')}</div>`
          );
        }
      })
      .catch((err) => {
        if (err) return prompt.htmlDialog('Error', `<div style="white-space: pre">${err}</div>`);
      })
  );
};

export const reclassifyShipmentItemPreset: DynamicFormConstant<ReclassifyShipmentItemData, ReclassifyShipmentItemForm> = {
  allowMultipleRows: false,
  getPrefill: reclassifyShipmentItemPrefill,
  openForm: reclassifyShipmentItemForm,
  entityType: SourceEntityType.CHUNK_KEY,
  label: 'Reclassify Item Quantity',
  submitForm: submitReclassifyShipmentItem,
  title: 'Reclassify Item Quantity',
  value: DynamicFormType.RECLASSIFY_SHIPMENT_ITEM,
  endpoints: [endpoints.getReclassifyShipmentData, endpoints.reclassifyShipmentItem],
  width: 1420,
};

export type ReclassifyShipmentItemForm = ReclassifyShipmentItemRequest & Pick<ReclassifyShipmentItemData, 'contractNumber'>;

export type ReclassifyShipmentItemResponse = Pick<ReclassifyShipmentItemData, 'shipmentId' | 'netWeight' | 'shipmentUnitId'> & Pick<ReclassifyLinesData, 'item' | 'isOriginalLine'>;

export type ReclassifyShipmentItemResponseData = {
  data: ReclassifyShipmentItemResponse[];
};
