import { from } from 'rxjs';
import { createFormCallback, DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';
import { ProcessBankFileResponseMapped } from 'src/lib/newBackendTypes/bankReconciliation';
import { CreateJournalEntry, CreateJournalRequest, EntryMode, EntryOperation, JournalEntrySource, JournalHeader, SourceEntityType } from 'src/lib/newBackendTypes';
import { endpoints } from 'src/lib/apiEndpoints';
import { formatDate } from 'src/lib/helperFunctions';

const createJournalBankReconciliationForm: createFormCallback<ProcessBankFileResponseMapped> = (delegate, id, prefill) => {
  return [
    {
      type: 'Label',
      text: `A Journal will be created. Do you want to confirm?`,
      style: { 'margin-bottom': '15px' },
    },
  ];
};

const createJournalBankReconciliatioCallback: submitFormCallback<ProcessBankFileResponseMapped> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<JournalHeader>((resolve, reject) => {
      (async () => {
        const baseJournalEntry: CreateJournalEntry = {
          accountId: prefill.fileGlAccountId,
          amount: prefill.lineAmount,
          baseAmount: prefill.lineAmount,
          entryPosition: 1,
          entryText1: prefill.lineEntryText,
          entryText2: '',
          externalRef: prefill.lineEntryText,
        };
        const request: CreateJournalRequest = {
          companyId: prefill.fileCompanyId,
          entryDate: new Date(),
          valueDate: prefill.fileDate,
          entryMode: EntryMode.AUTOMATIC,
          entryOperation: EntryOperation.NORMAL,
          entrySource: JournalEntrySource.AUTOMATION,
          externalRef: `Sweep ${prefill.lineAmount >= 0 ? 'in' : 'out'} - Bank account ${prefill.fileAccountNumber}`,
          title: `Sweep ${prefill.lineAmount >= 0 ? 'in' : 'out'} ${formatDate(prefill.fileDate)} ${prefill.fileAccountNumber}`,
          auxDocumentReference: null,
          entries: [
            baseJournalEntry,
            {
              ...baseJournalEntry,
              amount: baseJournalEntry.amount * -1,
              baseAmount: baseJournalEntry.baseAmount * -1,
              entryPosition: baseJournalEntry.entryPosition + 1,
              accountId: 120432, //TODO: pending to validate what account should be used
            },
          ],
        };

        try {
          const response = await api.run<JournalHeader>(endpoints.createJournal, request, null);
          return resolve(response);
        } catch (err) {
          return reject(err);
        }
      })();
    })
      .then((res) => {
        if (res) return prompt.htmlDialog('Success', `<div style="white-space: pre">Journal ${res.journalIndex} was created successfully.</div>`);
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const createJournalBankReconciliationPreset: DynamicFormConstant<ProcessBankFileResponseMapped> = {
  allowMultipleRows: false,
  value: DynamicFormType.CREATE_JOURNAL_BANK_RECONCILIATION,
  label: 'Create Journal',
  title: 'Create Journal',
  endpoints: [endpoints.createJournal],
  entityType: SourceEntityType.JOURNAL_ID,
  width: 600,
  createForm: createJournalBankReconciliationForm,
  submitForm: createJournalBankReconciliatioCallback,
};
