<div class="d-flex desktop-h-100" style="flex-direction: column">
  <ng-container *ngIf="readonly && popup"> Readonly mode </ng-container>

  <form [formGroup]="searchForm" *ngIf="!readonly" class="form-container">
    <div class="row">
      <vertical-field name="Company" class="col-lg-2 col-md-3 col-sm-3 col-6">
        <dropdown-wrapper [formControlName]="'company'" [dropdownConfig]="companyDropdown"></dropdown-wrapper>
      </vertical-field>
      <vertical-field class="col-xxl col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" [name]="'Freight Booking'">
        <numerictextbox-wrapper cyData="freightBooking" [formControlName]="'bookingNumber'" [decimals]="0" [min]="1" [format]="numberFormat"> </numerictextbox-wrapper>
      </vertical-field>
      <vertical-field class="col-xxl col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" [name]="'Shipment Id'">
        <numerictextbox-wrapper cyData="shipmentId" [formControlName]="'shipmentId'" [decimals]="0" [min]="1" [format]="numberFormat"> </numerictextbox-wrapper>
      </vertical-field>
      <vertical-field class="col-xxl col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" [name]="'Container Marks'">
        <input-wrapper cyData="containerMarks" [formControlName]="'containerMarks'"> </input-wrapper>
      </vertical-field>
      <vertical-field class="col-xxl col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" [name]="'Invoice Number'">
        <numerictextbox-wrapper cyData="invoiceNumber" [formControlName]="'invoiceNumber'" [decimals]="0" [min]="1" [format]="numberFormat"> </numerictextbox-wrapper>
      </vertical-field>
      <vertical-field class="col-xxl col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" [name]="'Contract Number'">
        <numerictextbox-wrapper cyData="number" [formControlName]="'contractNumber'" [decimals]="0" [min]="1" [format]="numberFormat"> </numerictextbox-wrapper>
      </vertical-field>
      <vertical-field class="col-xxl col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" [name]="'Counterparty'">
        <dropdown-wrapper cyData="counterparty" [formControlName]="'counterparty'" [dropdownConfig]="counterpartyDropdown"> </dropdown-wrapper>
      </vertical-field>
      <vertical-field class="col-xxl col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" [name]="'Appointment Ref'">
        <input-wrapper cyData="appointmentReference" [formControlName]="'appointmentReference'"> </input-wrapper>
      </vertical-field>
    </div>
    <div class="row">
      <vertical-field class="col-xxl col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <checkbox-wrapper [formControlName]="'enableShipmentGenealogy'" [label]="'Enable shipment genealogy'"> </checkbox-wrapper>
      </vertical-field>
    </div>
    <div *ngIf="searchForm.valid === false && searchForm.touched === true && searchForm?.errors?.required" style="text-align: end">
      <label class="error-label">You must fill out at least one field.</label>
    </div>
    <div style="margin-bottom: 10px; display: flex; flex-direction: row-reverse">
      <button type="submit" kendoButton (click)="clickSearch()" style="width: 100px">Search</button>
    </div>
  </form>
  <div style="width: 100%; flex-grow: 1">
    <ag-grid-angular
      class="ag-theme-balham desktop-h-100"
      [ngClass]="{ 'ignore-mobile-height': !popup, hidden: data === null }"
      [rowData]="data"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
    <div *ngIf="!popup && !completelyInvoiced" class="container-file-button mb-2">
      <input hidden type="file" id="file" #file (click)="file.value = null" (change)="importExcel($event)" accept=".xlsx, .xlsm" />
      <button type="button" class="btn btn-info" (click)="file.click()"><i class="fas fa-file-excel"></i> Import Excel</button>
    </div>
  </div>
</div>
