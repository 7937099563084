import { from, of } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { EntryType, OpenItem, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { Advance, AdvanceType, LinkUnlinkAdvancePaymentRequest } from 'src/lib/newBackendTypes/advance';
import { PaymentEntry, PaymentHeader } from 'src/lib/newBackendTypes/payment';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { AdvancePaymentManagerComponent } from 'src/app/+modules/+accounting/advance-payment/advance-payment-manager/advance-payment-manager.component';
import { fromBradyDate } from 'src/lib/helperFunctions';

const linkUnlinkAdvancePaymentPrefill: prefillCallback<LinkUnlinkAdvancePaymentPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const advanceItem = await api.run<Advance>(endpoints.getAdvance, { filters: { id } });

      // Find current advance payment entries to unlink
      const advancePaymentEntries = advanceItem.advancePayments.filter((ap) => ap.paymentEntry && !ap.paymentEntry.auxPaymentEntryId && !ap.paymentEntry.voucherId).map((ap) => ap.paymentEntry);

      // Find open payments to link
      const companyId = advanceItem.companyId;
      const counterpartyId = advanceItem.counterpartyId;
      const counterpartyOpenPayments = (await api.run(endpoints.listOpenPaymentsToAdvance, {
        filters: { companyId, counterpartyId },
      })) as ListResponse<OpenItem>;
      const openPayments = counterpartyOpenPayments.list;

      const openPaymentEntries: AdvancePaymentEntry[] = openPayments.map((item) => {
        return {
          externalRef: item.externalRef,
          phValueDate: fromBradyDate(item.valueDate),
          entryText: item.entryTitle,
          amount: item.balanceAmount,
          paymentEntryId: item.entryId,
        };
      });

      return { advanceItem, openPaymentEntries, advancePaymentEntries };
    })()
  );
};

const linkUnlinkAdvancePaymentCheck: checkPrefillCallback<LinkUnlinkAdvancePaymentPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.advanceItem.type !== AdvanceType.CLIENT_ADVANCE) return of('Payments can only be entered for advances');
  if (prefill.advanceItem.archived === YN.Y) return of('This Advance is marked as closed and cannot be paid');
  if (!prefill.advanceItem.advancePayments) return of('Unable to check the payment status of this advance');
  return of(true);
};

const openlinkUnlinkAdvancePaymentForm: openFormCallback<LinkUnlinkAdvancePaymentPrefill, LinkUnlinkAdvancePaymentForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<LinkUnlinkAdvancePaymentForm, AdvancePaymentManagerComponent, LinkUnlinkAdvancePaymentPrefill>(AdvancePaymentManagerComponent, {
    title: `Link/Unlink Payments to Advance: ${prefill.advanceItem.number}`,
    width: '98%',
    prefillValue: prefill,
  });
};

const linkUnlinkAdvancePaymentCallback: submitFormCallback<LinkUnlinkAdvancePaymentPrefill, LinkUnlinkAdvancePaymentForm> = (delegate, id, form) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<PaymentHeader | null>((resolve, reject) => {
      (async () => {
        const request: LinkUnlinkAdvancePaymentRequest = {
          id,
          paymentEntrysToLink:
            form &&
            form.advancePaymentEntries?.map((pe) => {
              return {
                paymentEntryId: pe.paymentEntryId,
                entryText: pe.entryText,
                externalRef: pe.externalRef,
              };
            }),
        };

        const advancePayment = await api.run<PaymentHeader>(endpoints.linkUnlinkAdvancePayment, request, null);
        if (advancePayment) return resolve(advancePayment);
      })();
    })
      .then((res) => {
        if (!!res) {
          const payment = res;
          if (Object.keys(payment).length === 0) {
            return prompt.htmlDialog('Error', `<div style="white-space: pre">An error occurred while linking/unlinking Advance Payment.</div>`);
          }
          return prompt.htmlDialog('Success', `<div style="white-space: pre">Advance Payment(s) successfully processed</a></div>`);
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const linkUnlinkAdvancePaymentPreset: DynamicFormConstant<LinkUnlinkAdvancePaymentPrefill, LinkUnlinkAdvancePaymentForm> = {
  allowMultipleRows: false,
  getPrefill: linkUnlinkAdvancePaymentPrefill,
  checkPrefill: linkUnlinkAdvancePaymentCheck,
  openForm: openlinkUnlinkAdvancePaymentForm,
  submitForm: linkUnlinkAdvancePaymentCallback,
  endpoints: [endpoints.getAdvance, endpoints.listOpenPaymentsToAdvance, endpoints.linkUnlinkAdvancePayment],
  entityType: SourceEntityType.ADVANCE_KEY,
  label: 'Link/Unlink Payments to Advance',
  title: 'Link/Unlink Payments to Advance',
  value: DynamicFormType.ADVANCE_PAYMENT_MANAGER,
  width: 700,
};

export type LinkUnlinkAdvancePaymentForm = {
  requestedAmount: number;
  openPaymentsLines: AdvancePaymentEntryForm[];
  advancePaymentEntries: AdvancePaymentEntryForm[];
};

export type LinkUnlinkAdvancePaymentPrefill = {
  advanceItem: Advance;
  openPaymentEntries: AdvancePaymentEntry[];
  advancePaymentEntries: AdvancePaymentEntry[];
};

export type AdvancePaymentEntry = Subset<PaymentEntry, 'externalRef' | 'phValueDate' | 'entryText' | 'amount' | 'paymentEntryId'>;
export type AdvancePaymentEntryForm = AdvancePaymentEntry & {
  lineNumber?: number;
  entryType?: EntryType;
  isLinkedAdvancePaymentEntry?: Boolean; // Flag to indicate if a Payment Entry is already linke to Advance
};
