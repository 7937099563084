import { Router } from '@angular/router';
import {
  CellClassParams,
  CellDoubleClickedEvent,
  CellStyle,
  CellStyleFunc,
  CheckboxSelectionCallbackParams,
  ColDef,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GetQuickFilterTextParams,
  GridOptions,
  IAggFunc,
  IAggFuncParams,
  MenuItemDef,
  RowNode,
  StatusPanelDef,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { Observable } from 'rxjs';
import { UPDATE_ANCHOR_POINT } from 'src/app/core/reducers/actions';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ColorPickerToolbarComponent } from 'src/app/shared/color-picker-toolbar/color-picker-toolbar.component';
import { GridState } from './BaseList';
import { ListResponse } from './ListResponse';
import { endpoints } from './apiEndpoints';
import { newTabWithData } from './broadcastChannelHelpers';
import { MiscFeature } from './feature';
import { DynamicFormConstant } from './flex/forms/types';
import { fromBradyDate, isNonZeroEmpty, limitDecimalPlaces, numberWithCommas, openFlexForm } from './helperFunctions';
import { BookingStage, Currency, LogisticsQueueDataMenuItem, ShipmentFinderResult, SourceEntityType, SourceEntityTypeEntityNameMap, Unit, YN } from './newBackendTypes';
import { toLocalDate } from './toUTCDate';
import { ExternalLinkIcon, GoToIcon, ServiceOrderIcon, getIconClassFromEntityType, randomFetchSynonym } from './uiConstants';
import { EntityLayout, IDListColumn } from './views';
import { Store } from 'src/app/core/services/store.service';

/**
 * @factory Creates a callback function to be called by the ag-grid framework
 * @returns A comparator function for sorting dates.  The function converts both values to JS dates if possible.
 */
export function dateComparator() {
  return (valueA: Date | string | number | null, valueB: Date | string | number | null, rowA: RowNode, rowB: RowNode, inverted: boolean) => {
    if ((typeof valueA === 'number' && typeof valueB === 'number') || (typeof valueA === 'object' && typeof valueB === 'object')) {
      return (valueA as any) - (valueB as any);
    }
    if (typeof valueA === 'number') {
      try {
        valueA = fromBradyDate(valueA);
      } catch (e) {
        valueA = null;
      }
    }

    if (typeof valueB === 'number') {
      try {
        valueA = fromBradyDate(valueB);
      } catch (e) {
        valueA = null;
      }
    }

    if (!valueA && !valueB) return 0;
    if (!valueA) return 1;
    if (!valueB) return -1;

    if (typeof valueA !== 'object') valueA = new Date(valueA);
    if (typeof valueB !== 'object') valueB = new Date(valueB);

    return valueA?.getTime() - valueB?.getTime();
  };
}

/**
 * @factory Creates a callback function to be called by the ag-grid framework
 * @returns A comparator function for sorting dates.  The function converts both to valid dates if possible.
 */
export function dateGridComparator() {
  return (valueA: Date | string | number | null, valueB: Date | string | number | null, rowA: RowNode, rowB: RowNode, inverted: boolean) => {
    const firstVal = dateGetter(valueA);
    const secondVal = dateGetter(valueB);

    if (!firstVal && !secondVal) return 0;
    if (!firstVal) return 1;
    if (!secondVal) return -1;

    return firstVal?.getTime() - secondVal?.getTime();
  };
}

/**
 * @valueFormatter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param labels An array of { label: string, value: T } objects.  The label of the object with the matching value will be used for each row.
 * @returns A callback to show enum values as their respective label
 */
export function enumLabelFormatter<T>(labels: { label: string; value: T }[]) {
  return (params: ValueFormatterParams) => {
    let val: T | null = params.value;
    if (!val) return '';
    let matching = labels.find((l) => l.value == val);
    if (!!matching) return matching.label;
    return `${val}`;
  };
}

/**
 * @valueGetter
 * @getQuickFilter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param field The field/id of the enum column
 * @param labels  An array of { label: string, value: T } objects.  The label of the object with the matching value will be used for each row.
 * @returns A callback to replace enum values with their respective label
 */
export function enumValueGetter<T extends number>(field: string, labels: { label: string; value: T | string }[]) {
  return (params: ValueGetterParams | GetQuickFilterTextParams) => {
    if (!params.data) return;
    let val: T | null = params.data[field];
    if (!val && val !== 0) return '';

    let matching = labels.find((l) => l.value == val);
    if (!!matching) return matching.label;
    return `${val}`;
  };
}

/**
 * @valueFormatter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param labelKey the object property to display in the column
 * @returns A callback to display the given property in this column. Assumes the value is an object.
 */
export function simpleLabelGetter<T>(labelKey: keyof T) {
  return (params: ValueFormatterParams) => {
    let data: T | null = params.data;
    if (!data) return '';
    if (!data[labelKey]) {
      return params.value ? params.value : '';
    }
    return data[labelKey];
  };
}

/**
 * @valueGetter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param field The field of the date column
 * @returns A callback to replace the values in a column with JS dates.  For example: Legacy numeric dates or stringified dates
 */
export function gridDateGetter(field: string) {
  return (params: ValueGetterParams) => {
    if (params.node.group) return '';
    const value: Date | string | number = params.data[field] ?? params.node.data[field];
    return dateFormatter(value);
  };
}

/**
 *
 * @param value A date, either as a JS date, a stringified date, a legacy numeric date, or null
 * @returns If value is a valid date representation, returns a JS date.  If the input is not valid or is null, returns null.
 */
export function dateGetter(value: Date | string | number | null): Date | null {
  if (!value) return null;

  if (typeof value === 'object') return value;
  if (isStringifiedBradyDate(value)) {
    let date: Date;
    try {
      date = fromBradyDate(parseInt(value));
    } catch (e) {
      date = null;
    }
    return date;
  } else if (typeof value === 'string') {
    let date = new Date(value);
    if (isNaN(date.getTime())) {
      date = null;
    }
    return date;
  }
  if (typeof value === 'number') {
    try {
      let date = fromBradyDate(value);
      return date;
    } catch (e) {
      return null;
    }
  }
  return null;
}

/**
 * @valueFormatter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @returns A callback that formats dates from any type to display mm/DD/YY
 */
export function gridDateFormatter() {
  return (params: ValueFormatterParams) => {
    let value: Date | string | number = params.value;
    return dateFormatter(value);
  };
}

/**
 *
 * @param value A date value, either a JS date or another valid representation of a date.
 * @returns If the date is valid, returns the date as a string in mm/DD/yy format, otherwise returns null
 */
export function dateFormatter(value: Date | string | number): string {
  if (!value) return '';
  let date;
  if (typeof value === 'object') {
    date = value;
  } else if (isStringifiedBradyDate(value)) {
    try {
      date = fromBradyDate(parseInt(value));
    } catch (e) {
      return null;
    }
  } else if (typeof value === 'string') {
    try {
      date = new Date(value);
      if (isNaN(date.getTime())) {
        return value;
      }
    } catch (e) {
      return null;
    }
  } else if (typeof value === 'number') {
    try {
      date = fromBradyDate(value);
    } catch (e) {
      return null;
    }
  }
  if (!date) return null;
  return toLocalDate(date).toLocaleDateString();
}

/**
 * @comparator
 * @factory Creates a callback function to be called by the ag-grid framework
 * @returns A callback to sort numbers that have been stringified. All non-numeric characters are stripped from original strings.
 */
export function amountStringComparator() {
  return (valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted: boolean) => {
    let numberA, numberB: number;
    if (!valueA || !valueB) return 0;
    if (typeof valueA === 'string') {
      valueA = valueA.replace(/[^\\0-9.]/g, '');
      try {
        numberA = Number(valueA);
      } catch (e) {
        return 0;
      }
    } else {
      numberA = valueA;
    }
    if (typeof valueB === 'string') {
      valueB = valueB.replace(/[^\\0-9.]/g, '');
      try {
        numberB = Number(valueB);
      } catch (e) {
        return 0;
      }
    } else {
      numberB = valueB;
    }

    let result = numberA - numberB;
    return result;
  };
}

/**
 * Default ag-grid options.
 * @param delegate The delegate service so state can be accessed.
 * @param primaryKey the primary key of the data if applicable.  Rows will be indexed by this field
 * @param pagination If true, divides data into pages of size 50. Defaults to false
 * @returns GridOptions object with default settings.
 */
export function defaultComplexGrid(delegate: DelegateService, primaryKey?: string, pagination = false): Partial<GridOptions> {
  let body = document.querySelector('body');
  const user = delegate.store.snapshot((state) => state.user.user);
  return {
    rowSelection: 'multiple',
    enableGroupEdit: false,
    suppressRowDeselection: false,
    suppressRowDrag: true,
    groupIncludeFooter: true,
    suppressRowClickSelection: true,
    groupDefaultExpanded: 1,
    suppressAggFuncInHeader: true,
    groupMultiAutoColumn: false,
    groupIncludeTotalFooter: false,
    groupUseEntireRow: false,
    groupSuppressAutoColumn: false,
    groupHideOpenParents: false,
    suppressAggAtRootLevel: false,
    suppressDragLeaveHidesColumns: false,
    defaultCsvExportParams: {
      processCellCallback: ({ column, columnApi, api, value, node, context }) => {
        let colDef = column.getColDef();

        if (colDef && colDef.valueFormatter && typeof colDef.valueFormatter !== 'string') {
          return colDef.valueFormatter({
            columnApi,
            column,
            api,
            value,
            node,
            colDef,
            context,
            data: node.data,
          });
        } else {
          return value;
        }
      },
    },
    defaultExcelExportParams: {
      author: user?.displayName || 'Thalos',
      fileName: document.title,
      sheetName: document.title,
      shouldRowBeSkipped: (params) => {
        return !params.node.isSelected() && params.api.getSelectedNodes().length > 0;
      },
      processCellCallback: ({ column, columnApi, api, value, node, context }) => {
        let colDef = column.getColDef();

        if (colDef && colDef.valueFormatter && typeof colDef.valueFormatter !== 'string') {
          return colDef.valueFormatter({
            columnApi,
            column,
            api,
            value,
            node,
            colDef,
            context,
            data: node.data,
          });
        } else {
          return value;
        }
      },
    },
    autoGroupColumnDef: {
      cellStyle: { 'font-weight': 'bold' },
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      width: 125,
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      autoHeaderHeight: true,
    },
    //ensure that group rows are bold
    getRowStyle: (params) => {
      return params?.node?.group ? { 'font-weight': 'bold' } : {};
    },
    //group rows should be taller
    getRowHeight: (params) => {
      return params.node.group ? 45 : 25;
    },
    //getRowNodeId: (data) => data[primaryKey],
    onColumnRowGroupChanged: (params) => params.api.expandAll(),
    getRowId: !!primaryKey ? (params) => params.data?.[primaryKey] : undefined,
    pagination,
    statusBar: pagination ? undefined : defaultStatusBar(),
    paginationPageSize: 50,
    sideBar: {
      position: 'right',
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            // suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            // suppressSideButtons: true,
            // suppressColumnFilter: true,
            // suppressColumnSelectAll: true,
            // suppressColumnExpandAll: true,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
    },
    popupParent: body,
  };
}

/**
 * Default ag-grid options.
 * @param delegate The delegate service so state can be accessed.
 * @param primaryKey the primary key of the data if applicable.  Rows will be indexed by this field
 * @param pagination If true, divides data into pages of size 50. Defaults to false
 * @returns GridOptions object with default settings.
 */
export function defaultListComplexGrid(delegate: DelegateService): Partial<GridOptions> {
  const user = delegate.store.snapshot((state) => state.user.user);
  return {
    groupIncludeTotalFooter: true,
    suppressMakeColumnVisibleAfterUnGroup: false,
    groupSuppressBlankHeader: false,
    suppressRowClickSelection: false,
    getRowHeight: undefined,
    suppressCopyRowsToClipboard: false,
    maintainColumnOrder: true,
    suppressRowGroupHidesColumns: true,
    isRowSelectable: (node) => {
      return !node.group && !node.footer && !node.rowPinned;
    },
    sideBar: {
      position: 'right',
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'colors',
          labelDefault: 'Colors',
          labelKey: 'colors',
          iconKey: 'colorPicker',
          toolPanel: ColorPickerToolbarComponent,
        },
      ],
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      width: 125,
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      autoHeaderHeight: true,
    },
    autoGroupColumnDef: {
      width: 200,
      headerName: 'Group',
      sortable: true,
      field: 'ag-Grid-AutoColumn',
    },
    defaultExcelExportParams: {
      author: user.displayName || 'Thalos',
      shouldRowBeSkipped: (params) => {
        return !params.node.isSelected() && params.api.getSelectedNodes().length > 0;
      },
    },
  };
}

export function getDefaultMenuItems(): (params: GetContextMenuItemsParams) => (MenuItemDef | string)[] {
  return (params: GetContextMenuItemsParams) => ['copy', 'copyWithHeaders', copyCell()(params), 'paste', 'separator', 'autoSizeAll', 'contractAll', 'resetColumns'];
}

/**
 * Copy cell value.
 *
 * @returns The menu item.
 */
export function copyCell() {
  return (params: GetContextMenuItemsParams): MenuItemDef => {
    if (!params.value) return;
    const colDef = params.column.getColDef();
    if (!colDef) return;
    const propertyRendered = colDef.cellEditorParams?.propertyRendered;

    const value = typeof params.value === 'object' ? params.value[propertyRendered] : params.value;
    return {
      name: 'Copy Cell',
      icon: '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>',
      action: () => {
        navigator.clipboard.writeText(value);
      },
    };
  };
}

/**
 *
 * @param field The property field in the data object for the grid.
 * @param headerName Name to display in header.  Optional, defaults to field name
 * @returns The column definition with grouping enabled.
 */
export function groupColumn(field: string, headerName?: string): ColDef {
  return { field, headerName, enableRowGroup: true, rowGroup: false, showRowGroup: false };
}

/**
 *
 * @param field The property field for the date column.
 * @param headerName Name to display in header.  Optional, defaults to field name
 * @returns The column definition with date filtering and formatting.
 */
export function dateColumn(field: string, headerName?: string): ColDef {
  return {
    field,
    headerName,
    filter: 'agDateColumnFilter',
    filterValueGetter: gridDateGetter(field),
    valueFormatter: gridDateFormatter(),
    cellStyle: { 'text-align': 'right' },
    width: 125,
    comparator: dateGridComparator(),
    filterParams: {
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split('/');
        var cellDate = new Date(`${dateParts[2]}/${dateParts[0]}/${dateParts[1]}`);
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  };
}

/**
 *
 * @param allowSelection A callback function that returns true when the row can be selected.
 * @param headerCheckboxSelection
 * @returns
 */
export function selectionColumn(allowSelection?: (params: CheckboxSelectionCallbackParams) => boolean, headerCheckboxSelection: boolean = false): ColDef {
  return {
    //we don't render the checkbox in group rows
    colId: 'checkbox',
    headerName: '',
    checkboxSelection: (params) => !params?.node?.group,
    headerCheckboxSelection,
    headerCheckboxSelectionFilteredOnly: true,
    width: 50,
    cellStyle: (params) => {
      let column = params.columnApi.getColumn(params.colDef);
      return !allowSelection || allowSelection({ ...params, column }) ? null : { 'pointer-events': 'none' };
    },
    suppressColumnsToolPanel: true,
  };
}

/**
 * @comparator
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param field The property of the row to sort by
 * @returns A basic comparison callback that will sort by the given field, not the value of the column this is assigned to.
 */
export function basicGridComparator(field: string) {
  return (valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted: boolean): number => {
    if (!nodeA && !nodeB) return 0;
    if (!nodeA) return -1;
    if (!nodeB) return 1;

    let dataA = nodeA[field];
    let dataB = nodeB[field];
    if (!dataA && !dataB) return 0;
    if (!dataA) return -1;
    if (!dataB) return 1;

    return dataA - dataB;
  };
}

/**
 * @valueFormatter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @returns A callback to join elements in an array, separating by newline.
 */
export function arrayJoinFormatter() {
  return (params: ValueFormatterParams) => {
    let value = params.value;
    if (!value || !Array.isArray(value)) return value;

    return value.join('\n');
  };
}

/**
 * @valueFormatter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param mapper Maps each object element in an array to a displayable value
 * @returns A callback that runs a map function on the array, then joins the elements, separating by newline
 */
export function arrayJoinObjectFormatter(mapper: (obj: any) => string | number) {
  return (params: ValueFormatterParams) => {
    let value = params.value;
    if (!value || !Array.isArray(value)) return value;

    return value.map(mapper).join('\n');
  };
}

/**
 * @valueFormatter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @returns Maps a string of comma seperated values to their own lines.
 */
export function commaJoinFormatter() {
  return (params: ValueFormatterParams) => {
    let value = params.value;
    if (!value || typeof value !== 'string') return value;

    return value
      .split(/,/)
      .map((s) => s.trim())
      .join('\n');
  };
}

/**
 * @valueFormatter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param minDecimals The minimum number of decimals
 * @param maxDecimals The maximum number of decimals
 * @param seperator If Y, thousand comma seperators will be added.
 * @returns A callback function to format numbers with the given parameters.
 */
export function basicNumberFormatter(minDecimals: number, maxDecimals: number, seperator: YN | string) {
  return (params: ValueFormatterParams): string => {
    let n: number = params.value;
    if (n === undefined || n === null) return '';
    if (typeof n !== 'number') {
      if (maxDecimals > 0) {
        n = parseFloat(n);
      } else {
        n = parseInt(n);
      }
      if (isNaN(n)) return params.value;
    }

    let formatted = minDecimals === maxDecimals ? n.toFixed(maxDecimals) : limitDecimalPlaces(n, maxDecimals);
    if (seperator === YN.Y) {
      return numberWithCommas(formatted);
    } else return formatted.toString();
  };
}

/**
 * @valueFormatter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param seperator If Y, thousand comma seperators will be added.
 * @returns A callback function to format metal units percentage with the given parameters.
 */
export function metalUnitPercentageFormatter(seperator: YN = YN.Y) {
  return (params: ValueFormatterParams): string => {
    const n: number = parseFloat(params.value);

    if (isNaN(n)) return params.value;

    const formatted = n.toFixed(3);
    if (seperator === YN.Y) return `${numberWithCommas(formatted)}%`;
    else return `${formatted.toString()}%`;
  };
}

/**
 * Displays numbers as percentages, multiplying by 100.
 *
 * For example, 0.05 will be displayed as 5%, 5 will be displayed as 500%
 *
 * @valueFormatter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param minDecimals The minimum number of decimals. Defaults to 0
 * @param maxDecimals The maximum number of decimals. Defaults to 2
 * @param seperator If Y, adds a thousand comma seperator, defaults to N
 * @returns  A callback function to format numbers as a percentage with the given parameters
 */
export function percentFormatter(minDecimals: number = 0, maxDecimals: number = 2, seperator: YN | string = YN.N) {
  return (params: ValueFormatterParams): string => {
    let n: number = params.value;
    if (isNonZeroEmpty(n)) return '';
    if (typeof n !== 'number') {
      if (maxDecimals > 0) {
        n = parseFloat(n);
      } else {
        n = parseInt(n);
      }
      if (isNaN(n)) return params.value;
    }
    return `${basicNumberFormatter(minDecimals, maxDecimals, seperator)({ ...params, value: n * 100 })}%`;
  };
}

/**
 * @aggFunc
 * @factory Creates a callback function to be called by the ag-grid framework
 * @returns An aggregate callback to return the number of unique values in the column.
 */
export function uniqueValuesAggregator(): IAggFunc {
  return (params: IAggFuncParams) => {
    let set = new Set(
      (params.values || []).flatMap((v) => {
        if (!v) return [];
        if (typeof v === 'object' && 'toString' in v) return v.toString();
        if (typeof v === 'number') return v.toString();
        return v;
      })
    );

    return set.size;
  };
}

/**
 * @cellDoubleClicked
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param idField The field of the primary key of the entity
 * @param path The path to the destination minus the id param
 * @param router The Angular router
 * @param permissionCallback A callback function that, if returning false, disables double click navigation
 * @param dataCallback A void callback for running additional logic before navigating.
 * @returns The callback to navigate to the given path when a row is double clicked.
 */
export function entityLinkDoubleClick(idField: string, path: string, router: Router, permissionCallback?: () => boolean, dataCallback?: (event: CellDoubleClickedEvent) => void) {
  //preserve this from original component
  return function (event: CellDoubleClickedEvent) {
    if (permissionCallback) {
      let permission = permissionCallback();
      if (!permission) return;
    }

    if (event.data && event.data[idField]) {
      if (dataCallback) {
        dataCallback(event);
      }
      if (event.event['metaKey'] || event.event['ctrlKey']) {
        window.open(path + '/' + event.data[idField]);
      } else {
        router.navigate([path, event.data[idField]]);
      }
    }
  };
}

type subMenuParam = {
  name: string;
  menuItems: (MenuItemDef | ContextMenuGetter | subMenuParam)[];
  iconClass?: string;
};
function isSubMenuParam(sub): sub is subMenuParam {
  return typeof (sub as any)?.name === 'string' && Array.isArray((sub as any).menuItems);
}

export type getContextMenuOptions = {
  replaceDefault?: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
};
type MenuItemArg = MenuItemDef | ContextMenuGetter | subMenuParam | 'separator';
function isMenuItemArg(obj: MenuItemArg | getContextMenuOptions): obj is MenuItemArg {
  return obj && (typeof obj === 'string' || typeof obj === 'function' || 'name' in obj);
}
// export function getContextMenuItems(...items: MenuItemArg[])
/**
 * Allows itemization of context menu items rather than creating one function for the entire menu for each grid.
 *
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param optionsOrFirst Optional. A set of parameters for the menu.
 * @param _items A list of Menu Items or Menu Item factories
 * @returns A context menu callback for ag-grid with all of the individual items included.
 */
export function getContextMenuItems(optionsOrFirst?: getContextMenuOptions | MenuItemArg, ..._items: MenuItemArg[]) {
  const options: getContextMenuOptions = isMenuItemArg(optionsOrFirst) ? {} : optionsOrFirst;
  const items = isMenuItemArg(optionsOrFirst) ? [optionsOrFirst, ..._items] : [..._items];

  return (params: GetContextMenuItemsParams) => {
    const defaultItems = getDefaultMenuItems()(params) || params.defaultItems || [];

    const menu: (string | MenuItemDef)[] = options && options.replaceDefault ? options.replaceDefault(params) : defaultItems;

    const menuItems = menuFromSubMenu(params, items);
    if (menuItems.length > 0) menuItems.forEach((m) => menu.push(m));

    if (!menu.some((v) => v !== 'separator')) return [{ name: 'No actions available' }];

    return menu;
  };
}

/**
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param items A list of Menu Items or Menu Item factories
 * @returns A main menu callback for ag-grid with all of the individual items included.
 */
export function getMainMenuItems(...items: (MenuItemDef | MainMenuGetter | subMenuParam)[]) {
  return (params: GetMainMenuItemsParams) => {
    const menu: (string | MenuItemDef)[] = params.defaultItems ?? [];

    let menuItems = menuFromSubMenu(params, items);
    if (menuItems.length > 0) {
      menuItems.forEach((m) => menu.push(m));
    }

    return menu;
  };
}

type getterType<T extends GetContextMenuItemsParams | GetMainMenuItemsParams> = (T) => MenuItemDef | MenuItemDef[] | null;

/**
 * This function is called recursively by getContextMenuItems and getMainMenuItems.  You do not need to call it directly.
 * @private
 * @param params The ag-grid params
 * @param items A list of menu items or menu item factories
 * @returns A sub menu containing the given itemss.
 */
function menuFromSubMenu<T extends GetContextMenuItemsParams | GetMainMenuItemsParams, g extends getterType<T>>(
  params: T,
  items: (MenuItemDef | g | subMenuParam | 'separator')[]
): (MenuItemDef | 'separator')[] {
  return items.flatMap((item) => {
    if (item === 'separator') return item;
    if (isGetter<g>(item)) {
      let menuItem = item(params);
      if (menuItem !== null) {
        return menuItem;
      }
      return [];
    } else if (isSubMenuParam(item)) {
      let subMenu = menuFromSubMenu(params, item.menuItems).filter((i) => !!i);
      let icon: string;
      if (item.iconClass) {
        icon = `<i class="${item.iconClass}" style="font-size: 13px; padding-left: 2px;"></i>`;
      }
      if (subMenu.length > 0) {
        return {
          icon,
          name: item.name,
          subMenu,
        };
      }
      return [];
    } else {
      return item;
    }
  });
}

export type ContextMenuGetter = (params: GetContextMenuItemsParams) => MenuItemDef | MenuItemDef[] | null;
export type MainMenuGetter = (params: GetMainMenuItemsParams) => MenuItemDef | MenuItemDef[] | null;

type genericAccessor = (data: any) => number | string;
function isGetter<g extends ContextMenuGetter | MainMenuGetter>(arg: MenuItemDef | g | subMenuParam): arg is g {
  return typeof arg === 'function';
}

/**
 * A Go To submenu
 * @param items The contents of the submenu.  Menu Items or menu item factories.
 * @returns The items in a Sub Menu with the name 'Go To' an the go to icon.  To be used with getContextMenuItems.
 */
export function gotoMenu(...items: (subMenuParam | MenuItemDef | ContextMenuGetter)[]): subMenuParam {
  return {
    menuItems: items,
    name: 'Go To',
    iconClass: GoToIcon,
  };
}

/**
 * Creates a menu item that navigates to the desired entity. For example, Go to Booking.
 *
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param delegate The delegate service to access routing and state.
 * @param title The title of the item.  This will be displayed to the user.
 * @param accessor Either the property name or a getter function to get the primary key of the target entity.
 * @param entity The SourceEntityType
 * @param operation Which entity location to navigate to.  Defaults to get.
 * @param labelAccessor Either the property name or a getter function to get the display name of the target entity.
 * @param persistData If true, stores the existing data set in the state for arrow navigation.  Defaults to true.
 * @param iconClass The css class name of the desired icon.
 * @returns An ag-grid callback to return the desired menu item.
 */
export function gotoMenuItem(
  delegate: DelegateService,
  title: string,
  accessor: string | genericAccessor,
  entity: SourceEntityType,
  operation: 'get' | 'list' = 'get',
  labelAccessor?: string | genericAccessor,
  persistData: boolean = true,
  iconClass?: string
) {
  return (params: GetContextMenuItemsParams): MenuItemDef | null => {
    let data = params?.node?.data;
    if (!data || typeof data !== 'object') return null;
    let id: number | string;
    if (accessor) {
      if (typeof accessor === 'string') {
        if (!data[accessor]) return null;
        id = data[accessor];
      } else {
        if (!accessor(data)) return null;
        id = accessor(data);
      }
    }
    if (!id || (typeof id !== 'string' && typeof id !== 'number')) return null;

    //Will try to find alternate solution/refactor, but this is too avoid circular reference in FlexView
    const router = delegate.getService('router');
    const lookup = delegate.getService('entityLookup');

    let exists = lookup.entityPathExists(operation, entity);
    let link = exists ? lookup.getLink(entity, operation) : null;

    let name = title;
    if (labelAccessor) {
      let identifier;
      if (typeof labelAccessor === 'string') {
        if (!data[labelAccessor]) return null;
        identifier = data[labelAccessor];
      } else {
        if (!labelAccessor(data)) return null;
        identifier = labelAccessor(data);
      }
      if (!!identifier) {
        name += ` ${identifier}`;
      }
    }

    if (exists) {
      let icon: string;
      if (iconClass === undefined) {
        let iconCode: string = getIconClassFromEntityType(entity);
        if (iconCode) {
          icon = `<i class="${iconCode}" style="font-size: 13px; padding-left: 2px;"></i>`;
        }
      } else {
        icon = `<i class="${iconClass}" style="font-size: 13px; padding-left: 2px;"></i>`;
      }

      let persistDataCallback = () => {
        if (!persistData) return;
        let data: any[] = [];
        let map: { [key: number]: true } = {};
        params.api.forEachNodeAfterFilterAndSort((n) => {
          let id: number | string;
          let label: string;
          let row = n.data;
          if (!row) return;
          if (typeof accessor === 'string') {
            id = row[accessor];
          } else {
            id = accessor(row);
          }
          if (!id || typeof id !== 'number') return;

          if (labelAccessor) {
            if (typeof labelAccessor === 'string') {
              label = row[labelAccessor];
            } else {
              label = `${labelAccessor(row)}`;
            }
          }
          if (!label) label = `${id}`;

          if (!map[id]) {
            map[id] = true;
            data.push({ id, label });
          }
        });
        return data;
      };

      return {
        name,
        icon,
        action: () => {
          const data = persistDataCallback();
          if (data) {
            const store = delegate.getService('store');
            store.dispatch({
              type: UPDATE_ANCHOR_POINT,
              payload: {
                dataLabelAccessor: 'label',
                dataSourceEntityType: entity,
                dataAccessor: 'id',
                data,
              },
            });
          }
          router.navigate([link, id]);
        },
        subMenu: [
          {
            icon: `<i class="${ExternalLinkIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
            name: 'New Tab',
            action: () => {
              const data = persistDataCallback();
              newTabWithData(`${link}/${id}`, {
                url: `${link}/${id}`,
                state: {
                  dataLabelAccessor: 'label',
                  dataSourceEntityType: entity,
                  dataAccessor: 'id',
                  data,
                },
              });
            },
          },
        ],
      };
    }
    return null;
  };
}

/**
 * Creates a menu item that creates a new service order from the selected shipment ids.
 *
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param delegate The delegate service to access routing and state.
 * @param title The title of the menu item
 * @param accessor Either the property name or a getter function to return the row's shipment id.
 * @returns A callback function for the desired menu item.
 */
export function getServiceOrderMenuItem(delegate: DelegateService, title: string, accessor: string | genericAccessor) {
  let api = delegate.api;
  let lookup = delegate.entityLookup;
  let spinner = delegate.spinner;
  return (params: GetContextMenuItemsParams) => {
    let gotoItems: MenuItemDef[] = [];

    let selectedRows = params.api
      .getSelectedNodes()
      .map((n) => n.data)
      .filter((d) => !!d);
    let shipmentIds = [
      ...selectedRows.map((r) => {
        return typeof accessor === 'string' ? r[accessor] : accessor(r);
      }),
    ].filter((s) => !!s);
    let currentRow = typeof accessor === 'string' ? params.node?.data?.[accessor] : accessor(params.node?.data);
    if (shipmentIds.length > 0) {
      gotoItems.push({
        icon: `<i class="${getIconClassFromEntityType(SourceEntityType.SERVICE_ORDER_KEY)}" style="font-size: 13px; padding-left: 2px;"></i>`,
        name: `New Service Order from ${title} (${shipmentIds.join(', ')})`,
        action: () => {
          let rid = spinner.startRequest(randomFetchSynonym() + ' Shipments');
          api.rpc<ListResponse<ShipmentFinderResult>>(endpoints.shipmentLookup, { filters: { shipmentId: shipmentIds } }, { list: [], count: 0 }).subscribe((res) => {
            spinner.completeRequest(rid);
            if (res.list && res.list.length > 0) {
              lookup.gotoEntity(SourceEntityType.SERVICE_ORDER_KEY, null, 'create', {
                state: { 'service-order-shipments': res.list },
              });
            }
          });
        },
      });
    } else if (currentRow) {
      let shipmentIds = [currentRow];
      gotoItems.push({
        icon: `<i class="${getIconClassFromEntityType(SourceEntityType.SERVICE_ORDER_KEY)}" style="font-size: 13px; padding-left: 2px;"></i>`,
        name: `New Service Order from ${title} (${currentRow})`,
        action: () => {
          let rid = spinner.startRequest(randomFetchSynonym() + ' Shipments');
          api.rpc<ListResponse<ShipmentFinderResult>>(endpoints.shipmentLookup, { filters: { shipmentId: shipmentIds } }, { list: [], count: 0 }).subscribe((res) => {
            spinner.completeRequest(rid);
            if (res.list && res.list.length > 0) {
              lookup.gotoEntity(SourceEntityType.SERVICE_ORDER_KEY, null, 'create', {
                state: { 'service-order-shipments': res.list },
              });
            }
          });
        },
      });
    }
    return gotoItems;
  };
}

/**
 * Creates a menu item that allow to navigate metal/logistic booking from the selected booking.
 *
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param delegate The delegate service to access routing and state.
 * @param title The title of the menu item
 * @param accessor Either the property name or a getter function to return the row's shipment id.
 * @param state Layout state.
 * @returns A callback function for the desired menu item.
 */
export function getBookingMenuItem(delegate: DelegateService, title: string, accessor: string | genericAccessor, state: GridState & { layout?: EntityLayout }) {
  const router = delegate.getService('router');
  const lookup = delegate.getService('entityLookup');
  return (params: GetContextMenuItemsParams): MenuItemDef | null => {
    let data = params?.node?.data;
    if (!data) return null;
    let booking: LogisticsQueueDataMenuItem = params.node.data;

    let exists = booking.stage === BookingStage.STB ? lookup.featureExists(MiscFeature.GET_ARRANGED_BOOKING) : lookup.entityPathExists('get', SourceEntityType.FREIGHT_BOOKING_KEY);
    let link = exists ? (booking.stage === BookingStage.STB ? lookup.getFeatureRoute(MiscFeature.GET_ARRANGED_BOOKING)?.link : lookup.getLink(SourceEntityType.FREIGHT_BOOKING_KEY, 'get')) : null;

    const dataCallback = () => {
      let data: any[] = [];
      let map: { [key: number]: true } = {};
      params.api.forEachNodeAfterFilterAndSort((n) => {
        if (!n.data) return;
        let id: number = n.data.id;
        let label: string = n.data.number;

        if (!id) return;
        if (!label) label = `${id}`;

        if (!map[id]) {
          map[id] = true;
          data.push({ id, label });
        }
      });
      return data;
    };

    let name = title;
    if (accessor) {
      let identifier;
      if (typeof accessor === 'string') {
        if (!data[accessor]) return null;
        identifier = data[accessor];
      } else {
        if (!accessor(data)) return null;
        identifier = accessor(data);
      }
      if (!!identifier) {
        name += ` ${identifier}`;
      }
    }

    if (exists) {
      const data = dataCallback();
      return {
        name: `${name}`,
        icon: `<i class="${getIconClassFromEntityType(SourceEntityType.FREIGHT_BOOKING_KEY)}" style="font-size: 13px; padding-left: 2px;"></i>`,
        action: () => {
          const store = delegate.getService('store');
          store.dispatch({
            type: UPDATE_ANCHOR_POINT,
            payload: {
              dataLabelAccessor: 'label',
              dataSourceEntityType: SourceEntityType.FREIGHT_BOOKING_KEY,
              dataAccessor: 'id',
              data,
            },
          });
          router.navigate([link, booking.bookingId ?? booking.id]);
        },
        subMenu: [
          {
            name: 'New Tab',
            icon: `<i class="${ExternalLinkIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
            action: () => {
              newTabWithData(`${link}/${booking.bookingId ?? booking.id}`, {
                url: router.url,
                state: {
                  ...state,
                  dataLabelAccessor: 'label',
                  dataSourceEntityType: SourceEntityType.FREIGHT_BOOKING_KEY,
                  dataAccessor: 'id',
                  data,
                },
              });
            },
          },
        ],
      };
    }
    return null;
  };
}

export function getServiceOrderListMenuItem(delegate: DelegateService) {
  const lookup = delegate.getService('entityLookup');
  const router = delegate.getService('router');
  return (params: GetContextMenuItemsParams): MenuItemDef | MenuItemDef[] => {
    const data = params?.node?.data;
    if (!data) return null;
    if (!data.hasServiceOrder) return null;
    const existsServiceOrder = lookup.entityPathExists('list', SourceEntityType.SERVICE_ORDER_KEY);
    const linkServiceOrder = existsServiceOrder ? lookup.getLink(SourceEntityType.SERVICE_ORDER_KEY, 'list') : null;
    const menu: MenuItemDef[] = [];

    if (data.hasServiceOrder === YN.Y) {
      menu.push({
        name: `Service Orders List ${data.voucherNumber}`,
        icon: `<i class="${ServiceOrderIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
        action: () => {
          localStorage.setItem('voucherKey', JSON.stringify(data.invoiceId));
          localStorage.setItem('counterpartyId', JSON.stringify(data.counterpartyId));
          router.navigate([`${linkServiceOrder}`]);
        },
        subMenu: [
          {
            name: 'New Tab',
            icon: `<i class="${ExternalLinkIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
            action: () => {
              localStorage.setItem('voucherKey', JSON.stringify(data.invoiceId));
              localStorage.setItem('counterpartyId', JSON.stringify(data.counterpartyId));
              window.open(`${linkServiceOrder}`);
            },
          },
        ],
      });
    }
    return menu;
  };
}

/**
 * Replaces 0 values with an empty string.
 *
 * @valueFormatter
 * @factory Creates a callback function to be called by the ag-grid framework
 * @returns The callback function
 */
export function hideZeroValues() {
  return (params: ValueFormatterParams) => {
    if (params.value === 0 || params.value === '0') return '';
    return params.value;
  };
}

/**
 * Creates a ag-grid column preset for generic quantity amounts
 *
 * @param field The property field
 * @param headerName The display name for the header.  Defaults to the field name.
 * @param decimals Number of decimals to display, defaults to 2.
 * @returns The partial column def.
 */
export function basicAmountColumn(field: string, headerName?: string, decimals: number = 2): ColDef {
  return {
    field,
    headerName,
    valueFormatter: basicNumberFormatter(decimals, decimals, YN.Y),
    ...quantityColumn(),
  };
}

/**
 * Creates a ag-grid column preset for currency amounts
 *
 * @param formatter The formatting service to access currency data.
 * @param currencyField The field of the currency.
 * @param field The field of the amount.
 * @param headerName Header name.  Defaults to field name.
 * @returns The partial column def.
 */
export function amountCurrencyColumn(formatter: DataFormattingService, currencyField: string, field: string, headerName?: string): ColDef {
  return {
    field,
    headerName,
    valueFormatter: formatter.gridCurrencyFormatter(currencyField),
    ...quantityColumn(),
  };
}

/**
 * Creates a ag-grid column preset for currency amounts with a static currency.
 *
 * @param formatter The formatting service to access currency data.
 * @param staticCurrency The currency either as the currencyId, currencyCode or Currency object.
 * @param field The field of the amount.
 * @param headerName Header name.  Defaults to field name.
 * @returns The partial column def.
 */
export function staticCurrencyColumn(formatter: DataFormattingService, staticCurrency: string | Currency | number, field: string, showUnit: YN | string = YN.Y, headerName?: string): ColDef {
  return {
    field,
    headerName,
    valueFormatter: formatter.gridStaticCurrencyFormatter(staticCurrency, undefined, YN.Y, showUnit),
    ...quantityColumn(),
  };
}

/**
 * Creates a ag-grid column preset for weight amounts
 *
 * @param formatter The formatting service to access unit data.
 * @param unitField The field of the unit.
 * @param field The field of the amount.
 * @param headerName Header name.  Defaults to field name.
 * @returns The partial column def.
 */
export function quantityUnitColumn(formatter: DataFormattingService, unitField: string, field: string, headerName?: string): ColDef {
  return {
    field,
    headerName,
    valueFormatter: formatter.gridUnitFormatter(unitField),
    ...quantityColumn(),
  };
}

/**
 * Creates a ag-grid column preset for price per unit amounts
 *
 * @param formatter The formatting service to access unit data.
 * @param unitField The field of the unit.
 * @param currencyField The field of the currency.
 * @param field The field of the amount.
 * @param headerName Header name.  Defaults to field name.
 * @param decimalPlaces Number of decimal places to display. Defaults to 2
 * @param separator If Y, adds thousand seperators.  Defaults to Y
 * @returns The partial column definition.
 */
export function priceColumn(formatter: DataFormattingService, unitField: string, currencyField: string, field: string, headerName?: string, decimalPlaces: number = 4, separator: YN = YN.Y): ColDef {
  return {
    field,
    headerName,
    valueFormatter: formatter.gridAmountCurrencyPerUnitFormatter(unitField, currencyField, decimalPlaces, separator),
    ...quantityColumn(),
  };
}

/**
 * Creates a ag-grid column preset for currency amounts with a static unit.
 *
 * @param formatter The formatting service to access unit data.
 * @param staticUnit The unit either as the unitId, unitCode or Unit object.
 * @param field The field of the amount.
 * @param headerName Header name.  Defaults to field name.
 * @returns The partial column def.
 */
export function staticUnitColumn(formatter: DataFormattingService, staticUnit: string | Unit | number, field: string, showUnit: YN | string = YN.Y, headerName?: string): ColDef {
  return {
    field,
    headerName,
    valueFormatter: formatter.gridStaticUnitFormatter(staticUnit, undefined, YN.Y, showUnit),
    ...quantityColumn(),
  };
}

/**
 * @returns A partial column def for quantity stylings.
 */
export function quantityColumn(): ColDef {
  return {
    cellStyle: { 'text-align': 'right' },
    filter: 'agNumberColumnFilter',
    comparator: (a, b, nodeA, nodeB) => {
      let amountA;
      let amountB;
      if (typeof a === 'object' && typeof a.amount === 'number') {
        amountA = a.amount;
      } else {
        amountA = a;
      }
      if (typeof b === 'object' && typeof b.amount === 'number') {
        amountB = b.amount;
      } else {
        amountB = b;
      }
      return amountA - amountB;
    },
  };
}

/**
 * @typeguard
 * @param test Any value
 * @returns True if the value is a legacy numeric date that has been stringified.
 */
export function isStringifiedBradyDate(test: any): test is string {
  return !!test && typeof test === 'string' && /^[0-9]{4}[0-2][0-9][0-3][0-9]$/.test(test) && !isNaN(parseInt(test));
}

/**
 * @returns A default status panel configuration for ag-grid
 */
export function defaultStatusBar(): { statusPanels: StatusPanelDef[] } {
  return {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agSelectedRowCountComponent',
        align: 'left',
      },
    ],
  };
}

/**
 * Returns a static menu item from a Flex View menu preset.  Allows generic flex features to be hardcoded into other pages without code duplication.
 *
 * @factory Creates a callback function to be called by the ag-grid framework
 * @param delegate The delegate service.
 * @param preset The Flex View feature preset.
 * @param field The field of the entity id the preset is tied to.
 * @param labelField The name of the entity if applicable.
 * @param callback An optional callback function that runs after the form is closed.  Used to add additional behavior, like reloading the page.
 * @param prefillOverride An optional callback function that overrides the preset form prefill.  Useful if the given context already has the desired data so it doesn't need to be fetched.
 * @param column The Flex Column so Source Entity Type and other data can be accessed.
 * @returns The menu item callback.
 */
export function dynamicFormItem<T, R = T>(
  delegate: DelegateService,
  preset: DynamicFormConstant<T, R>,
  field: string,
  labelField?: string,
  callback?: (result: any, params: GetContextMenuItemsParams) => void,
  prefillOverride?: (params: GetContextMenuItemsParams) => Observable<Partial<T>> | Partial<T>,
  column?: IDListColumn,
  filters?: QueryFilters<T>,
  useClickedRow?: boolean
): ContextMenuGetter {
  return (params: GetContextMenuItemsParams) => {
    const store = delegate.getService('store');
    const permissions = store.snapshot((state) => state.user.userEndpoints);
    if (!!preset.endpoints && !preset.endpoints.every((e) => permissions.includes(e))) {
      return [];
    }

    let selectedRows = params.api.getSelectedRows();
    if (selectedRows && selectedRows.length < 1) {
      selectedRows.push(params.node.data);
    }
    if (useClickedRow) selectedRows = [params.node.data];

    if (filters) {
      for (let [field, value] of Object.entries(filters)) {
        let notOperator = false;
        if (typeof value === 'object' && value !== null && 'not' in value) {
          notOperator = true;
          value = value.not;
        }
        if (Array.isArray(value) && value.length > 0) {
          for (const row of selectedRows) {
            const found = value.some((val) => val === row[field]);
            if (found && notOperator) return []; // Dont show it because we didnt find it and we wanted to see it
            if (!found && !notOperator) return []; // Dont show it because we found it but we did not want to see it
          }
        } else {
          const found = selectedRows.every((item) => {
            return item[field] === value;
          });
          if (!found && !notOperator) {
            return [];
          } // Dont show it because we didnt find it and we wanted to see it
          if (selectedRows.some((item) => item[field] === value) && notOperator) {
            return [];
          } // Dont show it because we found it but we did not want to see it
        }
      }
    }

    let id;
    let label;

    if (preset.allowMultipleRows) {
      if (selectedRows.length > 0) {
        id = selectedRows.flatMap((row) => (row ? row[field] : [])).filter((item) => item !== null);
        if (id.length === 0) return;
        let labels = selectedRows.flatMap((sr) => (labelField ? sr[labelField] : sr[field] ?? []));
        label = labels.length ? `Selected: ${labels.join(', ')}` : 'Selected Rows';
      }
    }

    if (!id) {
      if (!params.node?.data) return [];

      id = params.node.data[field];
      if (!id) return [];
      label = params.node.data[labelField];
      if (!label) label = `${id}`;
    }

    if (preset.entityType === null) {
      if (!column) return [];
      label = `${SourceEntityTypeEntityNameMap[column.typeConfiguration.entityType]} ${label}`;
    } else {
      label = `- ${SourceEntityTypeEntityNameMap[preset.entityType]} ${label}`;
    }

    const formTitle = `${preset.title || preset.label} ${label}`;

    return [
      {
        name: formTitle,
        action: () => openFlexForm(delegate, preset, id, callback, prefillOverride, column, params, selectedRows),
      },
    ];
  };
}

export function exportToExcel(store: Store): ContextMenuGetter {
  const user = store.snapshot((state) => state.user.user);
  return (params: GetContextMenuItemsParams) => {
    if (!params.node?.data) return [];
    return {
      name: 'Excel Export',
      icon: '<span class="ag-icon ag-icon-excel"></span>',
      action: () => {
        params.api.exportDataAsExcel({
          author: user?.displayName || 'Thalos',
          fileName: document.title,
          sheetName: document.title,
          processCellCallback: function (cell) {
            const colDef = cell.column.getColDef();
            if (!colDef) return;
            const propertyRendered = colDef.cellEditorParams?.propertyRendered;
            if (!cell.value) return;
            return typeof cell.value === 'object' ? cell.value[propertyRendered] : cell.value;
          },
        });
      },
    };
  };
}

/**
 * A value formatter callback that replaces blank values with a given message.
 *
 * @valueFormatter
 * @param text The replacement text.  Defaults to NA
 * @returns The callback function.
 */
export function warnIfBlank(text: string = 'N/A') {
  return (params: ValueFormatterParams) => params.value ?? text;
}

/**
 * A cell class callback that styles a cell if it is empty.
 *
 * @param style The cell style object.
 * @returns The callback function.
 */
export function styleIfBlank(style: CellStyle): CellStyleFunc {
  return (params: CellClassParams) => (params?.value ? {} : style);
}

export type QueryFilters<T> = {
  [key in keyof T]?: any;
} & { [key: string]: any };
