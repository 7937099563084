import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ModalFormComponent, SelectorApi } from 'src/app/core/services/selector-popup.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { MarketDateResult } from 'src/app/shared/market-valuation-date-selector/market-valuation-date-selector.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { counterpartyDropdown, paymentTermDropdown } from 'src/lib/commonTypes';
import { bradyDateValidator, materialInvoiceDateValidator } from 'src/lib/genericValidators';
import { Subset } from 'src/lib/generics';
import { fromBradyDate, fromBradyDateOrNull, getCompanyByDefaultAuthorizedCompany, markFormGroupTouched } from 'src/lib/helperFunctions';
import {
  CurrencyConversion,
  InvoiceHeader,
  MarketValuationHeader,
  NumberOfDecimals,
  OpenItem,
  PaymentTerm,
  PrecisionDecimals,
  ShipmentInformationForClientInvoice,
  Unit,
  UnitInfo,
  YN,
} from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';
import { ClientInvoicePaymentsCreditsComponent } from './client-invoice-payments-credits/client-invoice-payments-credits.component';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';

type ClientInvoiceHeaderForm = Pick<InvoiceHeader, 'counterparty' | 'number' | 'invoiceDate' | 'dueDate'> & {
  numberOfDecimals: PrecisionDecimals;
  paymentTerm?: PaymentTerm;
  contractNumber: number;
};

export type ClientInvoiceForm = ClientInvoiceHeaderForm & {
  lines: ClientInvoiceLineForm[];
  paymentsCreditsLines?: ClientInvoicePaymentCreditLineForm[];
  oneShipmentPerInvoice: YN;
};

type ProvisionalClientInvoiceLineForm = {
  shipmentId: number;
  type: 'Provisional';
  productId: number;
  quantity: number;
  quantityUnitId: number;
  formula: string;
  materialPrice: number;
  linePrice: number;
  lineTotal: number;
  priceUnitId: number;
  priceCurrencyId: number;
  collateralPercentage: number | null;
  collateralAmount: number;
  marketDate: MarketDateResult;
  marketValuation: MarketValuationHeader;
  marketPrice: number;
  marketUnitId: number;
  marketCurrencyId: number;
  marketSpread: number | null;
  marketSpreadCurrencyId: number | null;
  marketSpreadUnitId: number | null;
  marketPercentage: number | null;
  premiumMarketValuation: MarketValuationHeader | null;
  premiumMarketCurrencyId: number | null;
  premiumMarketUnitId: number | null;
  premiumMarketDate: MarketDateResult;
  premiumMarketPrice: number | null;
  premiumUnitId: number | null;
  premiumCurrencyId: number | null;
  premiumMarketSpread: number | null;
  premiumMarketSpreadCurrencyId: number | null;
  premiumMarketSpreadUnitId: number | null;
  premiumMarketPercentage: number | null;
  premium: number | null;
  fxRate: number | null;
  lineNote: string;
  blDate: Date | null;
  quantityUnitFactor: UnitInfo | null;
  priceUnitFactor: UnitInfo | null;
  materialPriceBounds: { min: number | null; max: number | null };
};

type FixedClientInvoiceLineForm = {
  shipmentId: number;
  type: 'Fixed';
  quantity: number;
  quantityUnitId: number;
  formula: string;
  materialPrice: number;
  linePrice: number;
  lineTotal: number;
  priceUnitId: number;
  priceCurrencyId: number;
  lineNote: string;
  quantityUnitFactor: UnitInfo | null;
  priceUnitFactor: UnitInfo | null;
  materialPriceBounds: { min: number | null; max: number | null };
};

export type ClientInvoiceLineForm = ProvisionalClientInvoiceLineForm | FixedClientInvoiceLineForm;

export type ClientInvoicePaymentCreditLineForm = Subset<
  OpenItem,
  'entryReference' | 'valueDate' | 'entryTitle' | 'externalRef' | 'balanceAmount' | 'entryType' | 'ourReference' | 'entryExternalRef',
  'entryId' | 'currencyId' | 'accountId' | 'balanceBaseAmount'
> & {
  lineNumber?: number;
  amountToApply?: number;
};

export type ClientInvoicePrefill = Omit<ClientInvoiceHeaderForm, 'numberOfDecimals'> & {
  contractDate: number;
  permanentDate: Date;
  lines: ShipmentInformationForClientInvoice[];
  paymentsCreditsLines: ClientInvoicePaymentCreditLineForm[];
  currencyConversions: CurrencyConversion[];
};

@UntilDestroy()
@Component({
  selector: 'accounting-client-invoice',
  templateUrl: './client-invoice.component.html',
  styleUrls: ['./client-invoice.component.scss'],
  providers: [ClientInvoicePaymentsCreditsComponent],
})
export class ClientInvoiceComponent implements OnInit, ModalFormComponent<ClientInvoiceForm, ClientInvoicePrefill> {
  popup = true;

  selectorApi?: SelectorApi;

  totalInvoice = new Subject<number>();

  totalAmount: number;
  paymentTermDropdown = paymentTermDropdown();
  numberOfDecimals = NumberOfDecimals;
  selectedNumberOfDecimals: number;
  counterpartyDropdown = counterpartyDropdown();
  form: TypedFormGroup<ClientInvoiceForm>;
  currencyConversions: CurrencyConversion[];
  type: 'P' | 'F' = 'F';
  companyId: number | null = null;
  contractDate: Date;

  constructor(private commonData: CommonDataService, private api: ThalosApiService, private delegateService: DelegateService, private store: Store, private formatter: DataFormattingService) {
    this.form = new TypedFormGroup<ClientInvoiceForm>({
      number: new UntypedFormControl(null, [Validators.min(1)]),
      contractNumber: new UntypedFormControl(null),
      counterparty: new UntypedFormControl(null),
      invoiceDate: new UntypedFormControl(null, [Validators.required, bradyDateValidator()]),
      dueDate: new UntypedFormControl(null, [Validators.required, bradyDateValidator()]),
      paymentTerm: new UntypedFormControl(null, Validators.required),
      numberOfDecimals: new UntypedFormControl(null),
      lines: new UntypedFormControl([]),
      paymentsCreditsLines: new UntypedFormControl([]),
      oneShipmentPerInvoice: new UntypedFormControl(YN.N),
    });

    this.form
      .get('numberOfDecimals')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.selectedNumberOfDecimals = !val && val !== 0 ? 2 : val;
      });

    this.form.patchValue({ numberOfDecimals: PrecisionDecimals.TWO });

    const defaultCompany = getCompanyByDefaultAuthorizedCompany(this.store);
    this.companyId = defaultCompany ? defaultCompany.id : null;
  }

  async validateClientInvoiceNumber(data) {
    if (data) {
      const prompt = this.delegateService.getService('prompt');
      const clientInvoiceNumber = Number(data);
      if (!clientInvoiceNumber || isNaN(clientInvoiceNumber)) return prompt.htmlDialog('Error', `<div style="white-space: pre">The invoice number entered is not valid</div>`);

      this.api.run<{ clientInvoiceNumberExists: boolean }>(endpoints.validateClientInvoiceNumber, { number: clientInvoiceNumber, companyId: this.companyId }, null).then((res) => {
        if (res?.clientInvoiceNumberExists === true) {
          prompt.htmlDialog('Error', `<div style="white-space: pre">The invoice number entered is not available. It will be replaced when submitting</div>`);
        }
      });
    }
  }

  prefillForm(data: ClientInvoicePrefill) {
    let lineNumber = 1;
    this.currencyConversions = data.currencyConversions;
    let marketValuations: MarketValuationHeader[] | null = null;
    let premiumValuations: MarketValuationHeader[] | null = null;
    const units = this.commonData.staticUnits.value.reduce((unitsMap, unit) => unitsMap.set(unit.unitId, unit), new Map<number, Unit>());
    this.contractDate = fromBradyDate(data.contractDate);
    this.form.get('invoiceDate').addValidators(materialInvoiceDateValidator(this.contractDate));

    this.form.patchValue({
      number: data.number,
      contractNumber: data.contractNumber,
      counterparty: data.counterparty,
      invoiceDate: data.invoiceDate,
      dueDate: data.dueDate,
      paymentTerm: data.paymentTerm,
      numberOfDecimals: data.counterparty && data.counterparty.decimalPrecision,
      lines: data.lines.map((shipment) => {
        const materialPriceBounds = { min: null, max: null };

        let priceUnitFactor: UnitInfo | null = null;
        const priceUnit = units.get(shipment.contractPriceUnitId);
        if (priceUnit && priceUnit.unitFactors) {
          priceUnitFactor = priceUnit.unitFactors.find((factor) => factor.productId === shipment.contractProductId) || null;
          if (priceUnitFactor) {
            let priceBoundsXr = 1;
            if (shipment.contractCurrencyId !== 3) {
              const currencyRate = this.currencyConversions.find((fx) => fx.baseCurrencyId === shipment.contractCurrencyId && fx.targetCurrencyId === 3);
              if (currencyRate) {
                priceBoundsXr = currencyRate.factor;
              }
            }

            materialPriceBounds.min = (shipment.lowerPriceToleranceInMts * priceUnitFactor.factor) / priceBoundsXr;
            materialPriceBounds.max = (shipment.upperPriceToleranceInMts * priceUnitFactor.factor) / priceBoundsXr;
          }
        }

        let quantityUnitFactor: UnitInfo | null = null;
        const quanityUnit = units.get(shipment.unitId);
        if (quanityUnit && quanityUnit.unitFactors) {
          quantityUnitFactor = quanityUnit.unitFactors.find((factor) => factor.productId === shipment.contractProductId) || null;
        }

        if (shipment.isShipmentFixed === 'N') {
          this.type = 'P';
          // Provisional
          let fxRate = shipment.fixedExchangeRate;
          if (!marketValuations) marketValuations = this.commonData.staticMarketValuations.value;
          const marketValuation = marketValuations.find((v) => v.valuationId === shipment.marketValuationId);
          if (!fxRate && !!shipment.marketValuationId) {
            const currencyRate = this.currencyConversions.find((fx) => fx.baseCurrencyId === marketValuation.currencyId && fx.targetCurrencyId === shipment.contractCurrencyId);
            if (currencyRate) fxRate = currencyRate.factor ? 1 / currencyRate.factor : null;
          }

          let premiumMarketValuation = null;
          if (!!shipment.premiumMarketValuationId) {
            if (!premiumValuations) premiumValuations = this.commonData.staticPremiumValuations.value;
            premiumMarketValuation = premiumValuations.find((v) => v.valuationId === shipment.premiumMarketValuationId) || null;
          }

          const line: ProvisionalClientInvoiceLineForm = {
            type: 'Provisional',
            shipmentId: shipment.shipmentId,
            productId: shipment.contractProductId,
            quantity: shipment.netWeight,
            quantityUnitId: shipment.unitId,
            materialPrice: null,
            linePrice: null,
            lineTotal: null,
            formula: shipment.formulaText,
            priceUnitId: shipment.contractPriceUnitId,
            priceCurrencyId: shipment.contractCurrencyId,
            collateralPercentage: shipment.collateral | 0,
            collateralAmount: 0,
            fxRate: fxRate,
            lineNote: '',
            marketDate: null,
            marketPrice: shipment.fixedMarketPrice,
            marketValuation,
            marketCurrencyId: marketValuation ? marketValuation.currencyId : null,
            marketUnitId: marketValuation ? marketValuation.unitId : null,
            marketPercentage: shipment.marketPercentage,
            marketSpread: shipment.marketSpread,
            marketSpreadCurrencyId: marketValuation ? marketValuation.currencyId : null,
            marketSpreadUnitId: marketValuation ? marketValuation.unitId : null,
            premiumMarketDate: null,
            premiumMarketPrice: shipment.fixedPremiumPrice,
            premiumMarketValuation,
            premiumMarketCurrencyId: premiumMarketValuation ? premiumMarketValuation.currencyId : null,
            premiumMarketUnitId: premiumMarketValuation ? premiumMarketValuation.unitId : null,
            premiumMarketPercentage: shipment.premiumMarketPercentage,
            premiumMarketSpread: shipment.premiumMarketSpread,
            premiumMarketSpreadCurrencyId: premiumMarketValuation ? premiumMarketValuation.currencyId : null,
            premiumMarketSpreadUnitId: premiumMarketValuation ? premiumMarketValuation.unitId : null,
            premium: shipment.premium,
            premiumCurrencyId: shipment.premiumCurrencyId,
            premiumUnitId: shipment.premiumUnitId,
            blDate: fromBradyDateOrNull(shipment.blDate),
            priceUnitFactor,
            quantityUnitFactor,
            materialPriceBounds,
          };
          return line;
        } else {
          // Fixed
          const line: FixedClientInvoiceLineForm = {
            type: 'Fixed',
            shipmentId: shipment.shipmentId,
            quantity: shipment.netWeight,
            quantityUnitId: shipment.unitId,
            materialPrice: shipment.walkPrice,
            linePrice: null,
            lineTotal: null,
            formula: shipment.formulaText,
            priceUnitId: shipment.contractPriceUnitId,
            priceCurrencyId: shipment.contractCurrencyId,
            lineNote: '',
            priceUnitFactor,
            quantityUnitFactor,
            materialPriceBounds,
          };
          return line;
        }
      }),
      paymentsCreditsLines: data.paymentsCreditsLines.map((openItem) => {
        const line: ClientInvoicePaymentCreditLineForm = {
          lineNumber: lineNumber++,
          entryType: openItem.entryType,
          entryTitle: openItem.entryTitle,
          externalRef: openItem.externalRef,
          entryExternalRef: openItem.entryExternalRef,
          balanceAmount: this.formatter.roundAmount(openItem.balanceAmount, openItem.currencyId),
          amountToApply: this.formatter.roundAmount(openItem.balanceAmount, openItem.currencyId),
          entryReference: openItem.entryReference,
          valueDate: openItem.valueDate,
          entryId: openItem.entryId,
          currencyId: openItem.currencyId,
          accountId: openItem.accountId,
          balanceBaseAmount: openItem.balanceBaseAmount,
          ourReference: openItem.ourReference,
        };
        return line;
      }),
    });
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): ClientInvoiceForm {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    return this.form.value;
  }

  ngOnInit() {}
}
