import { OperatorType } from 'src/app/+modules/+logistics/booking-list/booking-list.component';
import { environment } from 'src/environments/environment';
import { endpoints } from 'src/lib/apiEndpoints';
import { MiscFeature } from 'src/lib/feature';
import { Contact, SourceEntityType } from 'src/lib/newBackendTypes';
import {
  AdvanceIcon,
  ApprovalsIcon,
  BookingIcon,
  ContactIcon,
  ContractIcon,
  DiscrepancyIcon,
  DITIcon,
  DraftIcon,
  EditIcon,
  ExchangeContractIcon,
  ExecutiveIcon,
  FinanceIcon,
  FiscalCompanyIcon,
  FolderIcon,
  GenericListIcon,
  GlAccountIcon,
  GoToIcon,
  HomeIcon,
  InvoiceIcon,
  JournalIcon,
  LogisticsIcon,
  LogisticsManagerIcon,
  MatchingIcon,
  MetalControlIcon,
  NewIcon,
  PurchaseTicketIcon,
  QualityControlIcon,
  ReportIcon,
  ServiceOrderIcon,
  ShipmentIcon,
  TagsIcon,
  TradingIcon,
  UserAssignmentIcon,
  UserAuthorizedCompaniesIcon,
  ChartIcon,
  UtilIcon,
  WindowIcon,
} from 'src/lib/uiConstants';
import { Calculator, DateCalculator, MWTIMarketPriceMenuItem, OceanRateFinderMenuItem } from '../../../lib/menuEffects';
import { MenuItem } from '../../../lib/MenuItem';
import {
  BLOCK_ANCHOR_POINT,
  CALCULATED_DYNAMIC_MENUS,
  FINISH_REQUEST,
  LOADED_MISSING_ENDPOINTS,
  LOADED_MISSING_GRAPHQLS,
  SET_ANCHOR_POINT,
  SET_BOOKING_LAYOUT,
  SET_BOOKING_OPERATOR,
  SET_FLAT_MENU,
  SET_TIMEDOUT,
  START_REQUEST,
  TOGGLE_DEBUG_MODE,
  UPDATE_ANCHOR_POINT,
  UPDATE_REQUEST_PROGRESS,
} from './actions';
import { ExistingGraphql } from 'src/lib/graphql/graphQlEnums';

export const baseEndpointRequirements: readonly endpoints[] = [
  endpoints.listrpcids,
  endpoints.me,
  endpoints.listUnits,
  endpoints.listProducts,
  endpoints.listMarketValuations,
  endpoints.listCurrencies,
  endpoints.listCompanies,
  endpoints.listIncoterms,
  endpoints.listApprovalTypes,
  endpoints.listContainerTypes,
  endpoints.listFlexViews,
];

export type RequestSpinner = { id: string; text: string | null; background?: boolean; progress?: number; hidden?: boolean };

export type NavigationState<T> = {
  url: string;
  state: T;
};

export interface LayoutState {
  debugMode: boolean;
  showSpinner: boolean;
  requestsPending: RequestSpinner[];
  readyToHide: boolean;
  missingRequirements: { [key: string]: string[] };
  missinGraphqlRequirements: { [key: string]: string[] };
  fullMenu: MenuItem[];
  permittedMenu: MenuItem[];
  currentSpinner: RequestSpinner;
  timedOut: boolean;
  entityType?: SourceEntityType;
  bookingOperator?: Contact;
  bookingLayout?: OperatorType;
  anchorPoint: NavigationState<any> | null;
  anchorPointExtraParams: any | null;
  blockNextAnchor: boolean;
  flatMenu?: MenuItem[];
}

// TODO Rebuild menu using new endpoint enum (RpcIds)
const initialLayoutState: LayoutState = {
  debugMode: false, //!environment.production,
  readyToHide: true,
  showSpinner: false,
  requestsPending: [],
  currentSpinner: null,
  missingRequirements: {},
  missinGraphqlRequirements: {},
  timedOut: false,
  anchorPoint: null,
  anchorPointExtraParams: null,
  blockNextAnchor: false,
  fullMenu: [
    {
      title: 'Home',
      requirements: [],
      regexPath: /^\/?$/,
      hidden: false,
      link: '/',
      icon: HomeIcon,
    },
    {
      title: 'Executive',
      order: 0,
      regexPath: /^\/portal\/executive\/?$/,
      requirements: [],
      hidden: false,
      icon: ExecutiveIcon,
      children: [],
    },
    {
      title: 'Contacts',
      order: 10,
      graphQlrequirements: [ExistingGraphql.alves],
      regexPath: /^\/portal\/core\/contacts\/?$/,
      hidden: false,
      entityType: SourceEntityType.ALF_CODE,
      entitySource: 'list',
      link: '/portal/core/contacts',
      icon: ContactIcon,
      children: [
        {
          title: 'New Contact',
          requirements: [
            endpoints.createContact,
            endpoints.getContact,
            endpoints.listContacts,
            endpoints.listContactTypes,
            endpoints.listLanguages,
            endpoints.listBankAccounts,
            endpoints.listGlAccounts,
            endpoints.listClauseTemplates,
            endpoints.createAddress,
            endpoints.syncContactTypes,
            endpoints.updateAuthorizedCurrencies,
            endpoints.getContactNames,
            endpoints.listClientCreditInsurance,
            endpoints.listApprovals,
          ],
          graphQlrequirements: [ExistingGraphql.places],
          hidden: false,
          regexPath: /^\/portal\/core\/contacts\/new$/,
          link: '/portal/core/contacts/new',
          entityType: SourceEntityType.ALF_CODE,
          entitySource: 'create',
          icon: NewIcon,
        },
        {
          title: 'Approval Queue',
          link: '/portal/core/approval-queue',
          requirements: [endpoints.approvalQueue],
          regexPath: /^\/portal\/core\/approval-queue\/?$/,
          hidden: false,
          icon: ApprovalsIcon,
        },
        {
          title: 'Edit Contact',
          requirements: [
            endpoints.updateContact,
            endpoints.getContact,
            endpoints.listContacts,
            endpoints.listContactTypes,
            endpoints.listLanguages,
            endpoints.listBankAccounts,
            endpoints.listGlAccounts,
            endpoints.listClauseTemplates,
            endpoints.createAddress,
            endpoints.syncContactTypes,
            endpoints.updateAuthorizedCurrencies,
            endpoints.getContactNames,
            endpoints.listClientCreditInsurance,
            endpoints.listApprovals,
          ],
          graphQlrequirements: [ExistingGraphql.places],
          hidden: true,
          entityType: SourceEntityType.ALF_CODE,
          entitySource: 'get',
          link: '/portal/core/contacts',
          regexPath: /^\/portal\/core\/contacts\/(?!(new)($|\/)).+\/?$/,
          icon: EditIcon,
        },
      ],
    },
    {
      title: 'Trading',
      requirements: [],
      regexPath: /^\/portal\/trading\/?$/,
      hidden: false,
      icon: TradingIcon,
      children: [
        {
          title: 'Drafts',
          requirements: [endpoints.listDraftData],
          hidden: false,
          icon: DraftIcon,
          children: [
            {
              title: 'New Draft',
              requirements: [
                endpoints.createDraft,
                endpoints.getDraft,
                endpoints.listCompanies,
                endpoints.listContacts,
                endpoints.listPaymentTerms,
                endpoints.listContainerTypes,
                endpoints.listBudgetElements,
                endpoints.listRequirements,
                endpoints.listClauseTemplates,
                endpoints.getClauseTemplate,
                endpoints.listProducts,
                endpoints.listIncoterms,
                endpoints.listUnits,
                endpoints.listCurrencies,
                endpoints.listContainerTypes,
                endpoints.listCountries,
                endpoints.listItems,
                endpoints.listMetalControlGroups,
              ],
              graphQlrequirements: [ExistingGraphql.places],
              hidden: false,
              regexPath: /^\/portal\/trading\/drafts\/new?$/,
              link: '/portal/trading/drafts/new',
              entityType: SourceEntityType.DRAFT_KEY,
              entitySource: 'create',
              icon: NewIcon,
            },
            {
              title: 'Edit Draft',
              requirements: [
                endpoints.updateDraft,
                endpoints.getDraft,
                endpoints.listCompanies,
                endpoints.listContacts,
                endpoints.listPaymentTerms,
                endpoints.listContainerTypes,
                endpoints.listBudgetElements,
                endpoints.listRequirements,
                endpoints.listClauseTemplates,
                endpoints.getClauseTemplate,
                endpoints.listProducts,
                endpoints.listIncoterms,
                endpoints.listUnits,
                endpoints.listCurrencies,
                endpoints.listContainerTypes,
                endpoints.listCountries,
                endpoints.listItems,
                endpoints.listMetalControlGroups,
              ],
              graphQlrequirements: [ExistingGraphql.places],
              hidden: true,
              regexPath: /^\/portal\/trading\/drafts\/\d+/,
              entityType: SourceEntityType.DRAFT_KEY,
              entitySource: 'get',
              link: '/portal/trading/drafts',
              icon: EditIcon,
            },
          ],
          regexPath: /^\/portal\/trading\/drafts\/?$/,
          link: '/portal/trading/drafts',
          entityType: SourceEntityType.DRAFT_KEY,
          entitySource: 'list',
        },
        {
          title: 'Contracts',
          requirements: [endpoints.listContractData, endpoints.listCountries, endpoints.listItems, endpoints.listContacts, endpoints.listPaymentTerms, endpoints.listProducts, endpoints.listIncoterms],
          hidden: false,
          icon: ContractIcon,
          children: [
            {
              title: 'Edit Contract',
              requirements: [
                endpoints.updateContract,
                endpoints.getContract,
                endpoints.listContacts,
                endpoints.listPaymentTerms,
                endpoints.listContainerTypes,
                endpoints.listBudgetElements,
                endpoints.listRequirements,
                endpoints.listClauseTemplates,
                endpoints.getClauseTemplate,
                endpoints.listProducts,
                endpoints.listIncoterms,
                endpoints.listUnits,
                endpoints.listCurrencies,
                endpoints.listContainerTypes,
                endpoints.listCountries,
                endpoints.listItems,
                endpoints.listMetalControlGroups,
              ],
              hidden: true,
              regexPath: /^\/portal\/trading\/contracts\/\d+/,
              entityType: SourceEntityType.CONTRACT_KEY,
              entitySource: 'get',
              link: '/portal/trading/contracts',
              icon: EditIcon,
            },
          ],
          regexPath: /^\/portal\/trading\/contracts\/?$/,
          link: '/portal/trading/contracts',
          entityType: SourceEntityType.CONTRACT_KEY,
          entitySource: 'list',
        },
        {
          title: 'Clause Templates',
          requirements: [endpoints.listClauseTemplates],
          hidden: false,
          regexPath: /^\/portal\/trading\/clause-templates\/?$/,
          link: '/portal/trading/clause-templates',
          children: [
            {
              title: 'New Clause Template',
              requirements: [endpoints.createClauseTemplate, endpoints.getClauseTemplate, endpoints.listProducts, endpoints.listContacts],
              hidden: false,
              link: '/portal/trading/clause-templates/new',
              regexPath: /^\/portal\/trading\/clause-templates\/new\/?$/,
              icon: NewIcon,
            },
            {
              title: 'Edit Clause Template',
              requirements: [endpoints.updateClauseTemplate, endpoints.getClauseTemplate, endpoints.listProducts, endpoints.listContacts],
              hidden: true,
              regexPath: /^\/portal\/trading\/clause-templates\/(?!(new)($|\/)).+\/?$/,
              icon: EditIcon,
            },
          ],
        },
        {
          title: 'Contract Shipment Fixation Details',
          requirements: [endpoints.listContractFixationDetails],
          hidden: false,
          icon: ShipmentIcon,
          regexPath: /^\/portal\/trading\/contract-fixation-details\/?$/,
          link: '/portal/trading/contract-fixation-details',
          entitySource: 'list',
        },
      ],
    },
    {
      title: 'Quality Control',
      requirements: [],
      regexPath: /^\/portal\/quality-control\/?$/,
      hidden: false,
      icon: QualityControlIcon,
      children: [
        {
          title: 'Discrepancies',
          requirements: [endpoints.listDiscrepancies, endpoints.listContacts],
          hidden: false,
          link: '/portal/quality-control/discrepancies',
          regexPath: /^\/portal\/quality-control\/discrepancies\/?$/,
          entityType: SourceEntityType.DISCREPANCY_ID,
          entitySource: 'list',
          icon: DiscrepancyIcon,
          children: [
            {
              title: 'New Discrepancy',
              requirements: [
                endpoints.createDiscrepancy,
                endpoints.getDiscrepancy,
                endpoints.shipmentLookup,
                endpoints.listPaymentTerms,
                endpoints.listBudgetElements,
                endpoints.listShipments,
                endpoints.getBudgetElementGroup,
                endpoints.listWeightTickets,
                endpoints.listContacts,
                endpoints.listCurrencies,
                endpoints.listDiscrepancies,
                endpoints.listShipmentWalkPrice,
                endpoints.getShipmentInvoiceInformation,
                endpoints.shipmentLookupGenealogy,
                endpoints.listContractClauses,
              ],
              hidden: false,
              link: '/portal/quality-control/discrepancies/new',
              regexPath: /^\/portal\/quality-control\/discrepancies\/new\/?$/,
              entityType: SourceEntityType.DISCREPANCY_ID,
              entitySource: 'create',
              icon: NewIcon,
            },
            {
              title: 'Edit Discrepancy',
              requirements: [
                endpoints.updateDiscrepancy,
                endpoints.getDiscrepancy,
                endpoints.shipmentLookup,
                endpoints.listPaymentTerms,
                endpoints.listBudgetElements,
                endpoints.listShipments,
                endpoints.getBudgetElementGroup,
                endpoints.listWeightTickets,
                endpoints.listContacts,
                endpoints.listCurrencies,
                endpoints.listDiscrepancies,
                endpoints.listShipmentWalkPrice,
                endpoints.getShipmentInvoiceInformation,
                endpoints.shipmentLookupGenealogy,
                endpoints.listContractClauses,
              ],
              hidden: true,
              entityType: SourceEntityType.DISCREPANCY_ID,
              entitySource: 'get',
              link: '/portal/quality-control/discrepancies',
              regexPath: /^\/portal\/quality-control\/discrepancies\/(?!(new)($|\/)).+\/?$/,
              icon: EditIcon,
            },
          ],
        },
      ],
    },
    {
      title: 'Logistics',
      requirements: [],
      regexPath: /^\/portal\/logistics\/?$/,
      hidden: false,
      icon: LogisticsIcon,
      children: [
        {
          title: 'Bookings - Logistics',
          requirements: [endpoints.bookingQueue, endpoints.listContacts],
          hidden: false,
          regexPath: /^\/portal\/logistics\/bookings\/?$/,
          link: '/portal/logistics/bookings',
          entitySource: 'list',
          entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
          icon: BookingIcon,
          children: [
            {
              title: 'New Booking - Logistics',
              requirements: [
                endpoints.createBooking,
                endpoints.createContainer,
                endpoints.createShipmentFromContractLine,
                endpoints.getBooking,
                endpoints.listContacts,
                endpoints.listVessels,
                endpoints.listCities,
                endpoints.linkContainerToBooking,
                endpoints.poLineFinder,
                endpoints.listShipments,
                endpoints.listDocuments,
                endpoints.listComments,
                endpoints.listShipmentContractData,
                endpoints.checkDuplicateContainerNumber,
                endpoints.listOceanSegments,
                endpoints.listBookingExpenses,
                endpoints.getRelatedBookings,
                endpoints.oceanRateLookup,
              ],
              graphQlrequirements: [ExistingGraphql.places],
              hidden: false,
              link: '/portal/logistics/bookings/new',
              entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
              entitySource: 'create',
              icon: NewIcon,
              regexPath: /^\/portal\/logistics\/bookings\/new\/?$/,
            },
            {
              title: 'Edit Booking',
              requirements: [
                endpoints.updateBooking,
                endpoints.updateContainer,
                endpoints.updateShipment,
                endpoints.getBooking,
                endpoints.listContacts,
                endpoints.listVessels,
                endpoints.listCities,
                endpoints.linkContainerToBooking,
                endpoints.poLineFinder,
                endpoints.listShipments,
                endpoints.listDocuments,
                endpoints.listComments,
                endpoints.listShipmentContractData,
                endpoints.checkDuplicateContainerNumber,
                endpoints.listOceanSegments,
                endpoints.listBookingExpenses,
                endpoints.getRelatedBookings,
                endpoints.oceanRateLookup,
              ],
              graphQlrequirements: [ExistingGraphql.places],
              hidden: true,
              regexPath: /^\/portal\/logistics\/bookings\/(?!(new)($|\/)).+\/?$/,
              featureRoute: MiscFeature.GET_LOGISTICS_BOOKING,
              link: '/portal/logistics/bookings',
              icon: EditIcon,
            },
            {
              title: 'Get Booking',
              requirements: [endpoints.getBooking, endpoints.oceanRateLookup, endpoints.listShipments, endpoints.listShipmentContractData, endpoints.poLineFinder],
              hidden: true,
              regexPath: /^\/portal\/logistics\/get-booking\/(?!(new)($|\/)).+\/?$/,
              link: '/portal/logistics/get-booking',
              entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
              entitySource: 'get',
            },
          ],
        },
        {
          title: 'Shipments',
          requirements: [endpoints.listShipments, endpoints.shipmentLookup, endpoints.getShipment, endpoints.getGenealogy],
          hidden: false,
          regexPath: /^\/portal\/logistics\/shipments\/?$/,
          link: '/portal/logistics/shipments',
          entityType: SourceEntityType.CHUNK_KEY,
          entitySource: 'list',
          icon: ShipmentIcon,
          children: [
            {
              title: 'Manage Shipment',
              requirements: [],
              hidden: true,
              entitySource: 'get',
              entityType: SourceEntityType.CHUNK_KEY,
              link: '/portal/logistics/shipments',
              regexPath: /^\/portal\/logistics\/shipments\/(?!(new)($|\/)).+\/?$/,
              children: [],
            },
          ],
        },
        {
          title: 'Arrival Confirmation Tracking',
          requirements: [endpoints.listArrivalConfirmationBookings],
          hidden: false,
          regexPath: /^\/portal\/logistics\/arrival-confirmation-tracking\/?$/,
          link: '/portal/logistics/arrival-confirmation-tracking',
          icon: GenericListIcon,
        },
        {
          title: 'Routine Cargo Tracking',
          requirements: [endpoints.listRoutineTrackingBookings],
          hidden: false,
          regexPath: /^\/portal\/logistics\/routine-cargo-tracking\/?$/,
          link: '/portal/logistics/routine-cargo-tracking',
          icon: GenericListIcon,
        },
        {
          title: 'Ocean Rates',
          regexPath: /^\/portal\/logistics\/ocean-rates\/?$/,
          requirements: [],
          hidden: false,
          icon: InvoiceIcon,
          children: [
            {
              title: 'Create Rate',
              link: '/portal/logistics/ocean-rates/rates/new',
              regexPath: /^\/portal\/logistics\/ocean-rates\/rates\/new\/?$/,
              requirements: [endpoints.createOceanRateJob, endpoints.getOceanRate, endpoints.createSurchargeType, endpoints.listContacts],
              entityType: SourceEntityType.RATE_KEY,
              entitySource: 'create',
              icon: NewIcon,
            },
            {
              title: 'Edit Rate',
              regexPath: /^\/portal\/logistics\/ocean-rates\/rates\/(?!(new)($|\/)).+\/?$/,
              requirements: [endpoints.updateOceanRate, endpoints.getOceanRate, endpoints.createSurchargeType, endpoints.listContacts],
              entityType: SourceEntityType.RATE_KEY,
              entitySource: 'get',
              link: '/portal/logistics/ocean-rates/rates',
              icon: EditIcon,
              hidden: true,
            },
          ],
        },
        {
          title: 'Ocean Rate Finder',
          requirements: [endpoints.oceanRateLookup],
          graphQlrequirements: [ExistingGraphql.places],
          hidden: false,
          regexPath: /^\/portal\/logistics\/ocean-rate-finder\/?$/,
          link: '/portal/logistics/ocean-rate-finder',
          icon: GenericListIcon,
        },
        {
          title: 'Warehouse Inventory Report',
          icon: ShipmentIcon,
          hidden: false,
          link: '/portal/logistics/warehouse-inventory-report',
          regexPath: /^\/portal\/logistics\/warehouse-inventory-report\/?$/,
          requirements: [endpoints.listWarehouseInventoryReport],
          entitySource: 'list',
          entityType: SourceEntityType.CHUNK_KEY,
          children: [],
        },
      ],
    },
    {
      title: 'Logistics Manager',
      requirements: [],
      regexPath: /^\/portal\/logistics-manager\/?$/,
      hidden: false,
      icon: LogisticsManagerIcon,
      children: [
        {
          title: 'Appointments',
          icon: FiscalCompanyIcon,
          hidden: false,
          link: '/portal/logistics/appointments',
          regexPath: /^\/portal\/logistics\/appointments\/?$/,
          requirements: [endpoints.listLogisticsAppointmentsList, endpoints.listElementDescription],
          entitySource: 'list',
          entityType: SourceEntityType.APPOINTMENTS,
          children: [],
        },
        {
          title: 'File Assignment Queue',
          requirements: [endpoints.logisticsFileAssignmentQueue, endpoints.updateBooking, endpoints.listContacts],
          hidden: false,
          regexPath: /^\/portal\/logistics\/file-assignment-queue\/?$/,
          link: '/portal/logistics/file-assignment-queue',
          icon: UserAssignmentIcon,
        },
        {
          title: 'Booking Update Track',
          icon: FiscalCompanyIcon,
          hidden: false,
          link: '/portal/logistics/booking-update-track',
          regexPath: /^\/portal\/logistics\/booking-update-track\/?$/,
          requirements: [endpoints.getBookingUpdateHistory],
          entitySource: 'list',
          entityType: SourceEntityType.BOOKING_UPDATE_TRACK,
          children: [],
        },
      ],
    },
    {
      title: 'Finance and Accounting',
      requirements: [],
      regexPath: /^\/portal\/accounting\/?$/,
      hidden: false,
      icon: FinanceIcon,
      children: [
        {
          title: 'Navigations',
          hidden: false,
          icon: FolderIcon,
          children: [
            {
              title: 'Vouchers',
              link: '/portal/accounting/vouchers',
              regexPath: /^\/portal\/accounting\/vouchers\/?$/,
              requirements: [endpoints.listVouchers, endpoints.listCompanies],
              graphQlrequirements: [ExistingGraphql.alves],
              hidden: false,
              entityType: SourceEntityType.INVOICE_KEY,
              entitySource: 'list',
              icon: InvoiceIcon,
              children: [
                {
                  title: 'Create Voucher',
                  link: '/portal/accounting/vouchers/new',
                  regexPath: /^\/portal\/accounting\/vouchers\/new\/?$/,
                  requirements: [
                    endpoints.getVoucher,
                    endpoints.createVoucher,
                    endpoints.listCompanies,
                    endpoints.getDailyExchangeRates,
                    endpoints.listContractData,
                    endpoints.listGlAccounts,
                    endpoints.listBudgetElements,
                  ],
                  graphQlrequirements: [ExistingGraphql.alves, ExistingGraphql.paymentTerms, ExistingGraphql.accounts, ExistingGraphql.glAccounts],
                  entityType: SourceEntityType.INVOICE_KEY,
                  entitySource: 'create',
                  icon: NewIcon,
                },
                {
                  title: 'Edit Voucher',
                  regexPath: /^\/portal\/accounting\/vouchers\/(?!(new)($|\/)).+\/?$/,
                  requirements: [
                    endpoints.getVoucher,
                    endpoints.updateVoucher,
                    endpoints.listCompanies,
                    endpoints.getDailyExchangeRates,
                    endpoints.listContractData,
                    endpoints.listGlAccounts,
                    endpoints.listBudgetElements,
                  ],
                  graphQlrequirements: [ExistingGraphql.alves, ExistingGraphql.paymentTerms, ExistingGraphql.accounts, ExistingGraphql.glAccounts],
                  entityType: SourceEntityType.INVOICE_KEY,
                  entitySource: 'get',
                  link: '/portal/accounting/vouchers',
                  icon: EditIcon,
                  hidden: true,
                },
              ],
            },
            {
              title: 'Journals',
              hidden: false,
              link: '/portal/accounting/journals',
              regexPath: /^\/portal\/accounting\/journals\/?$/,
              requirements: [endpoints.listJournals, endpoints.listCompanies],
              entitySource: 'list',
              icon: JournalIcon,
              entityType: SourceEntityType.JOURNAL_ID,
              children: [
                {
                  title: 'New Journal',
                  hidden: false,
                  link: '/portal/accounting/journals/new',
                  regexPath: /^\/portal\/accounting\/journals\/new\/?$/,
                  requirements: [
                    endpoints.createJournal,
                    endpoints.getJournal,
                    endpoints.listBankAccounts,
                    endpoints.listGlAccounts,
                    endpoints.listAnalyticalGroups,
                    endpoints.getDailyExchangeRates,
                    endpoints.listCompanies,
                    endpoints.listProducts,
                    endpoints.listContacts,
                  ],
                  icon: NewIcon,
                  entitySource: 'create',
                  entityType: SourceEntityType.JOURNAL_ID,
                },
                {
                  title: 'Edit Journal',
                  hidden: true,
                  regexPath: /^\/portal\/accounting\/journals\/(?!(new)($|\/)).+\/?$/,
                  requirements: [
                    endpoints.updateJournal,
                    endpoints.getJournal,
                    endpoints.listBankAccounts,
                    endpoints.listGlAccounts,
                    endpoints.listAnalyticalGroups,
                    endpoints.getDailyExchangeRates,
                    endpoints.listCompanies,
                    endpoints.listProducts,
                    endpoints.listContacts,
                  ],
                  icon: EditIcon,
                  entitySource: 'get',
                  entityType: SourceEntityType.JOURNAL_ID,
                  link: '/portal/accounting/journals',
                },
              ],
            },
            {
              title: 'Payments',
              hidden: false,
              link: '/portal/accounting/payments',
              regexPath: /^\/portal\/accounting\/payments\/?$/,
              requirements: [endpoints.listPayments, endpoints.listCompanies],
              entitySource: 'list',
              icon: InvoiceIcon,
              entityType: SourceEntityType.PAYMENT_KEY,
              children: [
                {
                  title: 'New Payment',
                  hidden: false,
                  link: '/portal/accounting/payments/new',
                  regexPath: /^\/portal\/accounting\/payments\/new\/?$/,
                  requirements: [
                    endpoints.createPayment,
                    endpoints.getPayment,
                    endpoints.listGlAccounts,
                    endpoints.listAnalyticalGroups,
                    endpoints.getDailyExchangeRates,
                    endpoints.listCompanies,
                    endpoints.listPaymentEntries,
                    endpoints.listContacts,
                    endpoints.listVouchersBalance,
                  ],
                  icon: NewIcon,
                  entitySource: 'create',
                  entityType: SourceEntityType.PAYMENT_KEY,
                },
                {
                  title: 'Edit Payment',
                  hidden: true,
                  regexPath: /^\/portal\/accounting\/payments\/(?!(new)($|\/)).+\/?$/,
                  requirements: [
                    endpoints.updatePayment,
                    endpoints.getPayment,
                    endpoints.listGlAccounts,
                    endpoints.listAnalyticalGroups,
                    endpoints.getDailyExchangeRates,
                    endpoints.listCompanies,
                    endpoints.listPaymentEntries,
                    endpoints.listContacts,
                    endpoints.listVouchersBalance,
                  ],
                  icon: EditIcon,
                  entitySource: 'get',
                  entityType: SourceEntityType.PAYMENT_KEY,
                  link: '/portal/accounting/payments',
                },
              ],
            },
            {
              title: 'Open Items',
              hidden: false,
              link: '/portal/accounting/open-items',
              regexPath: /^\/portal\/accounting\/open-items\/?$/,
              requirements: [endpoints.listOpenItems, endpoints.listCompanies, endpoints.listContacts, endpoints.listGlAccounts],
              entitySource: 'list',
              icon: InvoiceIcon,
              entityType: SourceEntityType.PAYABLE_ITEM_KEY,
              children: [],
            },
            {
              title: 'Journal View',
              hidden: false,
              link: '/portal/accounting/journal-view',
              regexPath: /^\/portal\/accounting\/journal-view\/?$/,
              requirements: [endpoints.generateJournalViewReport, endpoints.listGlAccounts, endpoints.listCompanies, endpoints.listContacts],
              entitySource: 'list',
              icon: InvoiceIcon,
              entityType: SourceEntityType.PAYABLE_ITEM_KEY,
              children: [],
            },
            {
              title: 'General Simple Type',
              hidden: false,
              link: '/portal/accounting/general-simple-type',
              regexPath: /^\/portal\/accounting\/general-simple-type\/?$/,
              requirements: [endpoints.generateGeneralSimpleTypeReport, endpoints.listGlAccounts, endpoints.listCompanies],
              entitySource: 'list',
              icon: InvoiceIcon,
              entityType: SourceEntityType.JOURNAL_ENTRY_ID,
              children: [],
            },
            {
              title: 'Journal Reconciliation',
              hidden: false,
              link: '/portal/accounting/journal-reconciliation',
              regexPath: /^\/portal\/accounting\/journal-reconciliation\/?$/,
              requirements: [endpoints.generateJournalReconciliationReport, endpoints.listGlAccounts, endpoints.listCompanies],
              entitySource: 'list',
              icon: InvoiceIcon,
              entityType: SourceEntityType.JOURNAL_ENTRY_ID,
              children: [],
            },
            {
              title: 'Trial Balance',
              hidden: false,
              link: '/portal/accounting/trial-balance',
              regexPath: /^\/portal\/accounting\/trial-balance\/?$/,
              requirements: [endpoints.generateBalanceSheetReport, endpoints.getDailyExchangeRates, endpoints.listCompanies],
              entitySource: 'list',
              icon: InvoiceIcon,
              children: [],
            },
          ],
        },
        {
          title: 'AP',
          requirements: [],
          regexPath: /^\/portal\/finance-and-accounting\/ap\/?$/,
          icon: FolderIcon,
          hidden: false,
          children: [
            {
              title: 'Commissions',
              hidden: false,
              icon: FolderIcon,
              children: [
                {
                  title: 'Commissions Queue',
                  link: '/portal/accounting/commissions-queue',
                  regexPath: /^\/portal\/accounting\/commissions-queue\/?$/,
                  requirements: [endpoints.listFinalSalesCommissions, endpoints.listFinalPurchaseCommissions, endpoints.listContacts],
                  hidden: false,
                  icon: GenericListIcon,
                },
              ],
            },
            {
              title: 'Pay Orders',
              hidden: false,
              link: '/portal/accounting/pay-orders',
              regexPath: /^\/portal\/accounting\/pay-orders\/?$/,
              requirements: [endpoints.listPayOrders, endpoints.listCompanies],
              entitySource: 'list',
              icon: InvoiceIcon,
              entityType: SourceEntityType.PAY_ORDER_KEY,
              children: [
                {
                  title: 'New Pay Order',
                  hidden: false,
                  link: '/portal/accounting/pay-orders/new',
                  regexPath: /^\/portal\/accounting\/pay-orders\/new\/?$/,
                  requirements: [
                    endpoints.createPayOrder,
                    endpoints.getPayOrder,
                    endpoints.listBankAccounts,
                    endpoints.listContactBankInformation,
                    endpoints.listContacts,
                    endpoints.listGlAccounts,
                    endpoints.listOpenPayableItems,
                  ],
                  icon: NewIcon,
                  entitySource: 'create',
                  entityType: SourceEntityType.PAY_ORDER_KEY,
                },
                {
                  title: 'Edit Pay Order',
                  hidden: true,
                  regexPath: /^\/portal\/accounting\/pay-orders\/(?!(new)($|\/)).+\/?$/,
                  requirements: [
                    endpoints.updatePayOrder,
                    endpoints.getPayOrder,
                    endpoints.listBankAccounts,
                    endpoints.listContactBankInformation,
                    endpoints.listContacts,
                    endpoints.listGlAccounts,
                    endpoints.listOpenPayableItems,
                  ],
                  icon: EditIcon,
                  entitySource: 'get',
                  entityType: SourceEntityType.PAY_ORDER_KEY,
                  link: '/portal/accounting/pay-orders',
                },
              ],
            },
          ],
        },
        {
          title: 'AR',
          icon: FolderIcon,
          hidden: false,
          children: [
            {
              title: 'Advances',
              requirements: [],
              hidden: false,
              icon: AdvanceIcon,
              children: [
                {
                  title: 'Advances to Open',
                  link: '/portal/accounting/advances-to-open',
                  regexPath: /^\/portal\/accounting\/advances-to-open\/?$/,
                  requirements: [endpoints.listAdvancesToOpen],
                  hidden: false,
                  icon: GenericListIcon,
                  entityType: SourceEntityType.ADVANCE_KEY,
                  entitySource: 'create',
                  featureRoute: MiscFeature.CREATE_ADVANCE,
                },
              ],
            },
          ],
        },
        {
          title: 'Invoicing',
          icon: FolderIcon,
          hidden: false,
          children: [],
        },
        {
          title: 'Finance Release',
          icon: FolderIcon,
          hidden: false,
          children: [],
        },
        {
          title: 'Exchange Contracts',
          hidden: false,
          link: '/portal/accounting/exchange-contracts',
          regexPath: /^\/portal\/accounting\/exchange-contracts\/?$/,
          requirements: [endpoints.listExchangeContracts],
          entityType: SourceEntityType.EXCHANGE_CONTRACT,
          entitySource: 'list',
          icon: ExchangeContractIcon,
          children: [
            {
              title: 'New Exchange Contract',
              link: '/portal/accounting/exchange-contracts/new',
              regexPath: /^\/portal\/accounting\/exchange-contracts\/new\/?$/,
              requirements: [
                endpoints.createExchangeContract,
                endpoints.getExchangeContract,
                endpoints.listContractData,
                endpoints.listBanks,
                endpoints.getContract,
                endpoints.listCompanies,
                endpoints.listCurrencies,
                endpoints.listUnits,
              ],
              hidden: false,
              entityType: SourceEntityType.EXCHANGE_CONTRACT,
              entitySource: 'create',
              icon: NewIcon,
            },
            {
              title: 'Edit Exchange Contract',
              regexPath: /^\/portal\/accounting\/exchange-contracts\/(?!(new)($|\/)).+\/?$/,
              //could in theory be a seperate property called baseLink when an id will be appended to it
              link: '/portal/accounting/exchange-contracts',
              requirements: [
                endpoints.updateExchangeContract,
                endpoints.getExchangeContract,
                endpoints.listContractData,
                endpoints.listBanks,
                endpoints.getContract,
                endpoints.listCompanies,
                endpoints.listCurrencies,
                endpoints.listUnits,
              ],
              hidden: true,
              entityType: SourceEntityType.EXCHANGE_CONTRACT,
              entitySource: 'get',
              icon: EditIcon,
            },
          ],
        },
        {
          title: 'Accounting',
          hidden: false,
          icon: FolderIcon,
          children: [
            {
              title: 'Fiscal Years',
              hidden: false,
              link: '/portal/accounting/fiscal-years',
              regexPath: /^\/portal\/accounting\/fiscal-years\/?$/,
              requirements: [endpoints.listFiscalCompanies],
              entitySource: 'list',
              icon: FiscalCompanyIcon,
              entityType: SourceEntityType.FISCAL_COMPANY_KEY,
              children: [],
            },
            {
              title: 'GL Account Management',
              hidden: false,
              link: '/portal/accounting/gl-accounts-management',
              regexPath: /^\/portal\/accounting\/gl-accounts-management\/?$/,
              requirements: [endpoints.getGlStructureTree, endpoints.listGlStructures, endpoints.createGlStructureFromCopy],
              entitySource: 'list',
              icon: GlAccountIcon,
              children: [
                {
                  title: 'New GL Account',
                  hidden: false,
                  link: '/portal/accounting/gl-accounts-leaves/new',
                  regexPath: /^\/portal\/accounting\/gl-accounts-leaves\/new\/?$/,
                  requirements: [
                    endpoints.createGlAccount,
                    endpoints.getGlAccount,
                    endpoints.listCompanies,
                    endpoints.listCurrencies,
                    endpoints.listProducts,
                    endpoints.listGlBranches,
                    endpoints.listGlStructures,
                  ],
                  icon: NewIcon,
                  entitySource: 'create',
                  entityType: SourceEntityType.GL_ACCOUNT_LEAVES_ID,
                },
                {
                  title: 'Edit GL Account',
                  hidden: true,
                  regexPath: /^\/portal\/accounting\/gl-accounts-leaves\/(?!(new)($|\/)).+\/?$/,
                  requirements: [
                    endpoints.updateGlAccount,
                    endpoints.getGlAccount,
                    endpoints.listCompanies,
                    endpoints.listCurrencies,
                    endpoints.listProducts,
                    endpoints.listGlBranches,
                    endpoints.listGlStructures,
                  ],
                  icon: EditIcon,
                  entitySource: 'get',
                  entityType: SourceEntityType.GL_ACCOUNT_LEAVES_ID,
                  link: '/portal/accounting/gl-accounts-leaves',
                },
                {
                  title: 'New GL Branch Account',
                  hidden: false,
                  link: '/portal/accounting/gl-accounts-branches/new',
                  regexPath: /^\/portal\/accounting\/gl-accounts-branches\/new\/?$/,
                  requirements: [
                    endpoints.createGlBranch,
                    endpoints.getGlBranch,
                    endpoints.listCompanies,
                    endpoints.listCurrencies,
                    endpoints.listProducts,
                    endpoints.listGlBranches,
                    endpoints.listGlStructures,
                  ],
                  icon: NewIcon,
                  entitySource: 'create',
                  entityType: SourceEntityType.GL_ACCOUNT_BRANCHES_ID,
                },
                {
                  title: 'Edit GL Branch Account',
                  hidden: true,
                  regexPath: /^\/portal\/accounting\/gl-accounts-branches\/(?!(new)($|\/)).+\/?$/,
                  requirements: [
                    endpoints.getGlBranch,
                    endpoints.updateGlBranch,
                    endpoints.listCompanies,
                    endpoints.listCurrencies,
                    endpoints.listProducts,
                    endpoints.listGlBranches,
                    endpoints.listGlStructures,
                  ],
                  icon: EditIcon,
                  entitySource: 'get',
                  entityType: SourceEntityType.GL_ACCOUNT_BRANCHES_ID,
                  link: '/portal/accounting/gl-accounts-branches',
                },
              ],
            },
            {
              title: 'Budget Elements',
              icon: InvoiceIcon,
              hidden: false,
              link: '/portal/accounting/budget-element',
              regexPath: /^\/portal\/accounting\/budget-element\/?$/,
              requirements: [endpoints.listBudgetElementsAndAccounting, endpoints.listCompanies],
              entitySource: 'list',
              entityType: SourceEntityType.BUDGET_ELEMENT_KEY,
              children: [
                {
                  title: 'Create Budget Element',
                  link: '/portal/accounting/budget-element/new',
                  regexPath: /^\/portal\/accounting\/budget-element\/new\/?$/,
                  requirements: [endpoints.createBudgetElement, endpoints.getBudgetElement, endpoints.getBudgetElementUsage, endpoints.listCompanies, endpoints.listGlAccounts],
                  entityType: SourceEntityType.BUDGET_ELEMENT_KEY,
                  entitySource: 'create',
                  icon: NewIcon,
                },
                {
                  title: 'Edit Budget Element',
                  regexPath: /^\/portal\/accounting\/budget-element\/(?!(new)($|\/)).+\/?$/,
                  requirements: [endpoints.updateBudgetElement, endpoints.getBudgetElement, endpoints.getBudgetElementUsage, endpoints.listCompanies, endpoints.listGlAccounts],
                  entityType: SourceEntityType.BUDGET_ELEMENT_KEY,
                  entitySource: 'get',
                  link: '/portal/accounting/budget-element',
                  icon: EditIcon,
                  hidden: true,
                },
              ],
            },
            {
              title: 'Payment Terms',
              icon: FiscalCompanyIcon,
              hidden: false,
              link: '/portal/accounting/payment-term',
              regexPath: /^\/portal\/accounting\/payment-term\/?$/,
              requirements: [endpoints.listPaymentTerms],
              graphQlrequirements: [ExistingGraphql.waTermGroups],
              entitySource: 'list',
              entityType: SourceEntityType.PAYMENT_TERM_ID,
              children: [
                {
                  title: 'Create Payment Term',
                  link: '/portal/accounting/payment-term/new',
                  regexPath: /^\/portal\/accounting\/payment-term\/new\/?$/,
                  requirements: [endpoints.createPaymentTerm, endpoints.getPaymentTerm],
                  graphQlrequirements: [ExistingGraphql.waTermGroups, ExistingGraphql.alves, ExistingGraphql.waTermDates],
                  entityType: SourceEntityType.PAYMENT_TERM_ID,
                  entitySource: 'create',
                  icon: NewIcon,
                },
                {
                  title: 'Edit Payment Term',
                  regexPath: /^\/portal\/accounting\/payment-term\/(?!(new)($|\/)).+\/?$/,
                  requirements: [endpoints.updatePaymentTerm, endpoints.getPaymentTerm],
                  graphQlrequirements: [ExistingGraphql.waTermGroups, ExistingGraphql.alves, ExistingGraphql.waTermDates],
                  entityType: SourceEntityType.PAYMENT_TERM_ID,
                  entitySource: 'get',
                  link: '/portal/accounting/payment-term',
                  icon: EditIcon,
                  hidden: true,
                },
              ],
            },
            {
              title: 'Payment Term Dates',
              icon: FiscalCompanyIcon,
              hidden: false,
              link: '/portal/accounting/payment-term-date',
              regexPath: /^\/portal\/accounting\/payment-term-date\/?$/,
              requirements: [endpoints.listPaymentTermDates],
              entitySource: 'list',
              entityType: SourceEntityType.PAYMENT_TERM_DATE_ID,
              children: [
                {
                  title: 'Create Payment Term Date',
                  link: '/portal/accounting/payment-term-date/new',
                  regexPath: /^\/portal\/accounting\/payment-term-date\/new\/?$/,
                  requirements: [endpoints.createPaymentTermDate, endpoints.getPaymentTermDate],
                  entityType: SourceEntityType.PAYMENT_TERM_DATE_ID,
                  entitySource: 'create',
                  icon: NewIcon,
                },
                {
                  title: 'Edit Payment Term Date',
                  regexPath: /^\/portal\/accounting\/payment-term-date\/(?!(new)($|\/)).+\/?$/,
                  requirements: [endpoints.updatePaymentTermDate, endpoints.getPaymentTermDate],
                  entityType: SourceEntityType.PAYMENT_TERM_DATE_ID,
                  entitySource: 'get',
                  link: '/portal/accounting/payment-term-date',
                  icon: EditIcon,
                  hidden: true,
                },
              ],
            },
          ],
        },
        {
          title: 'Automated Reports',
          hidden: false,
          link: '/portal/accounting/automated-reports',
          regexPath: /^\/portal\/accounting\/automated-reports\/?$/,
          requirements: [endpoints.listReportAutomationDefinition, endpoints.listCompanies],
          entitySource: 'list',
          icon: ReportIcon,
          entityType: SourceEntityType.AUTOMATED_REPORTS_ID,
          children: [
            {
              title: 'New Automated Report',
              hidden: false,
              link: '/portal/accounting/automated-reports/new',
              regexPath: /^\/portal\/accounting\/automated-reports\/new\/?$/,
              requirements: [endpoints.createReportAutomationDefinition, endpoints.getReportAutomationDefinition, endpoints.listCompanies, endpoints.listStoredProcedures],
              icon: NewIcon,
              entitySource: 'create',
              entityType: SourceEntityType.AUTOMATED_REPORTS_ID,
            },
            {
              title: 'Edit Automated Report',
              hidden: true,
              regexPath: /^\/portal\/accounting\/automated-reports\/(?!(new)($|\/)).+\/?$/,
              requirements: [endpoints.getReportAutomationDefinition],
              icon: EditIcon,
              entitySource: 'get',
              entityType: SourceEntityType.AUTOMATED_REPORTS_ID,
              link: '/portal/accounting/automated-reports',
            },
          ],
        },
        {
          title: 'Bank Reconciliation',
          link: '/portal/accounting/bank-reconciliation',
          regexPath: /^\/portal\/accounting\/bank-reconciliation\/?$/,
          requirements: [endpoints.processMultipleBankFiles],
          icon: InvoiceIcon,
          hidden: false,
        },
        {
          title: 'Reports',
          icon: FolderIcon,
          hidden: false,
          children: [],
        },
        {
          title: 'Letters of Credit',
          icon: FolderIcon,
          hidden: false,
          children: [
            {
              title: 'Letters of Credit',
              link: '/portal/accounting/locs',
              regexPath: /^\/portal\/accounting\/locs\/?$/,
              requirements: [endpoints.listLettersOfCredit],
              hidden: false,
              entityType: SourceEntityType.THALOS_LETTER_OF_CREDIT,
              entitySource: 'list',
              icon: InvoiceIcon,
              children: [
                {
                  title: 'Create Letter of Credit',
                  link: '/portal/accounting/locs/new',
                  regexPath: /^\/portal\/accounting\/locs\/new\/?$/,
                  requirements: [
                    endpoints.createLetterOfCredit,
                    endpoints.getLetterOfCredit,
                    endpoints.listPaymentTerms,
                    endpoints.listCurrencies,
                    endpoints.listContacts,
                    endpoints.listBankAccounts,
                    endpoints.listContractData,
                    endpoints.getLcPresentation,
                    endpoints.listLcPresentations,
                    endpoints.listUnits,
                    endpoints.listDocumentsNames,
                  ],
                  graphQlrequirements: [ExistingGraphql.places],
                  entityType: SourceEntityType.THALOS_LETTER_OF_CREDIT,
                  entitySource: 'create',
                  icon: NewIcon,
                },
                {
                  title: 'Edit Letter of Credit',
                  regexPath: /^\/portal\/accounting\/locs\/(?!(new)($|\/)).+\/?$/,
                  requirements: [
                    endpoints.updateLetterOfCredit,
                    endpoints.getLetterOfCredit,
                    endpoints.listPaymentTerms,
                    endpoints.listCurrencies,
                    endpoints.listContacts,
                    endpoints.listBankAccounts,
                    endpoints.listContractData,
                    endpoints.getLcPresentation,
                    endpoints.listLcPresentations,
                    endpoints.listUnits,
                    endpoints.listDocumentsNames,
                  ],
                  entityType: SourceEntityType.THALOS_LETTER_OF_CREDIT,
                  entitySource: 'get',
                  link: '/portal/accounting/locs',
                  icon: EditIcon,
                  hidden: true,
                },
              ],
            },
            {
              title: 'Presentations',
              link: '/portal/accounting/presentations',
              regexPath: /^\/portal\/accounting\/presentations\/?$/,
              requirements: [endpoints.listLcPresentations],
              hidden: false,
              entityType: SourceEntityType.LETTER_OF_CREDIT_PRESENTATION,
              entitySource: 'list',
              icon: InvoiceIcon,
              children: [
                {
                  title: 'Create Presentation',
                  link: '/portal/accounting/presentations/new',
                  regexPath: /^\/portal\/accounting\/presentations\/new\/?$/,
                  requirements: [
                    endpoints.createLcPresentation,
                    endpoints.getLcPresentation,
                    endpoints.listCurrencies,
                    endpoints.listLettersOfCredit,
                    endpoints.listPaymentTerms,
                    endpoints.listVouchersBalance,
                  ],
                  entityType: SourceEntityType.LETTER_OF_CREDIT_PRESENTATION,
                  entitySource: 'create',
                  icon: NewIcon,
                },
                {
                  title: 'Edit Presentation',
                  regexPath: /^\/portal\/accounting\/presentations\/(?!(new)($|\/)).+\/?$/,
                  requirements: [
                    endpoints.updateLcPresentation,
                    endpoints.getLcPresentation,
                    endpoints.listCurrencies,
                    endpoints.listLettersOfCredit,
                    endpoints.listPaymentTerms,
                    endpoints.listVouchersBalance,
                  ],
                  entityType: SourceEntityType.LETTER_OF_CREDIT_PRESENTATION,
                  entitySource: 'get',
                  link: '/portal/accounting/presentations',
                  icon: EditIcon,
                  hidden: true,
                },
              ],
            },
          ],
        },
        {
          title: 'Risk Management',
          icon: FolderIcon,
          hidden: false,
          children: [
            {
              title: 'Open Futures MTM',
              link: '/portal/accounting/open-futures-mtm',
              regexPath: /^\/portal\/accounting\/open-futures-mtm\/?$/,
              requirements: [endpoints.openFuturesMTM],
              hidden: false,
              entityType: SourceEntityType.OPEN_FUTURE_MTM_KEY,
              entitySource: 'list',
              icon: InvoiceIcon,
              children: [],
            },
            {
              title: 'Collateral AR (USD)',
              link: '/portal/accounting/collateral-ar',
              regexPath: /^\/portal\/accounting\/collateral-ar\/?$/,
              requirements: [endpoints.rebuildMarketMatrixAR, endpoints.refreshMarketMatrixARPrices, endpoints.updateMarketMatrixAR, endpoints.listCollateralAR, endpoints.listCollateralMarkets],
              hidden: false,
              icon: ChartIcon,
              children: [],
            },
          ],
        },
      ],
    },
    {
      title: 'Metal Control',
      requirements: [],
      regexPath: /^\/portal\/metal-control\/?$/,
      hidden: false,
      icon: MetalControlIcon,
      children: [
        {
          title: 'Matching',
          requirements: [endpoints.listPurchaseMatchData, endpoints.listMatchingSaleContractData, endpoints.createMatching],
          regexPath: /^\/portal\/metal-control\/matching\/?$/,
          hidden: false,
          link: '/portal/metal-control/matching',
          icon: MatchingIcon,
        },
        {
          title: 'Bookings - Metal Control',
          requirements: [endpoints.bookingQueue, endpoints.listContacts],
          hidden: false,
          regexPath: /^\/portal\/metal-control\/bookings\/?$/,
          featureRoute: MiscFeature.LIST_ARRANGED_BOOKINGS,
          link: '/portal/metal-control/bookings',
          icon: BookingIcon,
          children: [
            {
              title: 'New Booking - Metal Control',
              requirements: [
                endpoints.createBooking,
                endpoints.createContainer,
                endpoints.listContacts,
                endpoints.listVessels,
                endpoints.listCities,
                endpoints.listContacts,
                endpoints.linkContainerToBooking,
                endpoints.getBooking,
                endpoints.listDocuments,
                endpoints.listComments,
                endpoints.listShipmentContractData,
                endpoints.checkDuplicateContainerNumber,
                endpoints.listOceanSegments,
                endpoints.listBookingExpenses,
                endpoints.listContractData,
                endpoints.oceanRateLookup,
              ],
              graphQlrequirements: [ExistingGraphql.places],
              hidden: false,
              link: '/portal/metal-control/bookings/new',
              featureRoute: MiscFeature.CREATE_ARRANGED_BOOKING,
              regexPath: /^\/portal\/metal-control\/bookings\/new\/?$/,
              icon: NewIcon,
            },
            {
              title: 'Edit Booking',
              requirements: [
                endpoints.updateBooking,
                endpoints.updateContainer,
                endpoints.listContacts,
                endpoints.listVessels,
                endpoints.listCities,
                endpoints.listContacts,
                endpoints.linkContainerToBooking,
                endpoints.getBooking,
                endpoints.listDocuments,
                endpoints.listComments,
                endpoints.listShipmentContractData,
                endpoints.checkDuplicateContainerNumber,
                endpoints.listOceanSegments,
                endpoints.listBookingExpenses,
                endpoints.listContractData,
                endpoints.oceanRateLookup,
              ],
              graphQlrequirements: [ExistingGraphql.places],
              hidden: true,
              regexPath: /^\/portal\/metal-control\/bookings\/(?!(new)($|\/)).+\/?$/,
              featureRoute: MiscFeature.GET_ARRANGED_BOOKING,
              link: '/portal/metal-control/bookings',
              icon: EditIcon,
            },
          ],
        },
        {
          title: 'PTickets',
          icon: FolderIcon,
          hidden: false,
          children: [
            {
              title: 'Purchase Ticket Queue',
              requirements: [
                endpoints.purchaseTicketQueue,
                endpoints.invoicePurchaseTicket,
                endpoints.listCheckListEntries,
                endpoints.createCheckListEntry,
                endpoints.listCheckListItem,
                endpoints.listDocuments,
                endpoints.listServiceOrders,
                endpoints.listContacts,
                endpoints.listBudgetElements,
              ],
              hidden: false,
              regexPath: /^\/portal\/metal-control\/purchase-tickets\/new\/?$/,
              link: '/portal/metal-control/purchase-tickets/new',
              icon: PurchaseTicketIcon,
            },
          ],
        },
      ],
    },
    {
      title: 'Service Orders',
      link: '/portal/core/service-orders',
      requirements: [endpoints.listServiceOrders, endpoints.listContacts, endpoints.listBudgetElements],
      regexPath: /^\/portal\/core\/service-orders\/?$/,
      entitySource: 'list',
      entityType: SourceEntityType.SERVICE_ORDER_KEY,
      hidden: false,
      icon: ServiceOrderIcon,
      children: [
        {
          title: 'New Service Order',
          link: '/portal/core/service-orders/new',
          regexPath: /^\/portal\/core\/service-orders\/new\/?$/,
          requirements: [
            endpoints.createServiceOrder,
            endpoints.getServiceOrder,
            endpoints.listServices,
            endpoints.shipmentLookup,
            endpoints.listContacts,
            endpoints.listBudgetElements,
            endpoints.listCities,
            endpoints.getShipment,
            endpoints.listProducts,
            endpoints.listContainerTypes,
            endpoints.listCurrencies,
          ],
          graphQlrequirements: [ExistingGraphql.places],
          entitySource: 'create',
          icon: NewIcon,
          entityType: SourceEntityType.SERVICE_ORDER_KEY,
          hidden: false,
        },
        {
          title: 'Edit Service Order',
          link: '/portal/core/service-orders',
          regexPath: /^\/portal\/core\/service-orders\/(?!(new)($|\/)).+\/?$/,
          requirements: [
            endpoints.updateServiceOrder,
            endpoints.getServiceOrder,
            endpoints.listServices,
            endpoints.shipmentLookup,
            endpoints.listContacts,
            endpoints.listBudgetElements,
            endpoints.listCities,
            endpoints.getShipment,
            endpoints.listProducts,
            endpoints.listContainerTypes,
            endpoints.listCurrencies,
          ],
          graphQlrequirements: [ExistingGraphql.places],
          entitySource: 'get',
          entityType: SourceEntityType.SERVICE_ORDER_KEY,
          hidden: true,
          icon: EditIcon,
        },
        {
          title: 'Batch Service Order Creation',
          requirements: [
            endpoints.generateBatchServiceOrder,
            endpoints.listContacts,
            endpoints.listBudgetElements,
            endpoints.listServices,
            endpoints.listContainerTypes,
            endpoints.listCurrencies,
            endpoints.listContainers,
          ],
          hidden: false,
          link: '/portal/core/batch-service-order',
          regexPath: /^\/portal\/core\/batch-service-order\/?$/,
          icon: ServiceOrderIcon,
        },
      ],
    },
    {
      title: 'Risk Management',
      requirements: [],
      hidden: false,
      icon: FolderIcon,
      regexPath: /^\/portal\/risk-management\/?$/,
      children: [
        {
          title: 'Net Position Summary',
          link: '/portal/risk-management/summary',
          regexPath: /^\/portal\/risk-management\/summary\/?$/,
          requirements: [endpoints.listNetPositionSummary, endpoints.listLmeMarketData, endpoints.listCompanies],
          entitySource: 'list',
          icon: GoToIcon,
          entityType: SourceEntityType.NET_POSITION_SUMMARY_KEY,
          hidden: false,
        },
        {
          title: 'Net Position Entries',
          link: '/portal/risk-management/entries',
          regexPath: /^\/portal\/risk-management\/entries\/?$/,
          requirements: [endpoints.listNetPositionEntries, endpoints.listCompanies, endpoints.listProductFamilies, endpoints.getNetPositionLine],
          entitySource: 'list',
          icon: GoToIcon,
          entityType: SourceEntityType.NET_POSITION_ENTRIES_KEY,
          hidden: false,
        },
        {
          title: 'Net Position Price Report',
          link: '/portal/risk-management/price-report',
          regexPath: /^\/portal\/risk-management\/price-report\/?$/,
          requirements: [endpoints.generateNetPositionPriceReport, endpoints.listProductFamilies],
          entitySource: 'list',
          entityType: SourceEntityType.NET_POSITION_PRICE_REPORT_KEY,
          hidden: false,
        },
        {
          title: 'Net Position Daily Review',
          link: '/portal/risk-management/net-position-daily-review',
          regexPath: /^\/portal\/risk-management\/net-position-daily-review\/?$/,
          requirements: [endpoints.listNetPositionDailyReview, endpoints.listCompanies, endpoints.listProducts, endpoints.listItems],
          icon: ReportIcon,
          hidden: false,
        },
      ],
    },
    {
      title: 'Hedging',
      regexPath: /^\/portal\/hedging\/futures-contracts\/?$/,
      requirements: [],
      hidden: false,
      icon: FolderIcon,
      children: [
        {
          title: 'Futures Contracts',
          hidden: false,
          icon: ContractIcon,
          children: [
            {
              title: 'Create Hedge Entry',
              link: '/portal/hedging/futures-contracts/contract/new',
              regexPath: /^\/portal\/hedging\/futures-contracts\/contract\/new\/?$/,
              requirements: [endpoints.createFutureContract, endpoints.listNoAffectedReasons, endpoints.listFutureMarketTerms, endpoints.listFutureMarkets],
              entityType: SourceEntityType.FUTURE_KEY,
              entitySource: 'create',
              icon: NewIcon,
            },
            {
              title: 'Edit Hedge Entry',
              regexPath: /^\/portal\/hedging\/futures-contracts\/contract\/(?!(new)($|\/)).+\/?$/,
              requirements: [endpoints.getFutureContract],
              entityType: SourceEntityType.FUTURE_KEY,
              entitySource: 'get',
              link: '/portal/hedging/futures-contracts/contract',
              icon: EditIcon,
              hidden: true,
            },
          ],
        },
        {
          title: 'MWTI Market Price',
          requirements: [endpoints.createMWTIMarketPrice],
          effect: MWTIMarketPriceMenuItem(),
          icon: InvoiceIcon,
        },
      ],
    },
    {
      title: 'Utilities',
      icon: UtilIcon,
      order: 20,
      children: [
        {
          title: 'Ocean Rate Finder',
          requirements: [endpoints.oceanRateLookup],
          graphQlrequirements: [ExistingGraphql.places],
          effect: OceanRateFinderMenuItem(),
          icon: WindowIcon,
        },
        {
          title: 'Date Calculator',
          effect: DateCalculator(),
          icon: WindowIcon,
        },
        {
          title: 'Expression Calculator',
          effect: Calculator(),
          icon: WindowIcon,
        },
      ],
    },
    {
      title: 'DIT',
      requirements: [],
      regexPath: /^\/portal\/it\/?$/,
      hidden: false,
      order: Infinity,
      icon: DITIcon,
      children: [
        {
          title: 'Flex Views',
          requirements: [endpoints.listFlexViewsAdmin],
          hidden: false,
          regexPath: /^\/portal\/it\/flex-views\/?$/,
          link: '/portal/it/flex-views',
          featureRoute: MiscFeature.LIST_FLEX_VIEWS,
          children: [
            {
              title: 'New Flex View',
              requirements: [
                endpoints.createFlexView,
                endpoints.listAllFlexFilterGroupsAdmin,
                endpoints.listFlexFilterRows,
                endpoints.getDefinitions,
                endpoints.listViewsAndTables,
                endpoints.getFlexViewAdmin,
                endpoints.createFlexColumn,
                endpoints.createFlexFilterGroup,
                endpoints.createFlexFilterRow,
                endpoints.setFlexViewUserGroupAssignments,
                endpoints.setFilterGroupUserGroupAssignments,
                endpoints.findGroups,
              ],
              hidden: false,
              regexPath: /^\/portal\/it\/flex-views\/new\/?$/,
              link: '/portal/it/flex-views/new',
              featureRoute: MiscFeature.NEW_FLEX_VIEW,
            },
            {
              title: 'Edit Flex View',
              requirements: [
                endpoints.updateFlexView,
                endpoints.listAllFlexFilterGroupsAdmin,
                endpoints.listFlexFilterRows,
                endpoints.getDefinitions,
                endpoints.listViewsAndTables,
                endpoints.getFlexViewAdmin,
                endpoints.createFlexColumn,
                endpoints.createFlexFilterGroup,
                endpoints.createFlexFilterRow,
                endpoints.updateFlexFilterRow,
                endpoints.updateFlexFilterGroup,
                endpoints.updateFlexColumn,
                endpoints.updateFlexViewLayoutAdmin,
                endpoints.setFlexViewUserGroupAssignments,
                endpoints.setFilterGroupUserGroupAssignments,
                endpoints.findGroups,
              ],
              link: '/portal/it/flex-views',
              hidden: true,
              regexPath: /^\/portal\/it\/flex-views\/(?!(new)($|\/)).+\/?$/,
              featureRoute: MiscFeature.EDIT_FLEX_VIEW,
            },
          ],
        },
        {
          title: 'Contact Types',
          requirements: [endpoints.listContactTypes],
          hidden: false,
          regexPath: /^\/portal\/it\/contact-types\/?$/,
          link: '/portal/it/contact-types',
          children: [
            {
              title: 'New Contact Type',
              requirements: [endpoints.createContactType, endpoints.getContactType],
              hidden: false,
              link: '/portal/it/contact-types/new',
              regexPath: /^\/portal\/it\/contact-types\/new\/?$/,
            },
            {
              title: 'Edit Contact Types',
              requirements: [endpoints.updateContactType, endpoints.getContactType],
              hidden: true,
              regexPath: /^\/portal\/it\/contact-types\/(?!(new)($|\/)).+\/?$/,
            },
          ],
        },
        {
          title: 'Approval Type Approvers',
          requirements: [endpoints.listApprovalTypeApprovers],
          hidden: false,
          regexPath: /^\/portal\/it\/approval-type-approvers\/?$/,
          link: '/portal/it/approval-type-approvers',
          children: [
            {
              title: 'New Approval Type Approver',
              requirements: [endpoints.createApprovalTypeApprover, endpoints.getApprovalTypeApprover, endpoints.listApprovalTypes, endpoints.listContacts],
              hidden: false,
              link: '/portal/it/approval-type-approvers/new',
              regexPath: /^\/portal\/it\/approval-type-approvers\/new\/?$/,
            },
            {
              title: 'Edit Approval Type Approver',
              requirements: [endpoints.updateApprovalTypeApprover, endpoints.getApprovalTypeApprover, endpoints.listApprovalTypes, endpoints.listContacts],
              hidden: true,
              regexPath: /^\/portal\/it\/approval-type-approvers\/(?!(new)($|\/)).+\/?$/,
            },
          ],
        },
        {
          title: 'Future Markets',
          requirements: [endpoints.listFutureMarkets],
          hidden: false,
          regexPath: /^\/portal\/it\/future-markets\/?$/,
          link: '/portal/it/future-markets',
        },
        {
          title: 'Container Types',
          requirements: [endpoints.listContainerTypes],
          hidden: false,
          regexPath: /^\/portal\/it\/container-types\/?$/,
          link: '/portal/it/container-types',
        },
        {
          title: 'Incoterms',
          requirements: [endpoints.listIncoterms],
          hidden: false,
          regexPath: /^\/portal\/it\/incoterms\/?$/,
          link: '/portal/it/incoterms',
          children: [
            {
              title: 'New Incoterm',
              requirements: [endpoints.createIncoterm, endpoints.getIncoterm, endpoints.listIncoterms],
              hidden: false,
              link: '/portal/it/incoterms/new',
              regexPath: /^\/portal\/it\/incoterms\/new\/?$/,
            },
            {
              title: 'Edit Incoterm',
              requirements: [endpoints.updateIncoterm, endpoints.getIncoterm, endpoints.listIncoterms],
              hidden: true,
              regexPath: /^\/portal\/it\/incoterms\/(?!(new)($|\/)).+\/?$/,
            },
          ],
        },
        {
          title: 'Cities',
          graphQlrequirements: [ExistingGraphql.places],
          hidden: false,
          regexPath: /^\/portal\/it\/cities\/?$/,
          link: '/portal/it/cities',
          children: [
            {
              title: 'New City',
              requirements: [endpoints.createCity, endpoints.getCity, endpoints.listCountries, endpoints.listCitiesMaxWeights],
              hidden: false,
              regexPath: /^\/portal\/it\/cities\/new\/?$/,
              link: '/portal/it/cities/new',
            },
            {
              title: 'Edit City',
              requirements: [endpoints.updateCity, endpoints.getCity, endpoints.listCountries, endpoints.listCitiesMaxWeights, endpoints.upsertCitiesMaxWeights],
              hidden: true,
              regexPath: /^\/portal\/it\/cities\/(?!(new)($|\/)).+\/?$/,
            },
          ],
        },
        {
          title: 'User Group Assignments',
          requirements: [endpoints.listUserGroupEndpointAssignments],
          hidden: false,
          regexPath: /^\/portal\/it\/user-group-assignments\/?$/,
          link: '/portal/it/user-group-assignments',
          children: [
            {
              title: 'New User Group Assignment',
              requirements: [endpoints.createUserGroupEndpointAssignment, endpoints.listUserGroupEndpointAssignments, endpoints.findGroups, endpoints.listEndpointsAdmin, endpoints.listrpcids],
              hidden: false,
              link: '/portal/it/user-group-assignments/new',
              regexPath: /^\/portal\/it\/user-group-assignments\/new\/?$/,
            },
            {
              title: 'User Group Assignment',
              requirements: [endpoints.createUserGroupEndpointAssignment, endpoints.deleteUserGroupEndpointAssignment, endpoints.findGroups, endpoints.listEndpointsAdmin, endpoints.listrpcids],
              hidden: true,
              regexPath: /^\/portal\/it\/user-group-assignments\/(?!(new)($|\/)).+\/?$/,
            },
            {
              title: 'New Endpoint Assignment',
              requirements: [endpoints.createUserGroupEndpointAssignment, endpoints.findGroups, endpoints.listEndpointsAdmin, endpoints.listrpcids],
              hidden: false,
              link: '/portal/it/endpoint-assignments/new',
              regexPath: /^\/portal\/it\/endpoint-assignments\/new\/?$/,
            },
            {
              title: 'Endpoint Assignment',
              requirements: [endpoints.createUserGroupEndpointAssignment, endpoints.deleteUserGroupEndpointAssignment, endpoints.findGroups, endpoints.listEndpointsAdmin, endpoints.listrpcids],
              hidden: true,
              regexPath: /^\/portal\/it\/endpoint-assignments\/(?!(new)($|\/)).+\/?$/,
            },
          ],
        },
        {
          title: 'User Group Graphql Assignments',
          requirements: [endpoints.listUserGroupGraphqlAssignments],
          hidden: false,
          regexPath: /^\/portal\/it\/user-group-graphql-assignments\/?$/,
          link: '/portal/it/user-group-graphql-assignments',
          children: [
            {
              title: 'New User Group Graphql Assignment',
              requirements: [endpoints.createUserGroupGraphqlAssignment, endpoints.findGroups, endpoints.listGraphqlAdmin, endpoints.listGraphqlRpcIds],
              hidden: false,
              link: '/portal/it/user-group-graphql-assignments/new',
              regexPath: /^\/portal\/it\/user-group-graphql-assignments\/new\/?$/,
            },
            {
              title: 'User Group Graphql Assignment',
              requirements: [endpoints.createUserGroupGraphqlAssignment, endpoints.deleteUserGroupGraphqlAssignment, endpoints.findGroups, endpoints.listGraphqlAdmin, endpoints.listGraphqlRpcIds],
              hidden: true,
              regexPath: /^\/portal\/it\/user-group-graphql-assignments\/(?!(new)($|\/)).+\/?$/,
            },
            {
              title: 'New Graphql Assignment',
              requirements: [endpoints.createUserGroupGraphqlAssignment, endpoints.findGroups, endpoints.listGraphqlAdmin, endpoints.listGraphqlRpcIds],
              hidden: false,
              link: '/portal/it/graphql-assignments/new',
              regexPath: /^\/portal\/it\/graphql-assignments\/new\/?$/,
            },
            {
              title: 'Graphql Assignment',
              requirements: [endpoints.createUserGroupGraphqlAssignment, endpoints.deleteUserGroupGraphqlAssignment, endpoints.findGroups, endpoints.listGraphqlAdmin, endpoints.listGraphqlRpcIds],
              hidden: true,
              regexPath: /^\/portal\/it\/graphql-assignments\/(?!(new)($|\/)).+\/?$/,
            },
          ],
        },
        {
          title: 'Document Packets',
          requirements: [endpoints.listDocumentPackets, endpoints.listCompanies],
          hidden: false,
          regexPath: /^\/portal\/it\/document-packets\/?$/,
          link: '/portal/it/document-packets',
          entityType: SourceEntityType.DOCUMENT_PACKET_KEY,
          entitySource: 'list',
          children: [
            {
              title: 'New Document Packet',
              requirements: [endpoints.createDocumentPacket, endpoints.getDocumentPacket, endpoints.listDocumentTemplates, endpoints.createDocumentTemplate],
              hidden: false,
              link: '/portal/it/document-packets/new',
              regexPath: /^\/portal\/it\/document-packets\/new\/?$/,
              entityType: SourceEntityType.DOCUMENT_PACKET_KEY,
              entitySource: 'create',
              children: [],
            },
            {
              title: 'Edit Document Packet',
              requirements: [endpoints.updateDocumentPacket, endpoints.getDocumentPacket, endpoints.listDocumentTemplates, endpoints.createDocumentTemplate],
              hidden: true,
              link: '/portal/it/document-packets',
              regexPath: /^\/portal\/it\/document-packets\/(?!(new)($|\/)).+\/?$/,
              entityType: SourceEntityType.DOCUMENT_PACKET_KEY,
              entitySource: 'get',
              children: [],
            },
          ],
        },
        {
          title: 'Document Templates',
          requirements: [endpoints.listDocumentTemplates],
          hidden: false,
          regexPath: /^\/portal\/it\/document-templates\/?$/,
          link: '/portal/it/document-templates',
          children: [
            {
              title: 'New Document Template',
              requirements: [endpoints.createDocumentTemplate, endpoints.getDocumentTemplate],
              hidden: false,
              link: '/portal/it/document-templates/new',
              regexPath: /^\/portal\/it\/document-templates\/new\/?$/,
              children: [],
            },
            {
              title: 'Edit Document Template',
              requirements: [endpoints.updateDocumentTemplate, endpoints.getDocumentTemplate],
              hidden: true,
              regexPath: /^\/portal\/it\/document-templates\/(?!(new)($|\/)).+\/?$/,
              children: [],
            },
          ],
        },
        {
          title: 'Tags',
          requirements: [endpoints.listTags],
          hidden: false,
          regexPath: /^\/portal\/it\/tags\/?$/,
          link: '/portal/it/tags',
          icon: TagsIcon,
        },
        {
          title: 'Configuration',
          requirements: [endpoints.listConfigurationsAdmin],
          hidden: false,
          regexPath: /^\/portal\/it\/configuration/,
          link: '/portal/it/configuration',
          featureRoute: MiscFeature.CONFIG,
        },
        {
          title: 'Users Information Dashboard',
          requirements: [endpoints.listEndpointStatsPerNumberOfRequests, endpoints.listEndpointStatsPerNumberOfUsers, endpoints.listEndpointStatsPerAverageTime, endpoints.sendThalosMessage],
          hidden: false,
          regexPath: /^\/portal\/it\/users-information-dashboard\/?$/,
          link: '/portal/it/users-information-dashboard',
          featureRoute: MiscFeature.USERS_INFORMATION_DASHBOARD,
          icon: ChartIcon,
        },
        {
          title: 'User Authorized Companies',
          requirements: [endpoints.listUserAuthorizedCompanies, endpoints.listAllCompanies, endpoints.listContacts],
          hidden: false,
          regexPath: /^\/portal\/it\/user-authorized-companies\/?$/,
          link: '/portal/it/user-authorized-companies',
          icon: UserAuthorizedCompaniesIcon,
          featureRoute: MiscFeature.USER_AUTHORIZED_COMPANIES,
          children: [
            {
              title: 'New User Authorized Companies',
              requirements: [
                endpoints.createUserAuthorizedCompanies,
                endpoints.listUserAuthorizedCompanies,
                endpoints.getThalosUser,
                endpoints.listThalosUsers,
                endpoints.listAllCompanies,
                endpoints.listContacts,
                endpoints.createThalosUser,
              ],
              hidden: false,
              link: '/portal/it/user-authorized-companies/new',
              regexPath: /^\/portal\/it\/user-authorized-companies\/new\/?$/,
              icon: NewIcon,
            },
            {
              title: 'Edit User Authorized Companies',
              regexPath: /^\/portal\/it\/user-authorized-companies\/(?!(new)($|\/)).+\/?$/,
              requirements: [
                endpoints.updateUserAuthorizedCompanies,
                endpoints.listUserAuthorizedCompanies,
                endpoints.getThalosUser,
                endpoints.listThalosUsers,
                endpoints.listAllCompanies,
                endpoints.listContacts,
              ],
              link: '/portal/it/user-authorized-companies',
              icon: EditIcon,
              hidden: true,
            },
          ],
        },
      ],
    },
  ],
  permittedMenu: null,
};

export function layoutsReducer(action, state = initialLayoutState): LayoutState {
  switch (action.type) {
    case RESOLVING_ENTITY_FOR_CONTAINER:
      return {
        ...state,
        readyToHide: false,
      };
    case CALCULATED_DYNAMIC_MENUS:
      const newState = {
        ...state,
        permittedMenu: action.payload,
      };
      return newState;
    case START_REQUEST: {
      let requestsPending = state.requestsPending;
      let args: { text?: string; id: string; background?: boolean; hidden?: boolean; progress?: number } = action.payload || {};
      requestsPending.unshift({ text: args.text || null, id: args.id, background: args.background || false, hidden: args.hidden ?? false, progress: args.progress });
      let currentSpinner = requestsPending.find((r) => r.text !== null);
      if (!currentSpinner) currentSpinner = requestsPending[0];

      return {
        ...state,
        requestsPending,
        showSpinner: !currentSpinner.hidden,
        currentSpinner,
      };
    }
    case FINISH_REQUEST: {
      let requestsPending = state.requestsPending;
      let matchingRequest = state.requestsPending.findIndex((req) => req.id === action.payload);
      if (matchingRequest >= 0) requestsPending.splice(matchingRequest, 1);
      let currentSpinner = requestsPending.find((r) => r.text !== null);
      if (!currentSpinner) currentSpinner = null;

      return {
        ...state,
        currentSpinner,
        requestsPending: requestsPending,
        showSpinner: currentSpinner && !currentSpinner.hidden,
      };
    }
    case UPDATE_REQUEST_PROGRESS: {
      let requestsPending = state.requestsPending;
      let args: { progress?: number; id: string; text?: string } = action.payload;
      let matchingRequest = requestsPending.find((r) => r.id === args.id);
      if (matchingRequest) {
        if (args.progress !== undefined) matchingRequest.progress = args.progress;
        if (args.text !== undefined) matchingRequest.text = args.text;
      }
      return {
        ...state,
        requestsPending,
      };
    }
    case TOGGLE_DEBUG_MODE:
      return {
        ...state,
        debugMode: !state.debugMode,
      };
    case LOADED_MISSING_ENDPOINTS:
      return {
        ...state,
        missingRequirements: { ...state.missingRequirements, ...action.payload },
      };
    case LOADED_MISSING_GRAPHQLS:
      return {
        ...state,
        missinGraphqlRequirements: { ...state.missinGraphqlRequirements, ...action.payload },
      };
    case SET_TIMEDOUT:
      return {
        ...state,
        timedOut: action.payload || true,
      };
    case SET_BOOKING_OPERATOR:
      return {
        ...state,
        bookingOperator: action.payload || null,
      };
    case SET_BOOKING_LAYOUT:
      return {
        ...state,
        bookingLayout: action.payload || null,
      };
    case SET_ANCHOR_POINT: {
      let newAnchor = state.blockNextAnchor ? null : action.payload;
      if (!!state.anchorPointExtraParams && !!newAnchor?.state) {
        newAnchor = {
          ...newAnchor,
          state: {
            ...newAnchor.state,
            ...state.anchorPointExtraParams,
          },
        };
      }
      let raw: string = '';
      try {
        raw = JSON.stringify(newAnchor || null);
      } catch (e) {
        raw = '';
      }
      sessionStorage.setItem(`thalos-anchor-point-${environment.name}`, raw);

      return {
        ...state,
        anchorPointExtraParams: null,
        blockNextAnchor: false,
        anchorPoint: newAnchor || null,
      };
    }
    case UPDATE_ANCHOR_POINT: {
      return {
        ...state,
        anchorPointExtraParams: action.payload,
      };
    }
    case BLOCK_ANCHOR_POINT:
      return {
        ...state,
        blockNextAnchor: true,
      };
    case SET_FLAT_MENU:
      return {
        ...state,
        flatMenu: action.payload,
      };
    default:
      return state;
  }
}

export const RESOLVING_ENTITY_FOR_CONTAINER = 'resolving_entity_for_container';
